/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contact info for an Arctic Wolf region
 * @export
 * @interface GlobalContact
 */
export interface GlobalContact {
  /**
   *
   * @type {string}
   * @memberof GlobalContact
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof GlobalContact
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GlobalContact
   */
  phoneNumber?: string;
}

export function GlobalContactFromJSON(json: any): GlobalContact {
  return GlobalContactFromJSONTyped(json, false);
}

export function GlobalContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlobalContact {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    region: !exists(json, 'region') ? undefined : json['region'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phoneNumber: !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
  };
}

export function GlobalContactToJSON(value?: GlobalContact | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    region: value.region,
    email: value.email,
    phoneNumber: value.phoneNumber,
  };
}
