import { LocationState } from 'redux-first-router';
import { iState } from '../configureStore';
import { RoutesKey } from '../routesMap';
import { iAssetsAction } from './assetActions';
import { TagType } from './assetTags';

export interface iOsInfo {
  name: string;
  vendor: string;
  product: string;
  version: string;
  update: string;
  edition: string;
  language: string;
  reason: string;
}
export interface iAsset {
  id: string;
  ip: string;
  deviceName: string;
  userDeviceName: string;
  mac: string;
  osInfo: iOsInfo;
  risk: number;
  vulns: number;
  lastVerifiedDeviceName: number;
  lastVerifiedMac: number;
  lastVerifiedOs: number;
  lastScannedTime: number;
  manufacturer: string;
  sessionQualifier: string;
  clientUUID: string;
  userName: string;
  externalIP: string;
  latitude: number;
  longitude: number;
  city: string;
  country: string;
  timeZone: string;
  anonymousProxy: boolean;
  satelliteProvider: boolean;
  lastVerifiedExternalIP: number;
  systemModel: string;
  systemBootTime: string;
  active: boolean;
  category: string;
  source?: string;
  deploymentID?: string;
  scannerUUID?: string;
  criticality?: number;
  tags?: number[];
}

/**
 * Raw asset schema as returned by API.
 */
export interface iAssetCatalogDataType {
  id: string;
  ip: string;
  source: string;
  deploymentID: string;
  deviceName: string;
  mac: string;
  os: string;
  risk: number;
  criticality?: number;
  vulns: number;
  category: string;
  scannerUUID: string;
  clientUUID: string;
  lastSeen: string;
  lastScannedTime: string;
  sourceName: string;
  manufacturer: string;
  tags?: number[];
}

/**
 * Enriched asset schema for display & csv download.
 */
export interface DataWithDuplicateAssets extends iAssetCatalogDataType {
  assetTags?: Array<TagType>;
  duplicateData?: Array<iAssetCatalogDataType>;
  tagCSV?: string;
  critCSV?: string;
}

export interface iAssetCategory {
  category: string;
  count: number;
  maxScore: number;
}

export interface iAssetCategories {
  name: string;
  risk: number;
  vulns: number;
  assets?: Array<iAsset>;
}

export interface iAssetSeverity {
  severity: string;
  count: number;
  maxScore: number;
  color?: string;
}

export interface iAssetSeverityType {
  type: string;
  data: { cat: string; val: number; category?: string }[];
  color?: string;
}

export interface iAssetAction {
  action: string;
  severity: string;
  riskCount: number;
  category: string;
  color?: string;
}
export interface iRiskFieldAction {
  count: number;
  maxLevel: number;
  riskLink: string;
  value: string;
}

export enum AssetActionTypes {
  /**
   * Refers to the node `severity` and `actions`
   * TODO: clean this up, two individual nodes should not share the same indicator
   */
  PROCESSING_ASSETS = 'PROCESSING_ASSETS',

  /**
   * Refers to the node `allAssets`, the only upstream of the Asset Catalog table
   */
  PROCESSING_ALL_ASSETS_NODE = 'PROCESSING_ASSETS_NODE',

  LOAD_ASSETS = 'LOAD_ASSETS',
  LOAD_ASSET_DATA = 'LOAD_ASSET_DATA',
  LOAD_ASSET_ACTIONS_DATA = 'LOAD_ASSET_ACTIONS_DATA',
  LOAD_RISK_ACTIONS_DATA = 'LOAD_RISK_ACTIONS_DATA',
  LOAD_ASSET_SEVERITY_DATA = 'LOAD_ASSET_SEVERITY_DATA',
  LOAD_ASSETS_CATEGORIES = 'LOAD_ASSETS_CATEGORIES',
}

const initialState = {
  isProcessing: true,
  isAllAssetsProcessing: false,
  allAssets: [],
  allAssetsById: {},
  assets: [],
  severities: [],
  actions: [],
  riskFieldActions: [],
  severity: [],
};
export interface iAssetsState {
  isProcessing?: boolean;
  isAllAssetsProcessing: boolean;
  allAssets: Array<iAsset>;
  allAssetsById: { [id: string]: iAsset };
  assets: Array<iAssetCategories>;
  severities: Array<iAssetSeverity>;
  actions: Array<iAssetAction>;
  riskFieldActions: Array<iRiskFieldAction>;
  severity: Array<iAssetSeverityType>;
}

export interface iAssetProfileOsInfo {
  Edition: string;
  Language: string;
  Name: string;
  Product: string;
  Reason: string;
  Update: string;
  Vendor: string;
  Version: string;
}
export interface iProfileHistory {
  deviceid: string;
  expireseconds: number;
  ip: string;
  mappingeffects: string;
  raw: string;
  tsseconds: number;
  type: string;
  value?: iAssetProfileOsInfo | string;
}

export const assetReducer = (
  state: iAssetsState = initialState,
  action: iAssetsAction
) => {
  const newState = { ...state };
  switch (action.type) {
    case AssetActionTypes.PROCESSING_ASSETS:
      newState.isProcessing = action.isProcessing;
      return newState;
    case AssetActionTypes.PROCESSING_ALL_ASSETS_NODE:
      newState.isAllAssetsProcessing = action.isProcessing;
      return newState;
    case AssetActionTypes.LOAD_ASSETS_CATEGORIES:
      newState.assets = action.payload.categories;
      return newState;
    case AssetActionTypes.LOAD_ASSETS:
      newState.allAssets = action.payload.data;
      newState.allAssetsById = { ...newState.allAssetsById };
      action.payload.data?.forEach((asset) => {
        newState.allAssetsById[asset.id] = asset;
      });
      return newState;
    case AssetActionTypes.LOAD_ASSET_DATA:
      newState.severities = action.payload.severities;
      return newState;
    case AssetActionTypes.LOAD_ASSET_ACTIONS_DATA:
      newState.actions = action.payload.actions;
      return newState;
    case AssetActionTypes.LOAD_RISK_ACTIONS_DATA:
      newState.riskFieldActions = action.payload.actions;
      return newState;
    case AssetActionTypes.LOAD_ASSET_SEVERITY_DATA:
      newState.severity = action.payload.severity;
      return newState;
    default:
      return state;
  }
};

export const assetSelectors = {
  getLocation: (
    store: iState
  ): LocationState<{}, iState> & { type: RoutesKey } => store.location,
  getAssetIsProcessing: (store: iState): boolean =>
    store.asset.isProcessing ?? false,
  getAllAssetIsProcessing: (store: iState): boolean =>
    store.asset.isAllAssetsProcessing ?? false,
  getAssetData: (store: iState): iAssetsState => store.asset,
  getAllAssetsById: (store: iState): { [id: string]: iAsset } =>
    store.asset.allAssetsById,
};
