/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialField
 */
export interface CredentialField {
    /**
     * Name of the field
     * @type {string}
     * @memberof CredentialField
     */
    name: string;
    /**
     * A name to show in any UIs (customer-facing)
     * @type {string}
     * @memberof CredentialField
     */
    displayName: string;
    /**
     * Indicates whether this field should be encrypted
     * @type {boolean}
     * @memberof CredentialField
     */
    sensitive: boolean;
    /**
     * Data type of the field
     * @type {string}
     * @memberof CredentialField
     */
    dataType: string;
    /**
     * The order in which this field is rendered in the UI
     * @type {number}
     * @memberof CredentialField
     */
    order: number;
    /**
     * Indicates if a value is required
     * @type {boolean}
     * @memberof CredentialField
     */
    required: boolean;
    /**
     * Description of the field input
     * @type {string}
     * @memberof CredentialField
     */
    description: string;
    /**
     * Default value for the field
     * @type {string}
     * @memberof CredentialField
     */
    _default: string;
}

export function CredentialFieldFromJSON(json: any): CredentialField {
    return CredentialFieldFromJSONTyped(json, false);
}

export function CredentialFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayName': json['displayName'],
        'sensitive': json['sensitive'],
        'dataType': json['dataType'],
        'order': json['order'],
        'required': json['required'],
        'description': json['description'],
        '_default': json['default'],
    };
}

export function CredentialFieldToJSON(value?: CredentialField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'sensitive': value.sensitive,
        'dataType': value.dataType,
        'order': value.order,
        'required': value.required,
        'description': value.description,
        'default': value._default,
    };
}


