/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CronSchedule,
  CronScheduleFromJSON,
  CronScheduleFromJSONTyped,
  CronScheduleToJSON,
} from './';

/**
 * Input to request for editing an iva scan schedule
 * @export
 * @interface IvaScheduleInput
 */
export interface IvaScheduleInput {
  /**
   *
   * @type {string}
   * @memberof IvaScheduleInput
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof IvaScheduleInput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IvaScheduleInput
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof IvaScheduleInput
   */
  nextScanWindow?: Date;
  /**
   *
   * @type {Date}
   * @memberof IvaScheduleInput
   */
  createdTime?: Date;
  /**
   *
   * @type {boolean}
   * @memberof IvaScheduleInput
   */
  scanningDisabled?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof IvaScheduleInput
   */
  targetGroups?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof IvaScheduleInput
   */
  targets?: Array<string>;
  /**
   *
   * @type {Array<CronSchedule>}
   * @memberof IvaScheduleInput
   */
  schedule?: Array<CronSchedule>;
  /**
   *
   * @type {number}
   * @memberof IvaScheduleInput
   */
  priority?: number;
  /**
   * IANA timezone string, can be empty
   * @type {string}
   * @memberof IvaScheduleInput
   */
  timezone?: string;
}

export function IvaScheduleInputFromJSON(json: any): IvaScheduleInput {
  return IvaScheduleInputFromJSONTyped(json, false);
}

export function IvaScheduleInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IvaScheduleInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    nextScanWindow: !exists(json, 'nextScanWindow')
      ? undefined
      : new Date(json['nextScanWindow']),
    createdTime: !exists(json, 'createdTime')
      ? undefined
      : new Date(json['createdTime']),
    scanningDisabled: !exists(json, 'scanningDisabled')
      ? undefined
      : json['scanningDisabled'],
    targetGroups: !exists(json, 'targetGroups')
      ? undefined
      : json['targetGroups'],
    targets: !exists(json, 'targets') ? undefined : json['targets'],
    schedule: !exists(json, 'schedule')
      ? undefined
      : (json['schedule'] as Array<any>).map(CronScheduleFromJSON),
    priority: !exists(json, 'priority') ? undefined : json['priority'],
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
  };
}

export function IvaScheduleInputToJSON(value?: IvaScheduleInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    nextScanWindow:
      value.nextScanWindow === undefined
        ? undefined
        : value.nextScanWindow.toISOString(),
    createdTime:
      value.createdTime === undefined
        ? undefined
        : value.createdTime.toISOString(),
    scanningDisabled: value.scanningDisabled,
    targetGroups: value.targetGroups,
    targets: value.targets,
    schedule:
      value.schedule === undefined
        ? undefined
        : (value.schedule as Array<any>).map(CronScheduleToJSON),
    priority: value.priority,
    timezone: value.timezone,
  };
}
