import moment from 'moment';
import { Cron, CronType, Schedule, ScheduleWindow } from '../types/scanner';
import { Frequency } from '../apiClient/rendall';

export function parseRawData(objArr: Array<any>) {
  const arr = typeof objArr != 'object' ? JSON.parse(objArr) : objArr;
  let str = '';

  for (let i = 0; i < arr.length; i++) {
    let line = '';
    for (const index in arr[i]) {
      if (line !== '') {
        line = line + ',';
      }
      line = line + arr[i][index];
    }
    str = str + line + '\r\n';
  }
  return str;
}

export const parseDate = (str: any) => {
  const dateArray = str.match(/\d+/g);
  const year = dateArray[0] !== undefined ? dateArray[0] : '';
  const month = dateArray[1] !== undefined ? dateArray[1] : '';
  const day = dateArray[2] !== undefined ? dateArray[2] : '';

  return new Date(year, month, day);
};

export const parseCronType = (cron: Cron) => {
  if (cron?.dayOfMonth) {
    return CronType.MONTHLY;
  } else if (cron?.dayOfWeek) {
    return CronType.WEEKLY;
  } else if (cron?.hour) {
    return CronType.DAILY;
  }
  return CronType.CONTINUOUS;
};

export const convertSchedulesToLocal = (incomingSchedules: Schedule[]) => {
  const schedules: Schedule[] = [];
  for (let i = 0; i < incomingSchedules.length; i++) {
    schedules.push(
      convertScheduleToLocal(incomingSchedules[i]) || incomingSchedules[i]
    );
  }
  return schedules;
};

export const convertScheduleToLocal = (incomingSchedule?: Schedule) => {
  if (!incomingSchedule || !incomingSchedule.windows) return incomingSchedule;
  const windows: ScheduleWindow[] = [];
  for (let x = 0; x < incomingSchedule.windows.length; x++) {
    if (incomingSchedule.windows[x]) {
      windows.push(
        convertWindowToLocal(
          incomingSchedule.windows[x],
          incomingSchedule.timezone
        )
      );
    } else {
      windows.push(incomingSchedule.windows[x]);
    }
  }
  return { ...incomingSchedule, windows: windows };
};

const convertCronToMoment = (cron: Cron, type: string, timezone: string) => {
  let hour = 1;
  let minute = 1;
  if (cron.hour) hour = cron.hour[0];
  if (cron.minute) minute = cron.minute[0];

  let convertedDate = moment().tz(timezone).hour(hour).minute(minute);
  if (type === Frequency.Monthly) {
    let dayOfMonth = 1;
    if (cron.dayOfMonth) dayOfMonth = cron.dayOfMonth[0];
    convertedDate = convertedDate.date(dayOfMonth);
  } else if (type === Frequency.Weekly) {
    let dayOfWeek = 1;
    if (cron.dayOfWeek) dayOfWeek = cron.dayOfWeek[0];
    convertedDate = convertedDate.day(dayOfWeek);
  }
  return convertedDate;
};

const convertWindowToLocal = (
  incomingWindow: ScheduleWindow,
  timezone: string
): ScheduleWindow => {
  if (!incomingWindow) return incomingWindow;
  const { cron } = incomingWindow;
  let type = Frequency.Continuous;
  if (cron.dayOfMonth) {
    type = Frequency.Monthly;
  } else if (cron.dayOfWeek) {
    type = Frequency.Weekly;
  } else if (cron.hour && cron.hour.length > 0) {
    type = Frequency.Daily;
  }
  // no conversion needs to happen on continuous
  if (type === Frequency.Continuous) return incomingWindow;

  const convertedDate = convertCronToMoment(cron, type, timezone).local();
  const scheduleWindow: ScheduleWindow = {
    cron: {
      minute: [convertedDate.minute()],
      hour: [convertedDate.hour()],
      dayOfMonth:
        type === Frequency.Monthly ? [convertedDate.date()] : cron.dayOfMonth,
      dayOfWeek:
        type === Frequency.Weekly ? [convertedDate.day()] : cron.dayOfWeek,
      month: cron.month,
      year: cron.year,
    },
    lengthMinute: incomingWindow.lengthMinute,
  };
  return scheduleWindow;
};

const leftZeroPad = (num: number, digits: number) =>
  `${'0'.repeat(digits)}${num}`.substr(-digits);

export const parseSchedule = (
  schedule: Schedule
): { intervals: string[]; windows: string[] } => {
  const intervals: string[] = [];
  const windows: string[] = [];
  if (!schedule.windows) {
    intervals.push('Continuous');
    windows.push('--');
  } else {
    schedule.windows.forEach((window) => {
      const { cron } = window;
      let time = '';
      if (cron.hour && cron.minute) {
        time = `${cron.hour[0]}:${leftZeroPad(
          (cron?.minute || [0])[0],
          2
        )} ${moment().tz(moment.tz.guess()).format('z')}`;
      }
      let interval;
      let hours;
      if (cron.dayOfMonth) {
        interval = `Monthly on ${cron.dayOfMonth} at ${time}`;
        hours = window.lengthMinute / 60;
      } else if (cron.dayOfWeek) {
        interval = `Weekly on ${cron.dayOfWeek
          .map((d) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d])
          .join(',')} at ${time}`;
        hours = window.lengthMinute / 60;
      } else if (cron.hour && cron.hour.length > 0) {
        interval = `Daily at ${time}`;
        hours = window.lengthMinute / 60;
      } else {
        interval = 'Continuous';
        hours = '--';
      }

      intervals.push(interval);
      windows.push(`${hours}`);
    });
  }
  return { intervals, windows };
};
