/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the status of an agent
 * @export
 * @enum {string}
 */
export enum AgentStatus {
    Online = 'online',
    Offline = 'offline',
    Inactive = 'inactive',
    Contained = 'contained'
}

export function AgentStatusFromJSON(json: any): AgentStatus {
    return AgentStatusFromJSONTyped(json, false);
}

export function AgentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentStatus {
    return json as AgentStatus;
}

export function AgentStatusToJSON(value?: AgentStatus | null): any {
    return value as any;
}

