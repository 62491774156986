/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LogSearchRequestInput
 */
export interface LogSearchRequestInput {
  /**
   *
   * @type {string}
   * @memberof LogSearchRequestInput
   */
  query: string;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof LogSearchRequestInput
   */
  startTime: Date;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof LogSearchRequestInput
   */
  endTime: Date;
  /**
   *
   * @type {boolean}
   * @memberof LogSearchRequestInput
   */
  caseSensitive: boolean;
  /**
   *
   * @type {object}
   * @memberof LogSearchRequestInput
   */
  logSourcesTags: object;
}

export function LogSearchRequestInputFromJSON(
  json: any
): LogSearchRequestInput {
  return LogSearchRequestInputFromJSONTyped(json, false);
}

export function LogSearchRequestInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogSearchRequestInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    query: json['query'],
    startTime: new Date(json['startTime']),
    endTime: new Date(json['endTime']),
    caseSensitive: json['caseSensitive'],
    logSourcesTags: json['logSourcesTags'],
  };
}

export function LogSearchRequestInputToJSON(
  value?: LogSearchRequestInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    query: value.query,
    startTime: value.startTime.toISOString(),
    endTime: value.endTime.toISOString(),
    caseSensitive: value.caseSensitive,
    logSourcesTags: value.logSourcesTags,
  };
}
