/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CronSchedule,
  CronScheduleFromJSON,
  CronScheduleFromJSONTyped,
  CronScheduleToJSON,
} from './';

/**
 *
 * @export
 * @interface CronWindow
 */
export interface CronWindow {
  /**
   *
   * @type {CronSchedule}
   * @memberof CronWindow
   */
  cron?: CronSchedule;
  /**
   *
   * @type {number}
   * @memberof CronWindow
   */
  lengthMinute?: number;
}

export function CronWindowFromJSON(json: any): CronWindow {
  return CronWindowFromJSONTyped(json, false);
}

export function CronWindowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CronWindow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cron: !exists(json, 'cron')
      ? undefined
      : CronScheduleFromJSON(json['cron']),
    lengthMinute: !exists(json, 'lengthMinute')
      ? undefined
      : json['lengthMinute'],
  };
}

export function CronWindowToJSON(value?: CronWindow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cron: CronScheduleToJSON(value.cron),
    lengthMinute: value.lengthMinute,
  };
}
