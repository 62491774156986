/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A summarized representation of all deployed Agents
 * @export
 * @interface AgentSummary
 */
export interface AgentSummary {
    /**
     * 
     * @type {number}
     * @memberof AgentSummary
     */
    online: number;
    /**
     * 
     * @type {number}
     * @memberof AgentSummary
     */
    offline: number;
    /**
     * 
     * @type {number}
     * @memberof AgentSummary
     */
    inactive: number;
    /**
     * 
     * @type {number}
     * @memberof AgentSummary
     */
    contained: number;
}

export function AgentSummaryFromJSON(json: any): AgentSummary {
    return AgentSummaryFromJSONTyped(json, false);
}

export function AgentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'online': json['online'],
        'offline': json['offline'],
        'inactive': json['inactive'],
        'contained': json['contained'],
    };
}

export function AgentSummaryToJSON(value?: AgentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'online': value.online,
        'offline': value.offline,
        'inactive': value.inactive,
        'contained': value.contained,
    };
}


