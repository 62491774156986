import React from 'react';
import styled from 'styled-components';
import { RowTileV2 } from '../../../Reusables/RowTileV2';
import { useAttackSurfaceContext } from '../Context/AscContext';
import { CountTile, Tile } from './CountTile';
import { HStack } from '@rtkwlf/fenrir-react';

const ContainerDiv = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: 10px;
`;

export type Props = {
  description: string;
  title?: string;
};

export const AssetCountContainer = ({ description, title }: Props) => {
  const { scouts, reach, iva } = useAttackSurfaceContext();
  const tiles: Tile[] = [
    {
      title: 'IVA',
      display: Object.keys(iva.assets).length.toString(),
    },
    {
      title: 'EVA',
      display: Object.keys(reach.hosts).length.toString(),
    },
    {
      title: 'CSPM',
      display: Object.keys(reach.cspm_accounts).length.toString(),
    },
    {
      title: 'Agent',
      display: Object.keys(scouts.clients).length.toString(),
    },
  ];

  return (
    <ContainerDiv data-testid='asset-count-container'>
      <RowTileV2
        data-testid='table-row-header'
        id='AscAssetCount'
        title={title || 'Asset Count'}
        description={description}
        buttons={
          <HStack>
            <div data-testid='asc_asset-count-export'></div>
          </HStack>
        }
      >
        <CountTile tiles={tiles} />
      </RowTileV2>
    </ContainerDiv>
  );
};
