/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CsvContents
 */
export interface CsvContents {
  /**
   *
   * @type {string}
   * @memberof CsvContents
   */
  name?: string;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof CsvContents
   */
  contents?: Array<Array<string>>;
}

export function CsvContentsFromJSON(json: any): CsvContents {
  return CsvContentsFromJSONTyped(json, false);
}

export function CsvContentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CsvContents {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    contents: !exists(json, 'contents') ? undefined : json['contents'],
  };
}

export function CsvContentsToJSON(value?: CsvContents | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    contents: value.contents,
  };
}
