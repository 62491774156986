/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AWSStackObject,
  AWSStackObjectFromJSON,
  AWSStackObjectFromJSONTyped,
  AWSStackObjectToJSON,
  CSPMObject,
  CSPMObjectFromJSON,
  CSPMObjectFromJSONTyped,
  CSPMObjectToJSON,
  IntegrationsNameObject,
  IntegrationsNameObjectFromJSON,
  IntegrationsNameObjectFromJSONTyped,
  IntegrationsNameObjectToJSON,
  SecurityAwarenessObject,
  SecurityAwarenessObjectFromJSON,
  SecurityAwarenessObjectFromJSONTyped,
  SecurityAwarenessObjectToJSON,
  StructureObject,
  StructureObjectFromJSON,
  StructureObjectFromJSONTyped,
  StructureObjectToJSON,
} from './';

/**
 *
 * @export
 * @interface ServiceEndpoints
 */
export interface ServiceEndpoints {
  /**
   *
   * @type {Array<StructureObject>}
   * @memberof ServiceEndpoints
   */
  agents?: Array<StructureObject>;
  /**
   *
   * @type {Array<StructureObject>}
   * @memberof ServiceEndpoints
   */
  scannersVirtual202AndAboveModels?: Array<StructureObject>;
  /**
   *
   * @type {Array<StructureObject>}
   * @memberof ServiceEndpoints
   */
  scannerOtherModels?: Array<StructureObject>;
  /**
   *
   * @type {Array<string>}
   * @memberof ServiceEndpoints
   */
  externalScans?: Array<string>;
  /**
   *
   * @type {Array<StructureObject>}
   * @memberof ServiceEndpoints
   */
  sensors?: Array<StructureObject>;
  /**
   *
   * @type {Array<string>}
   * @memberof ServiceEndpoints
   */
  cloudServiceIntegrations?: Array<string>;
  /**
   *
   * @type {Array<IntegrationsNameObject>}
   * @memberof ServiceEndpoints
   */
  thirdPartyIntegrations?: Array<IntegrationsNameObject>;
  /**
   *
   * @type {Array<SecurityAwarenessObject>}
   * @memberof ServiceEndpoints
   */
  securityAwareness?: Array<SecurityAwarenessObject>;
  /**
   *
   * @type {Array<AWSStackObject>}
   * @memberof ServiceEndpoints
   */
  awsStacks?: Array<AWSStackObject>;
  /**
   *
   * @type {Array<CSPMObject>}
   * @memberof ServiceEndpoints
   */
  cspm?: Array<CSPMObject>;
}

export function ServiceEndpointsFromJSON(json: any): ServiceEndpoints {
  return ServiceEndpointsFromJSONTyped(json, false);
}

export function ServiceEndpointsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ServiceEndpoints {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agents: !exists(json, 'agents')
      ? undefined
      : (json['agents'] as Array<any>).map(StructureObjectFromJSON),
    scannersVirtual202AndAboveModels: !exists(
      json,
      'scannersVirtual202AndAboveModels'
    )
      ? undefined
      : (json['scannersVirtual202AndAboveModels'] as Array<any>).map(
          StructureObjectFromJSON
        ),
    scannerOtherModels: !exists(json, 'scannerOtherModels')
      ? undefined
      : (json['scannerOtherModels'] as Array<any>).map(StructureObjectFromJSON),
    externalScans: !exists(json, 'externalScans')
      ? undefined
      : json['externalScans'],
    sensors: !exists(json, 'sensors')
      ? undefined
      : (json['sensors'] as Array<any>).map(StructureObjectFromJSON),
    cloudServiceIntegrations: !exists(json, 'cloudServiceIntegrations')
      ? undefined
      : json['cloudServiceIntegrations'],
    thirdPartyIntegrations: !exists(json, 'thirdPartyIntegrations')
      ? undefined
      : (json['thirdPartyIntegrations'] as Array<any>).map(
          IntegrationsNameObjectFromJSON
        ),
    securityAwareness: !exists(json, 'securityAwareness')
      ? undefined
      : (json['securityAwareness'] as Array<any>).map(
          SecurityAwarenessObjectFromJSON
        ),
    awsStacks: !exists(json, 'awsStacks')
      ? undefined
      : (json['awsStacks'] as Array<any>).map(AWSStackObjectFromJSON),
    cspm: !exists(json, 'cspm')
      ? undefined
      : (json['cspm'] as Array<any>).map(CSPMObjectFromJSON),
  };
}

export function ServiceEndpointsToJSON(value?: ServiceEndpoints | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agents:
      value.agents === undefined
        ? undefined
        : (value.agents as Array<any>).map(StructureObjectToJSON),
    scannersVirtual202AndAboveModels:
      value.scannersVirtual202AndAboveModels === undefined
        ? undefined
        : (value.scannersVirtual202AndAboveModels as Array<any>).map(
            StructureObjectToJSON
          ),
    scannerOtherModels:
      value.scannerOtherModels === undefined
        ? undefined
        : (value.scannerOtherModels as Array<any>).map(StructureObjectToJSON),
    externalScans: value.externalScans,
    sensors:
      value.sensors === undefined
        ? undefined
        : (value.sensors as Array<any>).map(StructureObjectToJSON),
    cloudServiceIntegrations: value.cloudServiceIntegrations,
    thirdPartyIntegrations:
      value.thirdPartyIntegrations === undefined
        ? undefined
        : (value.thirdPartyIntegrations as Array<any>).map(
            IntegrationsNameObjectToJSON
          ),
    securityAwareness:
      value.securityAwareness === undefined
        ? undefined
        : (value.securityAwareness as Array<any>).map(
            SecurityAwarenessObjectToJSON
          ),
    awsStacks:
      value.awsStacks === undefined
        ? undefined
        : (value.awsStacks as Array<any>).map(AWSStackObjectToJSON),
    cspm:
      value.cspm === undefined
        ? undefined
        : (value.cspm as Array<any>).map(CSPMObjectToJSON),
  };
}
