/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ScannerType {
  Physical = 'physical',
  Virtual = 'virtual',
}

export function ScannerTypeFromJSON(json: any): ScannerType {
  return ScannerTypeFromJSONTyped(json, false);
}

export function ScannerTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScannerType {
  return json as ScannerType;
}

export function ScannerTypeToJSON(value?: ScannerType | null): any {
  return value as any;
}
