/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentHostLocation,
    AgentHostLocationFromJSON,
    AgentHostLocationFromJSONTyped,
    AgentHostLocationToJSON,
    AgentHostNetwork,
    AgentHostNetworkFromJSON,
    AgentHostNetworkFromJSONTyped,
    AgentHostNetworkToJSON,
    AgentHostOs,
    AgentHostOsFromJSON,
    AgentHostOsFromJSONTyped,
    AgentHostOsToJSON,
    AgentHostPlugins,
    AgentHostPluginsFromJSON,
    AgentHostPluginsFromJSONTyped,
    AgentHostPluginsToJSON,
    AgentUser,
    AgentUserFromJSON,
    AgentUserFromJSONTyped,
    AgentUserToJSON,
} from './';

/**
 * Host attributes
 * @export
 * @interface AgentHost
 */
export interface AgentHost {
    /**
     * 
     * @type {string}
     * @memberof AgentHost
     */
    hostname?: string;
    /**
     * 
     * @type {AgentHostOs}
     * @memberof AgentHost
     */
    os?: AgentHostOs;
    /**
     * 
     * @type {AgentHostNetwork}
     * @memberof AgentHost
     */
    network?: AgentHostNetwork;
    /**
     * 
     * @type {AgentHostLocation}
     * @memberof AgentHost
     */
    location?: AgentHostLocation;
    /**
     * 
     * @type {AgentHostPlugins}
     * @memberof AgentHost
     */
    plugins?: AgentHostPlugins;
    /**
     * 
     * @type {Array<AgentUser>}
     * @memberof AgentHost
     */
    users?: Array<AgentUser>;
}

export function AgentHostFromJSON(json: any): AgentHost {
    return AgentHostFromJSONTyped(json, false);
}

export function AgentHostFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentHost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
        'os': !exists(json, 'os') ? undefined : AgentHostOsFromJSON(json['os']),
        'network': !exists(json, 'network') ? undefined : AgentHostNetworkFromJSON(json['network']),
        'location': !exists(json, 'location') ? undefined : AgentHostLocationFromJSON(json['location']),
        'plugins': !exists(json, 'plugins') ? undefined : AgentHostPluginsFromJSON(json['plugins']),
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(AgentUserFromJSON)),
    };
}

export function AgentHostToJSON(value?: AgentHost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hostname': value.hostname,
        'os': AgentHostOsToJSON(value.os),
        'network': AgentHostNetworkToJSON(value.network),
        'location': AgentHostLocationToJSON(value.location),
        'plugins': AgentHostPluginsToJSON(value.plugins),
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(AgentUserToJSON)),
    };
}


