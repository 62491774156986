/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An individual coverage score object
 * @export
 * @interface CoverageScoreDetails
 */
export interface CoverageScoreDetails {
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CoverageScoreDetails
   */
  customer: string;
  /**
   * The overall coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  coverageScore: number;
  /**
   * The configuration sub-component of coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  configurationScore: number;
  /**
   * The monitoring sub-component of coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  monitoringScore: number;
  /**
   * The incidents sub-component of coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  incidentsScore: number;
  /**
   * The ticketing sub-component of coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  ticketingScore: number;
  /**
   * The exscan sub-component of coverage score
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  exscanScore: number;
  /**
   *
   * @type {Date}
   * @memberof CoverageScoreDetails
   */
  timestamp: Date;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numInternalNmapScannedInPastWeek: number;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numScanTypesConfigured: number;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  hasRecentSnmpObservations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  adNxlogParserExists: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  fwLogParserExists: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  customerInGeoApprovedOrRestrictedList: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  adPollerLogsExist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  obsExistForConfiguredCloudApps: boolean;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numCloudAppsConfigured: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CoverageScoreDetails
   */
  cloudAppsConfigured: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numCloudAppsDetected: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CoverageScoreDetails
   */
  cloudAppsDetected: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  serverAvFound: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoverageScoreDetails
   */
  desktopAvFound: boolean;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numMonthlyIncidents: number;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numEscalationsConfigured: number;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numCriticalVulnerabilities: number;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numHighVulnerabilities: number;
  /**
   *
   * @type {number}
   * @memberof CoverageScoreDetails
   */
  numberOfEmployees: number;
  /**
   *
   * @type {string}
   * @memberof CoverageScoreDetails
   */
  industry: string;
}

export function CoverageScoreDetailsFromJSON(json: any): CoverageScoreDetails {
  return CoverageScoreDetailsFromJSONTyped(json, false);
}

export function CoverageScoreDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CoverageScoreDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    customer: json['customer'],
    coverageScore: json['coverageScore'],
    configurationScore: json['configurationScore'],
    monitoringScore: json['monitoringScore'],
    incidentsScore: json['incidentsScore'],
    ticketingScore: json['ticketingScore'],
    exscanScore: json['exscanScore'],
    timestamp: new Date(json['timestamp']),
    numInternalNmapScannedInPastWeek: json['numInternalNmapScannedInPastWeek'],
    numScanTypesConfigured: json['numScanTypesConfigured'],
    hasRecentSnmpObservations: json['hasRecentSnmpObservations'],
    adNxlogParserExists: json['adNxlogParserExists'],
    fwLogParserExists: json['fwLogParserExists'],
    customerInGeoApprovedOrRestrictedList:
      json['customerInGeoApprovedOrRestrictedList'],
    adPollerLogsExist: json['adPollerLogsExist'],
    obsExistForConfiguredCloudApps: json['obsExistForConfiguredCloudApps'],
    numCloudAppsConfigured: json['numCloudAppsConfigured'],
    cloudAppsConfigured: json['cloudAppsConfigured'],
    numCloudAppsDetected: json['numCloudAppsDetected'],
    cloudAppsDetected: json['cloudAppsDetected'],
    serverAvFound: json['serverAvFound'],
    desktopAvFound: json['desktopAvFound'],
    numMonthlyIncidents: json['numMonthlyIncidents'],
    numEscalationsConfigured: json['numEscalationsConfigured'],
    numCriticalVulnerabilities: json['numCriticalVulnerabilities'],
    numHighVulnerabilities: json['numHighVulnerabilities'],
    numberOfEmployees: json['numberOfEmployees'],
    industry: json['industry'],
  };
}

export function CoverageScoreDetailsToJSON(
  value?: CoverageScoreDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    customer: value.customer,
    coverageScore: value.coverageScore,
    configurationScore: value.configurationScore,
    monitoringScore: value.monitoringScore,
    incidentsScore: value.incidentsScore,
    ticketingScore: value.ticketingScore,
    exscanScore: value.exscanScore,
    timestamp: value.timestamp.toISOString(),
    numInternalNmapScannedInPastWeek: value.numInternalNmapScannedInPastWeek,
    numScanTypesConfigured: value.numScanTypesConfigured,
    hasRecentSnmpObservations: value.hasRecentSnmpObservations,
    adNxlogParserExists: value.adNxlogParserExists,
    fwLogParserExists: value.fwLogParserExists,
    customerInGeoApprovedOrRestrictedList:
      value.customerInGeoApprovedOrRestrictedList,
    adPollerLogsExist: value.adPollerLogsExist,
    obsExistForConfiguredCloudApps: value.obsExistForConfiguredCloudApps,
    numCloudAppsConfigured: value.numCloudAppsConfigured,
    cloudAppsConfigured: value.cloudAppsConfigured,
    numCloudAppsDetected: value.numCloudAppsDetected,
    cloudAppsDetected: value.cloudAppsDetected,
    serverAvFound: value.serverAvFound,
    desktopAvFound: value.desktopAvFound,
    numMonthlyIncidents: value.numMonthlyIncidents,
    numEscalationsConfigured: value.numEscalationsConfigured,
    numCriticalVulnerabilities: value.numCriticalVulnerabilities,
    numHighVulnerabilities: value.numHighVulnerabilities,
    numberOfEmployees: value.numberOfEmployees,
    industry: value.industry,
  };
}
