/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommentInput,
    CommentInputFromJSON,
    CommentInputFromJSONTyped,
    CommentInputToJSON,
} from './';

/**
 * the input to adding a comment to a ticket
 * @export
 * @interface CreateTicketCommentInput
 */
export interface CreateTicketCommentInput {
    /**
     * 
     * @type {CommentInput}
     * @memberof CreateTicketCommentInput
     */
    comment?: CommentInput;
}

export function CreateTicketCommentInputFromJSON(json: any): CreateTicketCommentInput {
    return CreateTicketCommentInputFromJSONTyped(json, false);
}

export function CreateTicketCommentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTicketCommentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : CommentInputFromJSON(json['comment']),
    };
}

export function CreateTicketCommentInputToJSON(value?: CreateTicketCommentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': CommentInputToJSON(value.comment),
    };
}


