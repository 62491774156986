/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * https://developer.zendesk.com/api-reference/ticketing/tickets/tickets/#status
 * @export
 * @enum {string}
 */
export enum TicketStatus {
    New = 'new',
    Open = 'open',
    Pending = 'pending',
    Hold = 'hold',
    Solved = 'solved',
    Closed = 'closed'
}

export function TicketStatusFromJSON(json: any): TicketStatus {
    return TicketStatusFromJSONTyped(json, false);
}

export function TicketStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketStatus {
    return json as TicketStatus;
}

export function TicketStatusToJSON(value?: TicketStatus | null): any {
    return value as any;
}

