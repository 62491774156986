/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReportFormat,
  ReportFormatFromJSON,
  ReportFormatFromJSONTyped,
  ReportFormatToJSON,
} from './';

/**
 *
 * @export
 * @interface Report
 */
export interface Report {
  /**
   *
   * @type {string}
   * @memberof Report
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  title: string;
  /**
   *
   * @type {ReportFormat}
   * @memberof Report
   */
  format: ReportFormat;
  /**
   *
   * @type {Date}
   * @memberof Report
   */
  sentDate: Date;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  reportingPeriod: string;
}

export function ReportFromJSON(json: any): Report {
  return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Report {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    format: ReportFormatFromJSON(json['format']),
    sentDate: new Date(json['sentDate']),
    reportingPeriod: json['reportingPeriod'],
  };
}

export function ReportToJSON(value?: Report | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    format: ReportFormatToJSON(value.format),
    sentDate: value.sentDate.toISOString(),
    reportingPeriod: value.reportingPeriod,
  };
}
