/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the status of a given sensor
 * @export
 * @enum {string}
 */
export enum SensorStatus {
    Unhealthy = 'unhealthy',
    Staged = 'staged',
    AwaitingActivation = 'awaitingActivation',
    Shipped = 'shipped',
    PreparingToShip = 'preparingToShip',
    Healthy = 'healthy'
}

export function SensorStatusFromJSON(json: any): SensorStatus {
    return SensorStatusFromJSONTyped(json, false);
}

export function SensorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorStatus {
    return json as SensorStatus;
}

export function SensorStatusToJSON(value?: SensorStatus | null): any {
    return value as any;
}

