import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RowTile } from '../../Reusables/RowTile';
import { DisplayTable } from '../../Reusables/DisplayTable';
import { Schedule } from '../../types/scanner';
import { parseSchedule } from '../../utils/parsers';
import { EditButton } from '../../Reusables/EditButton';
import DeleteButton from '../../Reusables/DeleteButton';
import styled from 'styled-components';
import { Button } from '@rtkwlf/fenrir-react';
import ScanningDisableToggle from '../../Reusables/ScanningDisableToggle';
import { ScanningDisableControls } from '../../Reusables/ScanningDisableControls';
import { useMutation } from '@tanstack/react-query';
import riskAPI from '../../utils/riskAPI';
import { SCANNER_ACTIONS } from '../../Global/scannerActions';
import { iState } from '../../configureStore';
import { useAppDispatch } from '../../hooks';
import { ManageIvaScheduleState } from '../../apiClient/rendall';
import { useRendallApi } from '../../apiClient/useRendallApi';
import { customerSelectors } from '../../Global/customerReducer';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { ScannerSchedule } from '../../Modals/Modals/ScannerSchedule';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScanDisableWarning =
  'Disabling a scan schedule will stop currently running scans.';

const headers = [
  'Target',
  'Next Scan Time',
  'Schedule',
  'Window (hours)',
  'Priority',
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    }}
  >
    <div style={{ marginRight: '2%', display: 'contents' }}>
      Schedule Enabled
    </div>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            color: '#58595B',
            fontSize: '14px',
            marginLeft: '5px',
            width: '20px',
          }}
          cursor='pointer'
        />
      </Tooltip.Trigger>
      <Tooltip.Content side='top'>
        <Tooltip.Text>{ScanDisableWarning}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip.Root>
  </div>,
  'Modify',
];

export type ScanningScheduleProps = {
  deleteSchedule: (id: string) => void;
  schedules: Schedule[];
};

type Target = {
  target: string;
  scantype: string;
};

type Targets = {
  targets: Target[];
};

const PRIORITY: any = {
  1: 'High',
  50: 'Medium',
  100: 'Low',
};

const StyledContainer = styled.div`
  display: flex;
`;

const StyledDiv = styled.div`
  margin: 0px -20px 0px 0px;
`;

export const ScanningSchedule = ({
  deleteSchedule,
  schedules,
}: ScanningScheduleProps) => {
  const dispatch = useAppDispatch();

  const scannerId = useSelector((state: iState) => state?.scanner?.scanner?.id);

  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const cancelScans = useMutation({
    mutationFn: (targets: Targets) => {
      return riskAPI.post(
        `/rootsecure/v3/scanners/${scannerId}/scans/cancel`,
        targets
      );
    },
  });

  const [editId, setEditId] = useState<string | undefined>();

  const cancelSchedule = useMutation({
    mutationFn: (schedule: Schedule) => {
      return riskAPI.put(
        `/rootsecure/v3/scanners/${scannerId}/schedules/${schedule.id}`,
        schedule,
        { maxRedirects: 2 }
      );
    },
  });

  const isLoading = cancelScans.isPending || cancelSchedule.isPending;

  const toggleScanSchedule = React.useCallback(
    async (schedule: Schedule) => {
      // build targets object for cancel request
      const targets: Target[] = schedule.targets.map((target: string) => ({
        target: target,
        scantype: 'OVS',
      }));

      // create updated schedule with disabled flag toggled
      const updatedSchedule = { ...schedule, disabled: !schedule.disabled };

      // send requests in parallel
      // only send the cancel request when going from: ON => OFF
      const stopScans = !schedule.disabled
        ? cancelScans.mutateAsync({ targets })
        : Promise.resolve({ status: 'fulfilled' });
      const stopSchedule = cancelSchedule.mutateAsync(updatedSchedule);

      stopSchedule.then(() => {
        // update Redux store when reponse is successfully in queue
        dispatch(SCANNER_ACTIONS.UPDATE_SCHEDULE(scannerId, updatedSchedule));
      });

      // await the resolutions
      const responses = await Promise.allSettled([
        stopSchedule,
        stopScans,
      ]).then((results) => results.map((r) => r.status === 'fulfilled'));

      // return request outcomes
      return responses;
    },
    [cancelScans, cancelSchedule, dispatch, scannerId]
  );

  const api = useRendallApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  const disableSchedule = useMutation({
    mutationFn: (ivaScanParams: ManageIvaScheduleState[]) =>
      api.bulkDisableIvaSchedule({
        organizationId,
        manageIvaScheduleStateInputs: {
          disable: ivaScanParams,
        },
      }),
    onSuccess: () => {
      const updatedSchedules = schedules
        .filter((s) => s.disabled === false)
        .map((s) => ({ ...s, disabled: true }));

      dispatch(SCANNER_ACTIONS.LOAD_SCHEDULES(scannerId, updatedSchedules));
    },
  });

  const stopAllScanSchedule = React.useCallback(
    async (schedules: Schedule[]) => {
      const scheduleIvaDisableInput: ManageIvaScheduleState[] = [];

      schedules.forEach((schedule) => {
        scheduleIvaDisableInput.push({
          scheduleId: schedule.id,
          scannerId: scannerId,
          scanningDisabled: true,
        });
      });

      const schedulesStopped = await disableSchedule
        .mutateAsync(scheduleIvaDisableInput)
        .then(() => true)
        .catch((e) => {
          console.warn(e);
          return false;
        });

      // get targets object for cancel scans request
      const targets: Target[] = schedules.flatMap((s: Schedule) =>
        s.targets.map((target: string) => ({
          target: target,
          scantype: 'OVS',
        }))
      );
      // response for cancel scan
      const scansStopped = await cancelScans
        .mutateAsync({ targets })
        .then(() => true)
        .catch((e) => {
          console.warn(e);
          return false;
        });

      return [schedulesStopped, scansStopped];
    },
    [cancelScans, disableSchedule, scannerId]
  );

  const [showStopScanModal, setShowStopScanModal] = React.useState(false);

  // create the rows
  const createRows = React.useMemo((): any[][] => {
    const newRows = [];
    for (const schedule of schedules) {
      const { intervals, windows } = parseSchedule(schedule);
      newRows.push([
        schedule.targets,
        schedule.nextScanWindow,
        Array.from(new Set(intervals)),
        Array.from(new Set(windows)),
        PRIORITY[schedule.priority],
        <ScanningDisableToggle
          modalTitle='Disable Scan Schedule'
          modalSentence='This stops and disables any running scans.'
          warning='This request will queue and may take several minutes to resolve.'
          buttonText='Stop Scan Schedule'
          checked={!schedule.disabled}
          isLoading={isLoading}
          modalConfirm={() => toggleScanSchedule(schedule)}
        />,
        <StyledContainer data-testid='row-button-wrap'>
          <EditButton
            onClick={() => {
              setEditId(schedule.id);
              setShowAddEditModal(true);
            }}
          />
          <DeleteButton
            title='Delete Schedule'
            message={
              <div>
                Please Confirm.
                <br />
                <br />
                Are you sure that you want to delete this schedule?
              </div>
            }
            yesClick={() => deleteSchedule(schedule.id)}
            yesButtonText='Delete Schedule'
            noButtonText='Cancel'
          />
        </StyledContainer>,
      ]);
    }
    return newRows;
  }, [deleteSchedule, isLoading, schedules, toggleScanSchedule]); // dependencies

  const stopAllSchedules = useCallback(
    () => stopAllScanSchedule(schedules),
    [stopAllScanSchedule, schedules]
  );

  const buttons = [
    <Button
      variant='secondary'
      onClick={() => setShowAddEditModal(true)}
      key='Add'
    >
      Add a new scan schedule
    </Button>,
    [
      <ScanningDisableControls
        key='Stop'
        modalTitle={`Stop All Scan Schedules`}
        modalSentenceOne={`This stops all active scans and disables all scheduled scans.`}
        modalSentenceTwo={`Scheduled scans need to be re-enabled.`}
        warning={
          'This request will queue and may take several minutes to resolve.'
        }
        buttonText={`Stop All Scan Schedules`}
        showModal={showStopScanModal}
        modalClose={() => setShowStopScanModal(false)}
        modalConfirm={stopAllSchedules}
        stopScanClick={() => setShowStopScanModal(true)}
        isLoading={isLoading}
      />,
    ],
  ];

  const cancel = useCallback(() => {
    setEditId(undefined);
    setShowAddEditModal(false);
  }, []);

  return (
    <StyledDiv>
      <RowTile
        id='scanning-schedule'
        title='Scanning Schedule'
        buttons={buttons}
        description='Modify the schedule of your scans.'
        buttonsContainerStyle={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          right: 0,
          marginRight: '10px',
        }}
        newStyle
      >
        <DisplayTable headers={headers} rows={createRows} />
      </RowTile>

      {showAddEditModal && (
        <ModalBox onCloseModal={cancel} position={ModalPositionType.top}>
          <ScannerSchedule uuid={editId} cancel={cancel} />
        </ModalBox>
      )}
    </StyledDiv>
  );
};

ScanningSchedule.displayName = 'ScanningSchedule';
