/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DeleteEvaSchedule,
  DeleteEvaScheduleFromJSON,
  DeleteEvaScheduleFromJSONTyped,
  DeleteEvaScheduleToJSON,
} from './';

/**
 * Input to delete eva scan schedules
 * @export
 * @interface DeleteEvaSchedulesInput
 */
export interface DeleteEvaSchedulesInput {
  /**
   *
   * @type {Array<DeleteEvaSchedule>}
   * @memberof DeleteEvaSchedulesInput
   */
  _delete?: Array<DeleteEvaSchedule>;
}

export function DeleteEvaSchedulesInputFromJSON(
  json: any
): DeleteEvaSchedulesInput {
  return DeleteEvaSchedulesInputFromJSONTyped(json, false);
}

export function DeleteEvaSchedulesInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteEvaSchedulesInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _delete: !exists(json, 'delete')
      ? undefined
      : (json['delete'] as Array<any>).map(DeleteEvaScheduleFromJSON),
  };
}

export function DeleteEvaSchedulesInputToJSON(
  value?: DeleteEvaSchedulesInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    delete:
      value._delete === undefined
        ? undefined
        : (value._delete as Array<any>).map(DeleteEvaScheduleToJSON),
  };
}
