/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CancelIvaScansInputTargets,
  CancelIvaScansInputTargetsFromJSON,
  CancelIvaScansInputTargetsFromJSONTyped,
  CancelIvaScansInputTargetsToJSON,
} from './';

/**
 *
 * @export
 * @interface CancelIvaScansInputScannerTargets
 */
export interface CancelIvaScansInputScannerTargets {
  /**
   *
   * @type {string}
   * @memberof CancelIvaScansInputScannerTargets
   */
  scannerId?: string;
  /**
   * List of target objects.
   * @type {Array<CancelIvaScansInputTargets>}
   * @memberof CancelIvaScansInputScannerTargets
   */
  targets?: Array<CancelIvaScansInputTargets>;
}

export function CancelIvaScansInputScannerTargetsFromJSON(
  json: any
): CancelIvaScansInputScannerTargets {
  return CancelIvaScansInputScannerTargetsFromJSONTyped(json, false);
}

export function CancelIvaScansInputScannerTargetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelIvaScansInputScannerTargets {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scannerId: !exists(json, 'scannerId') ? undefined : json['scannerId'],
    targets: !exists(json, 'targets')
      ? undefined
      : (json['targets'] as Array<any>).map(CancelIvaScansInputTargetsFromJSON),
  };
}

export function CancelIvaScansInputScannerTargetsToJSON(
  value?: CancelIvaScansInputScannerTargets | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scannerId: value.scannerId,
    targets:
      value.targets === undefined
        ? undefined
        : (value.targets as Array<any>).map(CancelIvaScansInputTargetsToJSON),
  };
}
