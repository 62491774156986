/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContactEmail
 */
export interface ContactEmail {
  /**
   * Unique identifier for the email address, this is for internal use and will not be returned
   * @type {number}
   * @memberof ContactEmail
   */
  id?: number;
  /**
   * Email address
   * @type {string}
   * @memberof ContactEmail
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactEmail
   */
  type?: ContactEmailTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ContactEmailTypeEnum {
  Work = 'work',
  Personal = 'personal',
  Imported = 'imported',
  Other = 'other',
}

export function ContactEmailFromJSON(json: any): ContactEmail {
  return ContactEmailFromJSONTyped(json, false);
}

export function ContactEmailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactEmail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    type: !exists(json, 'type') ? undefined : json['type'],
  };
}

export function ContactEmailToJSON(value?: ContactEmail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    type: value.type,
  };
}
