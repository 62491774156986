import { ColumnProps, Table } from '../../../Reusables/table/Table';
import React from 'react';
import styled from 'styled-components';
import { RowTileV2 } from '../../../Reusables/RowTileV2';
import { Scanner, ScannerSchedule } from '../../../types/attackSurface';
import { Accordion } from '../Accordion';
import AscPanelSubtitle from '../AscPanelSubtitle';
import { CountTile, Tile } from '../AssetCount/CountTile';
import { useAttackSurfaceContext } from '../Context/AscContext';
import {
  getUsableIpAddresses,
  StyledContainer,
  getPercentageString,
  getIvaScannerScheduleTargets,
  getIvaScanners,
  getIvaScannerSchedules,
} from '../utils';

const ContainerDiv = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: 10px;
`;

type ScheduleProps = {
  schedules: ScannerSchedule[];
};

interface ScheduleTableColumn {
  target: string;
  capacity: string;
  targetCount: number;
}

const scheduleColumns: ColumnProps<ScheduleTableColumn>[] = [
  {
    key: 'target',
    dataIndex: 'target',
    title: 'Target',
    justify: 'flex-start',
  },
  {
    key: 'capacity',
    dataIndex: 'capacity',
    title: 'Network Capacity',
    justify: 'flex-start',
  },
  {
    key: 'targetCount',
    dataIndex: 'targetCount',
    title: 'Device Count',
    justify: 'flex-start',
  },
];

const ScheduleNestedRenderer: React.FC<ScheduleProps> = ({ schedules }) => {
  const ScheduleItems = schedules.map((schedule, index) => {
    const tiles: Tile[] = [
      {
        title: 'Devices',
        display: schedule?.assetCount?.toString() || '',
      },
      {
        title: 'Schedule Percent Capacity',
        display: getPercentageString(schedule?.assetCount || 0, 2048),
      },
      {
        title: 'Schedule Empty Targets',
        display: schedule.emptyTargetCount
          ? schedule.emptyTargetCount.toString()
          : '0',
      },
    ];
    const tableData =
      schedule?.ivaTargets?.map((target) => {
        return {
          target: target.name,
          capacity: getUsableIpAddresses(target.name).toString(),
          targetCount: target.assetCount,
        };
      }) || [];
    return {
      title: `Schedule ${index + 1} (${schedule.assetCount})`,
      node: (
        <>
          <div style={{ padding: '10px' }}>
            <CountTile tiles={tiles} />
          </div>
          <StyledContainer data-testid='Asc-table'>
            <Table
              data={tableData}
              columns={scheduleColumns}
              dataUniqueKey='id'
              emptyComponent={<div>No Data Available</div>}
            />
          </StyledContainer>
        </>
      ),
    };
  });

  return ScheduleItems.length === 0 ? (
    <>No Schedules</>
  ) : (
    <Accordion childrenNodes={ScheduleItems} />
  );
};

type ScannerProps = {
  scanner: Scanner;
  showTooltip?: boolean;
};

const IvaScanner: React.FC<ScannerProps> = ({ scanner }) => {
  const tiles: Tile[] = [
    {
      title: 'Devices',
      display: scanner.assetCount.toString(),
    },
    {
      title: 'Total Percent Capacity',
      display: getPercentageString(scanner.assetCount, 2048),
      showTooltip: true,
      description:
        'Total Percent Capacity is based on a maximum capacity of 2048 hosts per scanner. If there are 307 devices, the percentage is (307/2048)*100% = 15%.',
    },
    {
      title: 'Deployment Empty Targets',
      display: scanner.emptyScheduleCount
        ? scanner.emptyScheduleCount.toString()
        : '0',
    },
  ];

  return (
    <>
      <AscPanelSubtitle
        title={
          scanner.label || scanner.deploymentID || scanner.id || '<No Name>'
        }
      />
      <div data-testid='iva-scanner' style={{ paddingBottom: '10px' }}>
        <CountTile tiles={tiles} />
      </div>
      <ScheduleNestedRenderer schedules={scanner.ivaScannerSchedules} />
    </>
  );
};

export type Props = {
  description: string;
  title?: string;
};

export const AscIvaContainer = ({ description, title }: Props) => {
  const { iva } = useAttackSurfaceContext();
  let scanners: Scanner[] = getIvaScanners(iva);
  scanners.forEach((scanner: Scanner) => {
    scanner.emptyScheduleCount = 0;
    scanner.ivaScannerSchedules = getIvaScannerSchedules(scanner.id, iva);
    scanner.ivaScannerSchedules.forEach((schedule: ScannerSchedule) => {
      schedule.emptyTargetCount = 0;
      schedule.ivaTargets = getIvaScannerScheduleTargets(
        scanner.id,
        schedule.id,
        iva
      );
      schedule.emptyTargetCount = schedule.ivaTargets?.filter(
        (target) => target.assetCount === 0
      ).length;
      if (schedule.emptyTargetCount && schedule.emptyTargetCount > 0) {
        scanner.emptyScheduleCount =
          (scanner.emptyScheduleCount || 0) + schedule.emptyTargetCount;
      }
    });
  });

  scanners = scanners.sort((a: Scanner, b: Scanner) =>
    a.ivaScannerSchedules?.length < b.ivaScannerSchedules?.length ? 1 : -1
  );

  return (
    <ContainerDiv>
      <RowTileV2
        data-testid='table-row-header'
        id='AscIvaCoverage'
        title={title || 'IVA Coverage'}
        description={description}
        buttons={<div></div>}
      >
        {scanners.map((scanner, index) => (
          <IvaScanner key={`iva-scanner-${index}`} scanner={scanner} />
        ))}
      </RowTileV2>
    </ContainerDiv>
  );
};
