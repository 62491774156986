/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the status of a given cloud sensor secret
 * @export
 * @enum {string}
 */
export enum CloudSensorDetailStatus {
    Active = 'active',
    Edit = 'edit',
    Error = 'error',
    Deactivating = 'deactivating'
}

export function CloudSensorDetailStatusFromJSON(json: any): CloudSensorDetailStatus {
    return CloudSensorDetailStatusFromJSONTyped(json, false);
}

export function CloudSensorDetailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudSensorDetailStatus {
    return json as CloudSensorDetailStatus;
}

export function CloudSensorDetailStatusToJSON(value?: CloudSensorDetailStatus | null): any {
    return value as any;
}

