/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  Agent,
  AgentFromJSON,
  AgentToJSON,
  AgentPage,
  AgentPageFromJSON,
  AgentPageToJSON,
  AgentScheduleInput,
  AgentScheduleInputFromJSON,
  AgentScheduleInputToJSON,
  AgentSummary,
  AgentSummaryFromJSON,
  AgentSummaryToJSON,
  BaseError,
  BaseErrorFromJSON,
  BaseErrorToJSON,
  CancelAgentScansInput,
  CancelAgentScansInputFromJSON,
  CancelAgentScansInputToJSON,
  CancelEvaScansInput,
  CancelEvaScansInputFromJSON,
  CancelEvaScansInputToJSON,
  CancelIvaScansInput,
  CancelIvaScansInputFromJSON,
  CancelIvaScansInputToJSON,
  CloudSensor,
  CloudSensorFromJSON,
  CloudSensorToJSON,
  CloudSensorPage,
  CloudSensorPageFromJSON,
  CloudSensorPageToJSON,
  CloudSensorSummary,
  CloudSensorSummaryFromJSON,
  CloudSensorSummaryToJSON,
  ContactInfo,
  ContactInfoFromJSON,
  ContactInfoToJSON,
  CoverageScore,
  CoverageScoreFromJSON,
  CoverageScoreToJSON,
  CoverageScoreDetails,
  CoverageScoreDetailsFromJSON,
  CoverageScoreDetailsToJSON,
  CreateTicketCommentInput,
  CreateTicketCommentInputFromJSON,
  CreateTicketCommentInputToJSON,
  CreateTicketInput,
  CreateTicketInputFromJSON,
  CreateTicketInputToJSON,
  CreateTicketOutput,
  CreateTicketOutputFromJSON,
  CreateTicketOutputToJSON,
  Credential,
  CredentialFromJSON,
  CredentialToJSON,
  CsvContents,
  CsvContentsFromJSON,
  CsvContentsToJSON,
  DeleteAgentSchedulesInput,
  DeleteAgentSchedulesInputFromJSON,
  DeleteAgentSchedulesInputToJSON,
  DeleteEvaSchedulesInput,
  DeleteEvaSchedulesInputFromJSON,
  DeleteEvaSchedulesInputToJSON,
  DeleteIvaSchedulesInput,
  DeleteIvaSchedulesInputFromJSON,
  DeleteIvaSchedulesInputToJSON,
  EscalationConfigs,
  EscalationConfigsFromJSON,
  EscalationConfigsToJSON,
  EvaScheduleCreate,
  EvaScheduleCreateFromJSON,
  EvaScheduleCreateToJSON,
  EvaScheduleEdit,
  EvaScheduleEditFromJSON,
  EvaScheduleEditToJSON,
  FederatedAuthInput,
  FederatedAuthInputFromJSON,
  FederatedAuthInputToJSON,
  Health,
  HealthFromJSON,
  HealthToJSON,
  IvaScheduleInput,
  IvaScheduleInputFromJSON,
  IvaScheduleInputToJSON,
  LicenseUsage,
  LicenseUsageFromJSON,
  LicenseUsageToJSON,
  LogSearchRequest,
  LogSearchRequestFromJSON,
  LogSearchRequestToJSON,
  LogSearchRequestInput,
  LogSearchRequestInputFromJSON,
  LogSearchRequestInputToJSON,
  ManageAgentScheduleStateInput,
  ManageAgentScheduleStateInputFromJSON,
  ManageAgentScheduleStateInputToJSON,
  ManageIvaScheduleStateInputs,
  ManageIvaScheduleStateInputsFromJSON,
  ManageIvaScheduleStateInputsToJSON,
  ObservationPipelineDetails,
  ObservationPipelineDetailsFromJSON,
  ObservationPipelineDetailsToJSON,
  Organization,
  OrganizationFromJSON,
  OrganizationToJSON,
  OrganizationContact,
  OrganizationContactFromJSON,
  OrganizationContactToJSON,
  OrganizationContacts,
  OrganizationContactsFromJSON,
  OrganizationContactsToJSON,
  ReportURL,
  ReportURLFromJSON,
  ReportURLToJSON,
  Reports,
  ReportsFromJSON,
  ReportsToJSON,
  RiskScoreDetails,
  RiskScoreDetailsFromJSON,
  RiskScoreDetailsToJSON,
  ScanAgentNowInput,
  ScanAgentNowInputFromJSON,
  ScanAgentNowInputToJSON,
  ScanEvaNowInput,
  ScanEvaNowInputFromJSON,
  ScanEvaNowInputToJSON,
  ScanIvaNowInput,
  ScanIvaNowInputFromJSON,
  ScanIvaNowInputToJSON,
  ScannerDetails,
  ScannerDetailsFromJSON,
  ScannerDetailsToJSON,
  ScannerPage,
  ScannerPageFromJSON,
  ScannerPageToJSON,
  ScannerSummary,
  ScannerSummaryFromJSON,
  ScannerSummaryToJSON,
  SchedulePage,
  SchedulePageFromJSON,
  SchedulePageToJSON,
  ScheduleRow,
  ScheduleRowFromJSON,
  ScheduleRowToJSON,
  ScheduledReports,
  ScheduledReportsFromJSON,
  ScheduledReportsToJSON,
  Sensor,
  SensorFromJSON,
  SensorToJSON,
  SensorPage,
  SensorPageFromJSON,
  SensorPageToJSON,
  SensorSummary,
  SensorSummaryFromJSON,
  SensorSummaryToJSON,
  ServiceEndpoints,
  ServiceEndpointsFromJSON,
  ServiceEndpointsToJSON,
  SubscriptionDetail,
  SubscriptionDetailFromJSON,
  SubscriptionDetailToJSON,
  TicketList,
  TicketListFromJSON,
  TicketListToJSON,
  TicketWithComments,
  TicketWithCommentsFromJSON,
  TicketWithCommentsToJSON,
  User,
  UserFromJSON,
  UserToJSON,
  ZendeskUploadResponse,
  ZendeskUploadResponseFromJSON,
  ZendeskUploadResponseToJSON,
} from '../models';

export interface AddUpdateFederatedAuthRequest {
  organizationId: string;
  federatedAuthInput: FederatedAuthInput;
}

export interface BulkDisableAgentScheduleRequest {
  organizationId: string;
  manageAgentScheduleStateInput: ManageAgentScheduleStateInput;
}

export interface BulkDisableIvaScheduleRequest {
  organizationId: string;
  manageIvaScheduleStateInputs: ManageIvaScheduleStateInputs;
}

export interface CancelAgentScansRequest {
  organizationId: string;
  cancelAgentScansInput: CancelAgentScansInput;
}

export interface CancelEvaScansRequest {
  organizationId: string;
  cancelEvaScansInput: CancelEvaScansInput;
}

export interface CancelIvaScansRequest {
  organizationId: string;
  cancelIvaScansInput: CancelIvaScansInput;
}

export interface CreateAgentScheduleRequest {
  organizationId: string;
  agentScheduleInput: AgentScheduleInput;
}

export interface CreateEvaScheduleRequest {
  organizationId: string;
  evaScheduleCreate: EvaScheduleCreate;
}

export interface CreateIvaScheduleRequest {
  organizationId: string;
  scannerId: string;
  ivaScheduleInput: IvaScheduleInput;
}

export interface CreateLogSearchRequestRequest {
  organizationId: string;
  logSearchRequestInput: LogSearchRequestInput;
}

export interface CreateTicketRequest {
  organizationId: string;
  createTicketInput: CreateTicketInput;
}

export interface CreateTicketCommentRequest {
  organizationId: string;
  ticketId: string;
  createTicketCommentInput: CreateTicketCommentInput;
}

export interface DeleteAgentSchedulesRequest {
  organizationId: string;
  deleteAgentSchedulesInput: DeleteAgentSchedulesInput;
}

export interface DeleteEvaSchedulesRequest {
  organizationId: string;
  deleteEvaSchedulesInput: DeleteEvaSchedulesInput;
}

export interface DeleteIvaSchedulesRequest {
  organizationId: string;
  deleteIvaSchedulesInput: DeleteIvaSchedulesInput;
}

export interface DownloadZendeskAttachmentRequest {
  path: string;
}

export interface EditAgentScheduleRequest {
  organizationId: string;
  scoutGroupId: string;
  agentScheduleInput: AgentScheduleInput;
}

export interface EditEvaScheduleRequest {
  organizationId: string;
  evaScheduleEdit: EvaScheduleEdit;
}

export interface EditIvaScheduleRequest {
  organizationId: string;
  scannerId: string;
  scheduleId: string;
  ivaScheduleInput: IvaScheduleInput;
}

export interface GetAgentRequest {
  organizationId: string;
  agentId: string;
}

export interface GetAgentPageRequest {
  organizationId: string;
  osType?: GetAgentPageOsTypeEnum;
  status?: GetAgentPageStatusEnum;
  offlineSince?: number;
  sort?: string;
  limit?: number;
  offset?: number;
}

export interface GetAgentScheduleRequest {
  organizationId: string;
  scoutGroupId: string;
}

export interface GetAgentSummaryRequest {
  organizationId: string;
}

export interface GetCloudSensorRequest {
  organizationId: string;
  cloudSensorId: string;
}

export interface GetCloudSensorPageRequest {
  organizationId: string;
  limit?: number;
  offset?: number;
  sort?: string;
  vendor?: string;
  status?: GetCloudSensorPageStatusEnum;
}

export interface GetCloudSensorSummaryRequest {
  organizationId: string;
}

export interface GetCoverageScoreRequest {
  organizationId: string;
}

export interface GetCoverageScoresPageRequest {
  organizationId: string;
  msp?: string;
  limit?: number;
  offset?: number;
}

export interface GetCsvTicketAttachmentRequest {
  organizationId: string;
  ticketId: string;
  attachmentId: string;
}

export interface GetEscalationConfigsRequest {
  organizationId: string;
}

export interface GetIvaScanScheduleRequest {
  organizationId: string;
  scannerId: string;
  scheduleId: string;
}

export interface GetLicenseUsageRequest {
  organizationId: string;
}

export interface GetLogSearchRequestsRequest {
  organizationId: string;
}

export interface GetObservationPipelineDetailsRequest {
  organizationId: string;
  start: string;
  end: string;
}

export interface GetOrgContactDetailsRequest {
  organizationId: string;
  contactId: string;
}

export interface GetOrgContactsRequest {
  organizationId: string;
  limit?: number;
  offset?: number;
  sort?: string;
}

export interface GetOrganizationRequest {
  organizationId: string;
}

export interface GetReportURLRequest {
  organizationId: string;
  reportId: string;
  contentDisposition?: string;
}

export interface GetReportsRequest {
  organizationId: string;
  limit?: number;
  offset?: number;
  title?: string;
  sentDate?: string;
  sort?: string;
}

export interface GetRiskScoreDetailsRequest {
  organizationId: string;
  window?: string;
}

export interface GetScanSchedulesRequest {
  organizationId: string;
}

export interface GetScannerDetailsRequest {
  organizationId: string;
  scannerId: string;
}

export interface GetScannerPageRequest {
  organizationId: string;
  sort?: string;
  limit?: number;
  offset?: number;
  status?: GetScannerPageStatusEnum;
  type?: GetScannerPageTypeEnum;
  version?: string;
}

export interface GetScannerSummaryRequest {
  organizationId: string;
}

export interface GetScheduledReportsRequest {
  organizationId: string;
  limit?: number;
  offset?: number;
  title?: string;
  sort?: string;
}

export interface GetSecretsSchemaRequest {
  organizationId: string;
}

export interface GetSensorRequest {
  organizationId: string;
  sensorId: string;
}

export interface GetSensorPageRequest {
  organizationId: string;
  limit?: number;
  offset?: number;
  sort?: string;
  status?: GetSensorPageStatusEnum;
  product?: GetSensorPageProductEnum;
}

export interface GetSensorSummaryRequest {
  organizationId: string;
}

export interface GetServiceEndpointsRequest {
  window?: string;
}

export interface GetSubscriptionsRequest {
  organizationId: string;
}

export interface GetTicketRequest {
  organizationId: string;
  ticketId: string;
}

export interface GetTicketV2Request {
  organizationId: string;
  ticketId: string;
}

export interface ListTicketsRequest {
  organizationId: string;
  includeInactive?: boolean;
  after?: Date;
  before?: Date;
  page?: number;
}

export interface ListTicketsV2Request {
  organizationId: string;
  includeInactive?: boolean;
  after?: Date;
  before?: Date;
  page?: number;
}

export interface PostQueryBrokerRequest {
  requestBody: { [key: string]: object };
}

export interface ScanAgentNowRequest {
  organizationId: string;
  scanAgentNowInput: ScanAgentNowInput;
}

export interface ScanEvaNowRequest {
  organizationId: string;
  scanEvaNowInput: ScanEvaNowInput;
}

export interface ScanIvaNowRequest {
  organizationId: string;
  scanIvaNowInput: ScanIvaNowInput;
}

export interface UploadZendeskAttachmentRequest {
  filename: string;
  body?: Blob;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Adds or updates the federated auth configuration details for the Organization
   */
  async addUpdateFederatedAuthRaw(
    requestParameters: AddUpdateFederatedAuthRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling addUpdateFederatedAuth.'
      );
    }

    if (
      requestParameters.federatedAuthInput === null ||
      requestParameters.federatedAuthInput === undefined
    ) {
      throw new runtime.RequiredError(
        'federatedAuthInput',
        'Required parameter requestParameters.federatedAuthInput was null or undefined when calling addUpdateFederatedAuth.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/federated-auth`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: FederatedAuthInputToJSON(requestParameters.federatedAuthInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Adds or updates the federated auth configuration details for the Organization
   */
  async addUpdateFederatedAuth(
    requestParameters: AddUpdateFederatedAuthRequest
  ): Promise<void> {
    await this.addUpdateFederatedAuthRaw(requestParameters);
  }

  /**
   * Enable/Disable multiple agent Schedules
   */
  async bulkDisableAgentScheduleRaw(
    requestParameters: BulkDisableAgentScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling bulkDisableAgentSchedule.'
      );
    }

    if (
      requestParameters.manageAgentScheduleStateInput === null ||
      requestParameters.manageAgentScheduleStateInput === undefined
    ) {
      throw new runtime.RequiredError(
        'manageAgentScheduleStateInput',
        'Required parameter requestParameters.manageAgentScheduleStateInput was null or undefined when calling bulkDisableAgentSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/agent/scan-schedules`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ManageAgentScheduleStateInputToJSON(
        requestParameters.manageAgentScheduleStateInput
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Enable/Disable multiple agent Schedules
   */
  async bulkDisableAgentSchedule(
    requestParameters: BulkDisableAgentScheduleRequest
  ): Promise<void> {
    await this.bulkDisableAgentScheduleRaw(requestParameters);
  }

  /**
   * Disable multiple IVA Schedule
   */
  async bulkDisableIvaScheduleRaw(
    requestParameters: BulkDisableIvaScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling bulkDisableIvaSchedule.'
      );
    }

    if (
      requestParameters.manageIvaScheduleStateInputs === null ||
      requestParameters.manageIvaScheduleStateInputs === undefined
    ) {
      throw new runtime.RequiredError(
        'manageIvaScheduleStateInputs',
        'Required parameter requestParameters.manageIvaScheduleStateInputs was null or undefined when calling bulkDisableIvaSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/scan-schedules/disable`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ManageIvaScheduleStateInputsToJSON(
        requestParameters.manageIvaScheduleStateInputs
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Disable multiple IVA Schedule
   */
  async bulkDisableIvaSchedule(
    requestParameters: BulkDisableIvaScheduleRequest
  ): Promise<void> {
    await this.bulkDisableIvaScheduleRaw(requestParameters);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelAgentScansRaw(
    requestParameters: CancelAgentScansRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling cancelAgentScans.'
      );
    }

    if (
      requestParameters.cancelAgentScansInput === null ||
      requestParameters.cancelAgentScansInput === undefined
    ) {
      throw new runtime.RequiredError(
        'cancelAgentScansInput',
        'Required parameter requestParameters.cancelAgentScansInput was null or undefined when calling cancelAgentScans.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/agent/cancel-scans`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CancelAgentScansInputToJSON(
        requestParameters.cancelAgentScansInput
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelAgentScans(
    requestParameters: CancelAgentScansRequest
  ): Promise<void> {
    await this.cancelAgentScansRaw(requestParameters);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelEvaScansRaw(
    requestParameters: CancelEvaScansRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling cancelEvaScans.'
      );
    }

    if (
      requestParameters.cancelEvaScansInput === null ||
      requestParameters.cancelEvaScansInput === undefined
    ) {
      throw new runtime.RequiredError(
        'cancelEvaScansInput',
        'Required parameter requestParameters.cancelEvaScansInput was null or undefined when calling cancelEvaScans.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/eva/cancel-scans`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CancelEvaScansInputToJSON(requestParameters.cancelEvaScansInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelEvaScans(
    requestParameters: CancelEvaScansRequest
  ): Promise<void> {
    await this.cancelEvaScansRaw(requestParameters);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelIvaScansRaw(
    requestParameters: CancelIvaScansRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling cancelIvaScans.'
      );
    }

    if (
      requestParameters.cancelIvaScansInput === null ||
      requestParameters.cancelIvaScansInput === undefined
    ) {
      throw new runtime.RequiredError(
        'cancelIvaScansInput',
        'Required parameter requestParameters.cancelIvaScansInput was null or undefined when calling cancelIvaScans.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/cancel-scans`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CancelIvaScansInputToJSON(requestParameters.cancelIvaScansInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Handles the request to cancel multiple scans.
   * Handles the request to cancel multiple scans
   */
  async cancelIvaScans(
    requestParameters: CancelIvaScansRequest
  ): Promise<void> {
    await this.cancelIvaScansRaw(requestParameters);
  }

  /**
   * Create a scan schedule for Agent
   */
  async createAgentScheduleRaw(
    requestParameters: CreateAgentScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createAgentSchedule.'
      );
    }

    if (
      requestParameters.agentScheduleInput === null ||
      requestParameters.agentScheduleInput === undefined
    ) {
      throw new runtime.RequiredError(
        'agentScheduleInput',
        'Required parameter requestParameters.agentScheduleInput was null or undefined when calling createAgentSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/agent`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgentScheduleInputToJSON(requestParameters.agentScheduleInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create a scan schedule for Agent
   */
  async createAgentSchedule(
    requestParameters: CreateAgentScheduleRequest
  ): Promise<void> {
    await this.createAgentScheduleRaw(requestParameters);
  }

  /**
   * Create new EVA schedule
   */
  async createEvaScheduleRaw(
    requestParameters: CreateEvaScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createEvaSchedule.'
      );
    }

    if (
      requestParameters.evaScheduleCreate === null ||
      requestParameters.evaScheduleCreate === undefined
    ) {
      throw new runtime.RequiredError(
        'evaScheduleCreate',
        'Required parameter requestParameters.evaScheduleCreate was null or undefined when calling createEvaSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/eva`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EvaScheduleCreateToJSON(requestParameters.evaScheduleCreate),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create new EVA schedule
   */
  async createEvaSchedule(
    requestParameters: CreateEvaScheduleRequest
  ): Promise<void> {
    await this.createEvaScheduleRaw(requestParameters);
  }

  /**
   * Create a scan schedule for Iva
   */
  async createIvaScheduleRaw(
    requestParameters: CreateIvaScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createIvaSchedule.'
      );
    }

    if (
      requestParameters.scannerId === null ||
      requestParameters.scannerId === undefined
    ) {
      throw new runtime.RequiredError(
        'scannerId',
        'Required parameter requestParameters.scannerId was null or undefined when calling createIvaSchedule.'
      );
    }

    if (
      requestParameters.ivaScheduleInput === null ||
      requestParameters.ivaScheduleInput === undefined
    ) {
      throw new runtime.RequiredError(
        'ivaScheduleInput',
        'Required parameter requestParameters.ivaScheduleInput was null or undefined when calling createIvaSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/{scannerId}/scan-schedules`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scannerId'}}`,
          encodeURIComponent(String(requestParameters.scannerId))
        ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IvaScheduleInputToJSON(requestParameters.ivaScheduleInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create a scan schedule for Iva
   */
  async createIvaSchedule(
    requestParameters: CreateIvaScheduleRequest
  ): Promise<void> {
    await this.createIvaScheduleRaw(requestParameters);
  }

  /**
   * create a new log search request
   */
  async createLogSearchRequestRaw(
    requestParameters: CreateLogSearchRequestRequest
  ): Promise<runtime.ApiResponse<LogSearchRequest>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createLogSearchRequest.'
      );
    }

    if (
      requestParameters.logSearchRequestInput === null ||
      requestParameters.logSearchRequestInput === undefined
    ) {
      throw new runtime.RequiredError(
        'logSearchRequestInput',
        'Required parameter requestParameters.logSearchRequestInput was null or undefined when calling createLogSearchRequest.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/logsearch`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LogSearchRequestInputToJSON(
        requestParameters.logSearchRequestInput
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogSearchRequestFromJSON(jsonValue)
    );
  }

  /**
   * create a new log search request
   */
  async createLogSearchRequest(
    requestParameters: CreateLogSearchRequestRequest
  ): Promise<LogSearchRequest> {
    const response = await this.createLogSearchRequestRaw(requestParameters);
    return await response.value();
  }

  /**
   * create a new ticket
   */
  async createTicketRaw(
    requestParameters: CreateTicketRequest
  ): Promise<runtime.ApiResponse<CreateTicketOutput>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createTicket.'
      );
    }

    if (
      requestParameters.createTicketInput === null ||
      requestParameters.createTicketInput === undefined
    ) {
      throw new runtime.RequiredError(
        'createTicketInput',
        'Required parameter requestParameters.createTicketInput was null or undefined when calling createTicket.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/tickets`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateTicketInputToJSON(requestParameters.createTicketInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateTicketOutputFromJSON(jsonValue)
    );
  }

  /**
   * create a new ticket
   */
  async createTicket(
    requestParameters: CreateTicketRequest
  ): Promise<CreateTicketOutput> {
    const response = await this.createTicketRaw(requestParameters);
    return await response.value();
  }

  /**
   * this endpoint is a proxy to Porthole that adds a comment to a ticket
   */
  async createTicketCommentRaw(
    requestParameters: CreateTicketCommentRequest
  ): Promise<runtime.ApiResponse<TicketWithComments>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createTicketComment.'
      );
    }

    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling createTicketComment.'
      );
    }

    if (
      requestParameters.createTicketCommentInput === null ||
      requestParameters.createTicketCommentInput === undefined
    ) {
      throw new runtime.RequiredError(
        'createTicketCommentInput',
        'Required parameter requestParameters.createTicketCommentInput was null or undefined when calling createTicketComment.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/tickets/{ticketId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId))
        ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateTicketCommentInputToJSON(
        requestParameters.createTicketCommentInput
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketWithCommentsFromJSON(jsonValue)
    );
  }

  /**
   * this endpoint is a proxy to Porthole that adds a comment to a ticket
   */
  async createTicketComment(
    requestParameters: CreateTicketCommentRequest
  ): Promise<TicketWithComments> {
    const response = await this.createTicketCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete scan schedules for Agent
   */
  async deleteAgentSchedulesRaw(
    requestParameters: DeleteAgentSchedulesRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAgentSchedules.'
      );
    }

    if (
      requestParameters.deleteAgentSchedulesInput === null ||
      requestParameters.deleteAgentSchedulesInput === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteAgentSchedulesInput',
        'Required parameter requestParameters.deleteAgentSchedulesInput was null or undefined when calling deleteAgentSchedules.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/agent`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: DeleteAgentSchedulesInputToJSON(
        requestParameters.deleteAgentSchedulesInput
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete scan schedules for Agent
   */
  async deleteAgentSchedules(
    requestParameters: DeleteAgentSchedulesRequest
  ): Promise<void> {
    await this.deleteAgentSchedulesRaw(requestParameters);
  }

  /**
   * Delete EVA schedules
   */
  async deleteEvaSchedulesRaw(
    requestParameters: DeleteEvaSchedulesRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEvaSchedules.'
      );
    }

    if (
      requestParameters.deleteEvaSchedulesInput === null ||
      requestParameters.deleteEvaSchedulesInput === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteEvaSchedulesInput',
        'Required parameter requestParameters.deleteEvaSchedulesInput was null or undefined when calling deleteEvaSchedules.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/eva`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: DeleteEvaSchedulesInputToJSON(
        requestParameters.deleteEvaSchedulesInput
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete EVA schedules
   */
  async deleteEvaSchedules(
    requestParameters: DeleteEvaSchedulesRequest
  ): Promise<void> {
    await this.deleteEvaSchedulesRaw(requestParameters);
  }

  /**
   * Delete scan schedules for iva
   */
  async deleteIvaSchedulesRaw(
    requestParameters: DeleteIvaSchedulesRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteIvaSchedules.'
      );
    }

    if (
      requestParameters.deleteIvaSchedulesInput === null ||
      requestParameters.deleteIvaSchedulesInput === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteIvaSchedulesInput',
        'Required parameter requestParameters.deleteIvaSchedulesInput was null or undefined when calling deleteIvaSchedules.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/iva`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: DeleteIvaSchedulesInputToJSON(
        requestParameters.deleteIvaSchedulesInput
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete scan schedules for iva
   */
  async deleteIvaSchedules(
    requestParameters: DeleteIvaSchedulesRequest
  ): Promise<void> {
    await this.deleteIvaSchedulesRaw(requestParameters);
  }

  /**
   * Initiates a call to the Zendesk proxy in order to retrieve a 302 response that will give the UI its next (final) hop in the download chain.
   */
  async downloadZendeskAttachmentRaw(
    requestParameters: DownloadZendeskAttachmentRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.path === null ||
      requestParameters.path === undefined
    ) {
      throw new runtime.RequiredError(
        'path',
        'Required parameter requestParameters.path was null or undefined when calling downloadZendeskAttachment.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.path !== undefined) {
      queryParameters['path'] = requestParameters.path;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/downloads`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Initiates a call to the Zendesk proxy in order to retrieve a 302 response that will give the UI its next (final) hop in the download chain.
   */
  async downloadZendeskAttachment(
    requestParameters: DownloadZendeskAttachmentRequest
  ): Promise<void> {
    await this.downloadZendeskAttachmentRaw(requestParameters);
  }

  /**
   * Edit a scan schedule for Agent
   */
  async editAgentScheduleRaw(
    requestParameters: EditAgentScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling editAgentSchedule.'
      );
    }

    if (
      requestParameters.scoutGroupId === null ||
      requestParameters.scoutGroupId === undefined
    ) {
      throw new runtime.RequiredError(
        'scoutGroupId',
        'Required parameter requestParameters.scoutGroupId was null or undefined when calling editAgentSchedule.'
      );
    }

    if (
      requestParameters.agentScheduleInput === null ||
      requestParameters.agentScheduleInput === undefined
    ) {
      throw new runtime.RequiredError(
        'agentScheduleInput',
        'Required parameter requestParameters.agentScheduleInput was null or undefined when calling editAgentSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/agent/{scoutGroupId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scoutGroupId'}}`,
          encodeURIComponent(String(requestParameters.scoutGroupId))
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AgentScheduleInputToJSON(requestParameters.agentScheduleInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Edit a scan schedule for Agent
   */
  async editAgentSchedule(
    requestParameters: EditAgentScheduleRequest
  ): Promise<void> {
    await this.editAgentScheduleRaw(requestParameters);
  }

  /**
   * Edit EVA schedule
   */
  async editEvaScheduleRaw(
    requestParameters: EditEvaScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling editEvaSchedule.'
      );
    }

    if (
      requestParameters.evaScheduleEdit === null ||
      requestParameters.evaScheduleEdit === undefined
    ) {
      throw new runtime.RequiredError(
        'evaScheduleEdit',
        'Required parameter requestParameters.evaScheduleEdit was null or undefined when calling editEvaSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/eva/{groupId}`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: EvaScheduleEditToJSON(requestParameters.evaScheduleEdit),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Edit EVA schedule
   */
  async editEvaSchedule(
    requestParameters: EditEvaScheduleRequest
  ): Promise<void> {
    await this.editEvaScheduleRaw(requestParameters);
  }

  /**
   * Edit a scan schedule for Iva
   */
  async editIvaScheduleRaw(
    requestParameters: EditIvaScheduleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling editIvaSchedule.'
      );
    }

    if (
      requestParameters.scannerId === null ||
      requestParameters.scannerId === undefined
    ) {
      throw new runtime.RequiredError(
        'scannerId',
        'Required parameter requestParameters.scannerId was null or undefined when calling editIvaSchedule.'
      );
    }

    if (
      requestParameters.scheduleId === null ||
      requestParameters.scheduleId === undefined
    ) {
      throw new runtime.RequiredError(
        'scheduleId',
        'Required parameter requestParameters.scheduleId was null or undefined when calling editIvaSchedule.'
      );
    }

    if (
      requestParameters.ivaScheduleInput === null ||
      requestParameters.ivaScheduleInput === undefined
    ) {
      throw new runtime.RequiredError(
        'ivaScheduleInput',
        'Required parameter requestParameters.ivaScheduleInput was null or undefined when calling editIvaSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/{scannerId}/scan-schedules/{scheduleId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scannerId'}}`,
          encodeURIComponent(String(requestParameters.scannerId))
        )
        .replace(
          `{${'scheduleId'}}`,
          encodeURIComponent(String(requestParameters.scheduleId))
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: IvaScheduleInputToJSON(requestParameters.ivaScheduleInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Edit a scan schedule for Iva
   */
  async editIvaSchedule(
    requestParameters: EditIvaScheduleRequest
  ): Promise<void> {
    await this.editIvaScheduleRaw(requestParameters);
  }

  /**
   * Get deployed agent
   */
  async getAgentRaw(
    requestParameters: GetAgentRequest
  ): Promise<runtime.ApiResponse<Agent>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getAgent.'
      );
    }

    if (
      requestParameters.agentId === null ||
      requestParameters.agentId === undefined
    ) {
      throw new runtime.RequiredError(
        'agentId',
        'Required parameter requestParameters.agentId was null or undefined when calling getAgent.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/agents/{agentId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'agentId'}}`,
          encodeURIComponent(String(requestParameters.agentId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgentFromJSON(jsonValue)
    );
  }

  /**
   * Get deployed agent
   */
  async getAgent(requestParameters: GetAgentRequest): Promise<Agent> {
    const response = await this.getAgentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get deployed agents
   */
  async getAgentPageRaw(
    requestParameters: GetAgentPageRequest
  ): Promise<runtime.ApiResponse<AgentPage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getAgentPage.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.osType !== undefined) {
      queryParameters['osType'] = requestParameters.osType;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.offlineSince !== undefined) {
      queryParameters['offlineSince'] = requestParameters.offlineSince;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/agents`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgentPageFromJSON(jsonValue)
    );
  }

  /**
   * Get deployed agents
   */
  async getAgentPage(
    requestParameters: GetAgentPageRequest
  ): Promise<AgentPage> {
    const response = await this.getAgentPageRaw(requestParameters);
    return await response.value();
  }

  /**
   * get a scan schedule for Agent
   */
  async getAgentScheduleRaw(
    requestParameters: GetAgentScheduleRequest
  ): Promise<runtime.ApiResponse<ScheduleRow>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getAgentSchedule.'
      );
    }

    if (
      requestParameters.scoutGroupId === null ||
      requestParameters.scoutGroupId === undefined
    ) {
      throw new runtime.RequiredError(
        'scoutGroupId',
        'Required parameter requestParameters.scoutGroupId was null or undefined when calling getAgentSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/agent/{scoutGroupId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scoutGroupId'}}`,
          encodeURIComponent(String(requestParameters.scoutGroupId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScheduleRowFromJSON(jsonValue)
    );
  }

  /**
   * get a scan schedule for Agent
   */
  async getAgentSchedule(
    requestParameters: GetAgentScheduleRequest
  ): Promise<ScheduleRow> {
    const response = await this.getAgentScheduleRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getAgentSummaryRaw(
    requestParameters: GetAgentSummaryRequest
  ): Promise<runtime.ApiResponse<AgentSummary>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getAgentSummary.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/agent-summary`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgentSummaryFromJSON(jsonValue)
    );
  }

  /**
   */
  async getAgentSummary(
    requestParameters: GetAgentSummaryRequest
  ): Promise<AgentSummary> {
    const response = await this.getAgentSummaryRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getCloudSensorRaw(
    requestParameters: GetCloudSensorRequest
  ): Promise<runtime.ApiResponse<CloudSensor>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCloudSensor.'
      );
    }

    if (
      requestParameters.cloudSensorId === null ||
      requestParameters.cloudSensorId === undefined
    ) {
      throw new runtime.RequiredError(
        'cloudSensorId',
        'Required parameter requestParameters.cloudSensorId was null or undefined when calling getCloudSensor.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/cloud-sensors/{cloudSensorId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'cloudSensorId'}}`,
          encodeURIComponent(String(requestParameters.cloudSensorId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CloudSensorFromJSON(jsonValue)
    );
  }

  /**
   */
  async getCloudSensor(
    requestParameters: GetCloudSensorRequest
  ): Promise<CloudSensor> {
    const response = await this.getCloudSensorRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getCloudSensorPageRaw(
    requestParameters: GetCloudSensorPageRequest
  ): Promise<runtime.ApiResponse<CloudSensorPage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCloudSensorPage.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.vendor !== undefined) {
      queryParameters['vendor'] = requestParameters.vendor;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/cloud-sensors`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CloudSensorPageFromJSON(jsonValue)
    );
  }

  /**
   */
  async getCloudSensorPage(
    requestParameters: GetCloudSensorPageRequest
  ): Promise<CloudSensorPage> {
    const response = await this.getCloudSensorPageRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getCloudSensorSummaryRaw(
    requestParameters: GetCloudSensorSummaryRequest
  ): Promise<runtime.ApiResponse<CloudSensorSummary>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCloudSensorSummary.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/cloud-sensor-summary`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CloudSensorSummaryFromJSON(jsonValue)
    );
  }

  /**
   */
  async getCloudSensorSummary(
    requestParameters: GetCloudSensorSummaryRequest
  ): Promise<CloudSensorSummary> {
    const response = await this.getCloudSensorSummaryRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the email and phone number for contacting Arctic Wolf; Exposes publicly available information and is therefore not authorized
   */
  async getContactInfoRaw(): Promise<runtime.ApiResponse<ContactInfo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/contact-information`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ContactInfoFromJSON(jsonValue)
    );
  }

  /**
   * Returns the email and phone number for contacting Arctic Wolf; Exposes publicly available information and is therefore not authorized
   */
  async getContactInfo(): Promise<ContactInfo> {
    const response = await this.getContactInfoRaw();
    return await response.value();
  }

  /**
   * the organization\'s coverage score
   */
  async getCoverageScoreRaw(
    requestParameters: GetCoverageScoreRequest
  ): Promise<runtime.ApiResponse<CoverageScore>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCoverageScore.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/coverage-score`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CoverageScoreFromJSON(jsonValue)
    );
  }

  /**
   * the organization\'s coverage score
   */
  async getCoverageScore(
    requestParameters: GetCoverageScoreRequest
  ): Promise<CoverageScore> {
    const response = await this.getCoverageScoreRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetch a page of coverage scores; each customer has zero or one coverage score record; a customer would not have a coverage score if they are brand new and the daily task which computes the scores hasn\'t run yet; coverage score is not refreshed for inactive customers
   */
  async getCoverageScoresPageRaw(
    requestParameters: GetCoverageScoresPageRequest
  ): Promise<runtime.ApiResponse<CoverageScoreDetails>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCoverageScoresPage.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.msp !== undefined) {
      queryParameters['msp'] = requestParameters.msp;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/coverage-score-details`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CoverageScoreDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Fetch a page of coverage scores; each customer has zero or one coverage score record; a customer would not have a coverage score if they are brand new and the daily task which computes the scores hasn\'t run yet; coverage score is not refreshed for inactive customers
   */
  async getCoverageScoresPage(
    requestParameters: GetCoverageScoresPageRequest
  ): Promise<CoverageScoreDetails> {
    const response = await this.getCoverageScoresPageRaw(requestParameters);
    return await response.value();
  }

  /**
   * returns the contents of the CSV file attached to a ticket
   */
  async getCsvTicketAttachmentRaw(
    requestParameters: GetCsvTicketAttachmentRequest
  ): Promise<runtime.ApiResponse<CsvContents>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getCsvTicketAttachment.'
      );
    }

    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling getCsvTicketAttachment.'
      );
    }

    if (
      requestParameters.attachmentId === null ||
      requestParameters.attachmentId === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentId',
        'Required parameter requestParameters.attachmentId was null or undefined when calling getCsvTicketAttachment.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/tickets/{ticketId}/attachments/{attachmentId}/csv`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId))
        )
        .replace(
          `{${'attachmentId'}}`,
          encodeURIComponent(String(requestParameters.attachmentId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CsvContentsFromJSON(jsonValue)
    );
  }

  /**
   * returns the contents of the CSV file attached to a ticket
   */
  async getCsvTicketAttachment(
    requestParameters: GetCsvTicketAttachmentRequest
  ): Promise<CsvContents> {
    const response = await this.getCsvTicketAttachmentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the escalation configuration details for the Organization
   */
  async getEscalationConfigsRaw(
    requestParameters: GetEscalationConfigsRequest
  ): Promise<runtime.ApiResponse<EscalationConfigs>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getEscalationConfigs.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/escalation-configs`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EscalationConfigsFromJSON(jsonValue)
    );
  }

  /**
   * Returns the escalation configuration details for the Organization
   */
  async getEscalationConfigs(
    requestParameters: GetEscalationConfigsRequest
  ): Promise<EscalationConfigs> {
    const response = await this.getEscalationConfigsRaw(requestParameters);
    return await response.value();
  }

  /**
   * API Health Summary
   */
  async getHealthInfoRaw(): Promise<runtime.ApiResponse<Health>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/health`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthFromJSON(jsonValue)
    );
  }

  /**
   * API Health Summary
   */
  async getHealthInfo(): Promise<Health> {
    const response = await this.getHealthInfoRaw();
    return await response.value();
  }

  /**
   */
  async getIvaScanScheduleRaw(
    requestParameters: GetIvaScanScheduleRequest
  ): Promise<runtime.ApiResponse<ScheduleRow>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getIvaScanSchedule.'
      );
    }

    if (
      requestParameters.scannerId === null ||
      requestParameters.scannerId === undefined
    ) {
      throw new runtime.RequiredError(
        'scannerId',
        'Required parameter requestParameters.scannerId was null or undefined when calling getIvaScanSchedule.'
      );
    }

    if (
      requestParameters.scheduleId === null ||
      requestParameters.scheduleId === undefined
    ) {
      throw new runtime.RequiredError(
        'scheduleId',
        'Required parameter requestParameters.scheduleId was null or undefined when calling getIvaScanSchedule.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/{scannerId}/scan-schedules/{scheduleId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scannerId'}}`,
          encodeURIComponent(String(requestParameters.scannerId))
        )
        .replace(
          `{${'scheduleId'}}`,
          encodeURIComponent(String(requestParameters.scheduleId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScheduleRowFromJSON(jsonValue)
    );
  }

  /**
   */
  async getIvaScanSchedule(
    requestParameters: GetIvaScanScheduleRequest
  ): Promise<ScheduleRow> {
    const response = await this.getIvaScanScheduleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the license utilization/quotas
   */
  async getLicenseUsageRaw(
    requestParameters: GetLicenseUsageRequest
  ): Promise<runtime.ApiResponse<LicenseUsage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getLicenseUsage.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/license-usage`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LicenseUsageFromJSON(jsonValue)
    );
  }

  /**
   * Returns the license utilization/quotas
   */
  async getLicenseUsage(
    requestParameters: GetLicenseUsageRequest
  ): Promise<LicenseUsage> {
    const response = await this.getLicenseUsageRaw(requestParameters);
    return await response.value();
  }

  /**
   * returns a list of existing log search requests
   */
  async getLogSearchRequestsRaw(
    requestParameters: GetLogSearchRequestsRequest
  ): Promise<runtime.ApiResponse<Array<LogSearchRequest>>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getLogSearchRequests.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/logsearch`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(LogSearchRequestFromJSON)
    );
  }

  /**
   * returns a list of existing log search requests
   */
  async getLogSearchRequests(
    requestParameters: GetLogSearchRequestsRequest
  ): Promise<Array<LogSearchRequest>> {
    const response = await this.getLogSearchRequestsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Observation Pipeline API. When To and From are excluded the default period is 7 days.
   */
  async getObservationPipelineDetailsRaw(
    requestParameters: GetObservationPipelineDetailsRequest
  ): Promise<runtime.ApiResponse<ObservationPipelineDetails>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getObservationPipelineDetails.'
      );
    }

    if (
      requestParameters.start === null ||
      requestParameters.start === undefined
    ) {
      throw new runtime.RequiredError(
        'start',
        'Required parameter requestParameters.start was null or undefined when calling getObservationPipelineDetails.'
      );
    }

    if (requestParameters.end === null || requestParameters.end === undefined) {
      throw new runtime.RequiredError(
        'end',
        'Required parameter requestParameters.end was null or undefined when calling getObservationPipelineDetails.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.end !== undefined) {
      queryParameters['end'] = requestParameters.end;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/pipeline-details`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ObservationPipelineDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Observation Pipeline API. When To and From are excluded the default period is 7 days.
   */
  async getObservationPipelineDetails(
    requestParameters: GetObservationPipelineDetailsRequest
  ): Promise<ObservationPipelineDetails> {
    const response = await this.getObservationPipelineDetailsRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Returns the details for the Contact
   */
  async getOrgContactDetailsRaw(
    requestParameters: GetOrgContactDetailsRequest
  ): Promise<runtime.ApiResponse<OrganizationContact>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrgContactDetails.'
      );
    }

    if (
      requestParameters.contactId === null ||
      requestParameters.contactId === undefined
    ) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling getOrgContactDetails.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/contacts/{contactId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'contactId'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationContactFromJSON(jsonValue)
    );
  }

  /**
   * Returns the details for the Contact
   */
  async getOrgContactDetails(
    requestParameters: GetOrgContactDetailsRequest
  ): Promise<OrganizationContact> {
    const response = await this.getOrgContactDetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the contact details for the Organization
   */
  async getOrgContactsRaw(
    requestParameters: GetOrgContactsRequest
  ): Promise<runtime.ApiResponse<OrganizationContacts>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrgContacts.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/contacts`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationContactsFromJSON(jsonValue)
    );
  }

  /**
   * Returns the contact details for the Organization
   */
  async getOrgContacts(
    requestParameters: GetOrgContactsRequest
  ): Promise<OrganizationContacts> {
    const response = await this.getOrgContactsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Organization API
   */
  async getOrganizationRaw(
    requestParameters: GetOrganizationRequest
  ): Promise<runtime.ApiResponse<Organization>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganization.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationFromJSON(jsonValue)
    );
  }

  /**
   * Organization API
   */
  async getOrganization(
    requestParameters: GetOrganizationRequest
  ): Promise<Organization> {
    const response = await this.getOrganizationRaw(requestParameters);
    return await response.value();
  }

  /**
   * this endpoint is a proxy to Query Broker with spec https://github.com/rtkwlf/querybroker/blob/9b38f2ef4fa9041b6990592bd50841461e0353db/api_spec.yaml. It is thus valid to use a QB API client that is configured to point at a Rendall deployment. Rendall will proxy the request directly to QueryBroker. Authorization happens in query broker for this endpoint.
   */
  async getQueryBrokerRaw(): Promise<
    runtime.ApiResponse<{ [key: string]: object }>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/query/{path:.+}`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * this endpoint is a proxy to Query Broker with spec https://github.com/rtkwlf/querybroker/blob/9b38f2ef4fa9041b6990592bd50841461e0353db/api_spec.yaml. It is thus valid to use a QB API client that is configured to point at a Rendall deployment. Rendall will proxy the request directly to QueryBroker. Authorization happens in query broker for this endpoint.
   */
  async getQueryBroker(): Promise<{ [key: string]: object }> {
    const response = await this.getQueryBrokerRaw();
    return await response.value();
  }

  /**
   * Download report
   */
  async getReportURLRaw(
    requestParameters: GetReportURLRequest
  ): Promise<runtime.ApiResponse<ReportURL>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getReportURL.'
      );
    }

    if (
      requestParameters.reportId === null ||
      requestParameters.reportId === undefined
    ) {
      throw new runtime.RequiredError(
        'reportId',
        'Required parameter requestParameters.reportId was null or undefined when calling getReportURL.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.contentDisposition !== undefined) {
      queryParameters['contentDisposition'] =
        requestParameters.contentDisposition;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/reports/{reportId}/download`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'reportId'}}`,
          encodeURIComponent(String(requestParameters.reportId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReportURLFromJSON(jsonValue)
    );
  }

  /**
   * Download report
   */
  async getReportURL(
    requestParameters: GetReportURLRequest
  ): Promise<ReportURL> {
    const response = await this.getReportURLRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get reports
   */
  async getReportsRaw(
    requestParameters: GetReportsRequest
  ): Promise<runtime.ApiResponse<Reports>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getReports.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.title !== undefined) {
      queryParameters['title'] = requestParameters.title;
    }

    if (requestParameters.sentDate !== undefined) {
      queryParameters['sentDate'] = requestParameters.sentDate;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/reports`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReportsFromJSON(jsonValue)
    );
  }

  /**
   * Get reports
   */
  async getReports(requestParameters: GetReportsRequest): Promise<Reports> {
    const response = await this.getReportsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Risk Score Details API
   */
  async getRiskScoreDetailsRaw(
    requestParameters: GetRiskScoreDetailsRequest
  ): Promise<runtime.ApiResponse<RiskScoreDetails>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getRiskScoreDetails.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.window !== undefined) {
      queryParameters['window'] = requestParameters.window;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/risk-score-details`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RiskScoreDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Risk Score Details API
   */
  async getRiskScoreDetails(
    requestParameters: GetRiskScoreDetailsRequest
  ): Promise<RiskScoreDetails> {
    const response = await this.getRiskScoreDetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getScanSchedulesRaw(
    requestParameters: GetScanSchedulesRequest
  ): Promise<runtime.ApiResponse<SchedulePage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getScanSchedules.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchedulePageFromJSON(jsonValue)
    );
  }

  /**
   */
  async getScanSchedules(
    requestParameters: GetScanSchedulesRequest
  ): Promise<SchedulePage> {
    const response = await this.getScanSchedulesRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getScannerDetailsRaw(
    requestParameters: GetScannerDetailsRequest
  ): Promise<runtime.ApiResponse<ScannerDetails>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getScannerDetails.'
      );
    }

    if (
      requestParameters.scannerId === null ||
      requestParameters.scannerId === undefined
    ) {
      throw new runtime.RequiredError(
        'scannerId',
        'Required parameter requestParameters.scannerId was null or undefined when calling getScannerDetails.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scanners/{scannerId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'scannerId'}}`,
          encodeURIComponent(String(requestParameters.scannerId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScannerDetailsFromJSON(jsonValue)
    );
  }

  /**
   */
  async getScannerDetails(
    requestParameters: GetScannerDetailsRequest
  ): Promise<ScannerDetails> {
    const response = await this.getScannerDetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getScannerPageRaw(
    requestParameters: GetScannerPageRequest
  ): Promise<runtime.ApiResponse<ScannerPage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getScannerPage.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    if (requestParameters.version !== undefined) {
      queryParameters['version'] = requestParameters.version;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scanners`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScannerPageFromJSON(jsonValue)
    );
  }

  /**
   */
  async getScannerPage(
    requestParameters: GetScannerPageRequest
  ): Promise<ScannerPage> {
    const response = await this.getScannerPageRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getScannerSummaryRaw(
    requestParameters: GetScannerSummaryRequest
  ): Promise<runtime.ApiResponse<ScannerSummary>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getScannerSummary.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scanner-summary`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScannerSummaryFromJSON(jsonValue)
    );
  }

  /**
   */
  async getScannerSummary(
    requestParameters: GetScannerSummaryRequest
  ): Promise<ScannerSummary> {
    const response = await this.getScannerSummaryRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get scheduled reports
   */
  async getScheduledReportsRaw(
    requestParameters: GetScheduledReportsRequest
  ): Promise<runtime.ApiResponse<ScheduledReports>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getScheduledReports.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.title !== undefined) {
      queryParameters['title'] = requestParameters.title;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scheduled-reports`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScheduledReportsFromJSON(jsonValue)
    );
  }

  /**
   * Get scheduled reports
   */
  async getScheduledReports(
    requestParameters: GetScheduledReportsRequest
  ): Promise<ScheduledReports> {
    const response = await this.getScheduledReportsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the Cloud Credential schema from the Console.
   */
  async getSecretsSchemaRaw(
    requestParameters: GetSecretsSchemaRequest
  ): Promise<runtime.ApiResponse<{ [key: string]: Credential }>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getSecretsSchema.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/secrets-schema`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, CredentialFromJSON)
    );
  }

  /**
   * Returns the Cloud Credential schema from the Console.
   */
  async getSecretsSchema(
    requestParameters: GetSecretsSchemaRequest
  ): Promise<{ [key: string]: Credential }> {
    const response = await this.getSecretsSchemaRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getSensorRaw(
    requestParameters: GetSensorRequest
  ): Promise<runtime.ApiResponse<Sensor>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getSensor.'
      );
    }

    if (
      requestParameters.sensorId === null ||
      requestParameters.sensorId === undefined
    ) {
      throw new runtime.RequiredError(
        'sensorId',
        'Required parameter requestParameters.sensorId was null or undefined when calling getSensor.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/sensors/{sensorId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'sensorId'}}`,
          encodeURIComponent(String(requestParameters.sensorId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SensorFromJSON(jsonValue)
    );
  }

  /**
   */
  async getSensor(requestParameters: GetSensorRequest): Promise<Sensor> {
    const response = await this.getSensorRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getSensorPageRaw(
    requestParameters: GetSensorPageRequest
  ): Promise<runtime.ApiResponse<SensorPage>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getSensorPage.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.product !== undefined) {
      queryParameters['product'] = requestParameters.product;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/sensors`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SensorPageFromJSON(jsonValue)
    );
  }

  /**
   */
  async getSensorPage(
    requestParameters: GetSensorPageRequest
  ): Promise<SensorPage> {
    const response = await this.getSensorPageRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getSensorSummaryRaw(
    requestParameters: GetSensorSummaryRequest
  ): Promise<runtime.ApiResponse<SensorSummary>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getSensorSummary.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/sensor-summary`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SensorSummaryFromJSON(jsonValue)
    );
  }

  /**
   */
  async getSensorSummary(
    requestParameters: GetSensorSummaryRequest
  ): Promise<SensorSummary> {
    const response = await this.getSensorSummaryRaw(requestParameters);
    return await response.value();
  }

  /**
   * Service Endpoints API; Exposes publicly available information and is therefore not authorized
   */
  async getServiceEndpointsRaw(
    requestParameters: GetServiceEndpointsRequest
  ): Promise<runtime.ApiResponse<ServiceEndpoints>> {
    const queryParameters: any = {};

    if (requestParameters.window !== undefined) {
      queryParameters['window'] = requestParameters.window;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/service-endpoints`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ServiceEndpointsFromJSON(jsonValue)
    );
  }

  /**
   * Service Endpoints API; Exposes publicly available information and is therefore not authorized
   */
  async getServiceEndpoints(
    requestParameters: GetServiceEndpointsRequest
  ): Promise<ServiceEndpoints> {
    const response = await this.getServiceEndpointsRaw(requestParameters);
    return await response.value();
  }

  /**
   * list subscriptions for an organization
   */
  async getSubscriptionsRaw(
    requestParameters: GetSubscriptionsRequest
  ): Promise<runtime.ApiResponse<SubscriptionDetail>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getSubscriptions.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/subscriptions`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubscriptionDetailFromJSON(jsonValue)
    );
  }

  /**
   * list subscriptions for an organization
   */
  async getSubscriptions(
    requestParameters: GetSubscriptionsRequest
  ): Promise<SubscriptionDetail> {
    const response = await this.getSubscriptionsRaw(requestParameters);
    return await response.value();
  }

  /**
   * this endpoint is a proxy to Porthole that retrieves the complete details for a ticket
   */
  async getTicketRaw(
    requestParameters: GetTicketRequest
  ): Promise<runtime.ApiResponse<TicketWithComments>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getTicket.'
      );
    }

    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling getTicket.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/tickets/{ticketId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketWithCommentsFromJSON(jsonValue)
    );
  }

  /**
   * this endpoint is a proxy to Porthole that retrieves the complete details for a ticket
   */
  async getTicket(
    requestParameters: GetTicketRequest
  ): Promise<TicketWithComments> {
    const response = await this.getTicketRaw(requestParameters);
    return await response.value();
  }

  /**
   * get the complete details for an individual ticket
   */
  async getTicketV2Raw(
    requestParameters: GetTicketV2Request
  ): Promise<runtime.ApiResponse<TicketWithComments>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getTicketV2.'
      );
    }

    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling getTicketV2.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v2/organizations/{organizationId}/tickets/{ticketId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId))
        )
        .replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketWithCommentsFromJSON(jsonValue)
    );
  }

  /**
   * get the complete details for an individual ticket
   */
  async getTicketV2(
    requestParameters: GetTicketV2Request
  ): Promise<TicketWithComments> {
    const response = await this.getTicketV2Raw(requestParameters);
    return await response.value();
  }

  /**
   * Returns information about current user. Doesn\'t return organization information and is therefore not authorized to an organization.
   */
  async getUserInfoRaw(): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/user`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * Returns information about current user. Doesn\'t return organization information and is therefore not authorized to an organization.
   */
  async getUserInfo(): Promise<User> {
    const response = await this.getUserInfoRaw();
    return await response.value();
  }

  /**
   * this endpoint is a proxy to Porthole that gets a list of all tickets that the user has access to
   */
  async listTicketsRaw(
    requestParameters: ListTicketsRequest
  ): Promise<runtime.ApiResponse<TicketList>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listTickets.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.includeInactive !== undefined) {
      queryParameters['include_inactive'] = requestParameters.includeInactive;
    }

    if (requestParameters.after !== undefined) {
      queryParameters['after'] = (requestParameters.after as any).toISOString();
    }

    if (requestParameters.before !== undefined) {
      queryParameters[
        'before'
      ] = (requestParameters.before as any).toISOString();
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/tickets`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketListFromJSON(jsonValue)
    );
  }

  /**
   * this endpoint is a proxy to Porthole that gets a list of all tickets that the user has access to
   */
  async listTickets(
    requestParameters: ListTicketsRequest
  ): Promise<TicketList> {
    const response = await this.listTicketsRaw(requestParameters);
    return await response.value();
  }

  /**
   * gets a list of all tickets that the user has access to
   */
  async listTicketsV2Raw(
    requestParameters: ListTicketsV2Request
  ): Promise<runtime.ApiResponse<TicketList>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listTicketsV2.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.includeInactive !== undefined) {
      queryParameters['include_inactive'] = requestParameters.includeInactive;
    }

    if (requestParameters.after !== undefined) {
      queryParameters['after'] = (requestParameters.after as any).toISOString();
    }

    if (requestParameters.before !== undefined) {
      queryParameters[
        'before'
      ] = (requestParameters.before as any).toISOString();
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v2/organizations/{organizationId}/tickets`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketListFromJSON(jsonValue)
    );
  }

  /**
   * gets a list of all tickets that the user has access to
   */
  async listTicketsV2(
    requestParameters: ListTicketsV2Request
  ): Promise<TicketList> {
    const response = await this.listTicketsV2Raw(requestParameters);
    return await response.value();
  }

  /**
   * this endpoint is a proxy to Query Broker with spec https://github.com/rtkwlf/querybroker/blob/9b38f2ef4fa9041b6990592bd50841461e0353db/api_spec.yaml. It is thus valid to use a QB API client that is configured to point at a Rendall deployment. Rendall will proxy the request directly to QueryBroker. Authorization happens in query broker for this endpoint.
   */
  async postQueryBrokerRaw(
    requestParameters: PostQueryBrokerRequest
  ): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling postQueryBroker.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/query/{path:.+}`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * this endpoint is a proxy to Query Broker with spec https://github.com/rtkwlf/querybroker/blob/9b38f2ef4fa9041b6990592bd50841461e0353db/api_spec.yaml. It is thus valid to use a QB API client that is configured to point at a Rendall deployment. Rendall will proxy the request directly to QueryBroker. Authorization happens in query broker for this endpoint.
   */
  async postQueryBroker(
    requestParameters: PostQueryBrokerRequest
  ): Promise<{ [key: string]: object }> {
    const response = await this.postQueryBrokerRaw(requestParameters);
    return await response.value();
  }

  /**
   * Request to initiate a scan
   */
  async scanAgentNowRaw(
    requestParameters: ScanAgentNowRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling scanAgentNow.'
      );
    }

    if (
      requestParameters.scanAgentNowInput === null ||
      requestParameters.scanAgentNowInput === undefined
    ) {
      throw new runtime.RequiredError(
        'scanAgentNowInput',
        'Required parameter requestParameters.scanAgentNowInput was null or undefined when calling scanAgentNow.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/scan-schedules/agent/scan`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ScanAgentNowInputToJSON(requestParameters.scanAgentNowInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Request to initiate a scan
   */
  async scanAgentNow(requestParameters: ScanAgentNowRequest): Promise<void> {
    await this.scanAgentNowRaw(requestParameters);
  }

  /**
   * Rescan supplied EVA assets
   */
  async scanEvaNowRaw(
    requestParameters: ScanEvaNowRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling scanEvaNow.'
      );
    }

    if (
      requestParameters.scanEvaNowInput === null ||
      requestParameters.scanEvaNowInput === undefined
    ) {
      throw new runtime.RequiredError(
        'scanEvaNowInput',
        'Required parameter requestParameters.scanEvaNowInput was null or undefined when calling scanEvaNow.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/eva/scan`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ScanEvaNowInputToJSON(requestParameters.scanEvaNowInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Rescan supplied EVA assets
   */
  async scanEvaNow(requestParameters: ScanEvaNowRequest): Promise<void> {
    await this.scanEvaNowRaw(requestParameters);
  }

  /**
   * Request to initiate a scan
   */
  async scanIvaNowRaw(
    requestParameters: ScanIvaNowRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationId === null ||
      requestParameters.organizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling scanIvaNow.'
      );
    }

    if (
      requestParameters.scanIvaNowInput === null ||
      requestParameters.scanIvaNowInput === undefined
    ) {
      throw new runtime.RequiredError(
        'scanIvaNowInput',
        'Required parameter requestParameters.scanIvaNowInput was null or undefined when calling scanIvaNow.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/organizations/{organizationId}/iva/scan`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(requestParameters.organizationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ScanIvaNowInputToJSON(requestParameters.scanIvaNowInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Request to initiate a scan
   */
  async scanIvaNow(requestParameters: ScanIvaNowRequest): Promise<void> {
    await this.scanIvaNowRaw(requestParameters);
  }

  /**
   * Upload an attachment to the ticket. Attachment isn\'t linked to a specific organization until the ticket is created and therefore this endpoint isn\'t authorized.
   */
  async uploadZendeskAttachmentRaw(
    requestParameters: UploadZendeskAttachmentRequest
  ): Promise<runtime.ApiResponse<ZendeskUploadResponse>> {
    if (
      requestParameters.filename === null ||
      requestParameters.filename === undefined
    ) {
      throw new runtime.RequiredError(
        'filename',
        'Required parameter requestParameters.filename was null or undefined when calling uploadZendeskAttachment.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.filename !== undefined) {
      queryParameters['filename'] = requestParameters.filename;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/octet-stream';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/v1/uploads`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ZendeskUploadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Upload an attachment to the ticket. Attachment isn\'t linked to a specific organization until the ticket is created and therefore this endpoint isn\'t authorized.
   */
  async uploadZendeskAttachment(
    requestParameters: UploadZendeskAttachmentRequest
  ): Promise<ZendeskUploadResponse> {
    const response = await this.uploadZendeskAttachmentRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetAgentPageOsTypeEnum {
  Windows = 'windows',
  Mac = 'mac',
  Linux = 'linux',
}
/**
 * @export
 * @enum {string}
 */
export enum GetAgentPageStatusEnum {
  Online = 'online',
  Offline = 'offline',
  Inactive = 'inactive',
  AllOffline = 'allOffline',
  Contained = 'contained',
}
/**
 * @export
 * @enum {string}
 */
export enum GetCloudSensorPageStatusEnum {
  Healthy = 'healthy',
  Unhealthy = 'unhealthy',
  Disconnectpending = 'disconnectpending',
  Configpending = 'configpending',
  Notavailable = 'notavailable',
  Notapplicable = 'notapplicable',
  Invalidcredentials = 'invalidcredentials',
}
/**
 * @export
 * @enum {string}
 */
export enum GetScannerPageStatusEnum {
  Disconnected = 'disconnected',
  Degraded = 'degraded',
  AwaitingActivation = 'awaitingActivation',
  Scanning = 'scanning',
  Idle = 'idle',
  Connected = 'connected',
  NotAvailable = 'notAvailable',
}
/**
 * @export
 * @enum {string}
 */
export enum GetScannerPageTypeEnum {
  Physical = 'physical',
  Virtual = 'virtual',
}
/**
 * @export
 * @enum {string}
 */
export enum GetSensorPageStatusEnum {
  Unhealthy = 'unhealthy',
  Staged = 'staged',
  AwaitingActivation = 'awaitingActivation',
  Shipped = 'shipped',
  PreparingToShip = 'preparingToShip',
  Healthy = 'healthy',
}
/**
 * @export
 * @enum {string}
 */
export enum GetSensorPageProductEnum {
  Physical = 'physical',
  Virtual = 'virtual',
  Vlc = 'vlc',
}
