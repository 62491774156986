/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EvaScheduleHost
 */
export interface EvaScheduleHost {
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  domainName?: string;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  cidrBlock?: string;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  accountID?: string;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleHost
   */
  autoDetectChildren?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleHost
   */
  autoScanChildren?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleHost
   */
  scan?: boolean;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleHost
   */
  parent?: string;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleHost
   */
  strict?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleHost
   */
  strictChildren?: boolean;
}

export function EvaScheduleHostFromJSON(json: any): EvaScheduleHost {
  return EvaScheduleHostFromJSONTyped(json, false);
}

export function EvaScheduleHostFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EvaScheduleHost {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    domainName: !exists(json, 'domainName') ? undefined : json['domainName'],
    ipAddress: !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
    cidrBlock: !exists(json, 'cidrBlock') ? undefined : json['cidrBlock'],
    accountID: !exists(json, 'accountID') ? undefined : json['accountID'],
    description: !exists(json, 'description') ? undefined : json['description'],
    autoDetectChildren: !exists(json, 'autoDetectChildren')
      ? undefined
      : json['autoDetectChildren'],
    autoScanChildren: !exists(json, 'autoScanChildren')
      ? undefined
      : json['autoScanChildren'],
    scan: !exists(json, 'scan') ? undefined : json['scan'],
    parent: !exists(json, 'parent') ? undefined : json['parent'],
    strict: !exists(json, 'strict') ? undefined : json['strict'],
    strictChildren: !exists(json, 'strictChildren')
      ? undefined
      : json['strictChildren'],
  };
}

export function EvaScheduleHostToJSON(value?: EvaScheduleHost | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    domainName: value.domainName,
    ipAddress: value.ipAddress,
    cidrBlock: value.cidrBlock,
    accountID: value.accountID,
    description: value.description,
    autoDetectChildren: value.autoDetectChildren,
    autoScanChildren: value.autoScanChildren,
    scan: value.scan,
    parent: value.parent,
    strict: value.strict,
    strictChildren: value.strictChildren,
  };
}
