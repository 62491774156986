/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TargetStatus {
  Disconnected = 'disconnected',
  Degraded = 'degraded',
  AwaitingActivation = 'awaitingActivation',
  Scanning = 'scanning',
  Idle = 'idle',
  Connected = 'connected',
  NotAvailable = 'notAvailable',
  Online = 'online',
  Offline = 'offline',
  Inactive = 'inactive',
  AllOffline = 'allOffline',
  Contained = 'contained',
  Scheduled = 'scheduled',
}

export function TargetStatusFromJSON(json: any): TargetStatus {
  return TargetStatusFromJSONTyped(json, false);
}

export function TargetStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TargetStatus {
  return json as TargetStatus;
}

export function TargetStatusToJSON(value?: TargetStatus | null): any {
  return value as any;
}
