/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AttachmentFileThumbnail,
  AttachmentFileThumbnailFromJSON,
  AttachmentFileThumbnailFromJSONTyped,
  AttachmentFileThumbnailToJSON,
} from './';

/**
 * attachment file type that gets attached to a ticket or comment
 * @export
 * @interface AttachmentFile
 */
export interface AttachmentFile {
  /**
   *
   * @type {number}
   * @memberof AttachmentFile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AttachmentFile
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentFile
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof AttachmentFile
   */
  size?: number;
  /**
   *
   * @type {AttachmentFileThumbnail}
   * @memberof AttachmentFile
   */
  thumbnail?: AttachmentFileThumbnail;
}

export function AttachmentFileFromJSON(json: any): AttachmentFile {
  return AttachmentFileFromJSONTyped(json, false);
}

export function AttachmentFileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttachmentFile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    url: !exists(json, 'url') ? undefined : json['url'],
    name: !exists(json, 'name') ? undefined : json['name'],
    size: !exists(json, 'size') ? undefined : json['size'],
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : AttachmentFileThumbnailFromJSON(json['thumbnail']),
  };
}

export function AttachmentFileToJSON(value?: AttachmentFile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    url: value.url,
    name: value.name,
    size: value.size,
    thumbnail: AttachmentFileThumbnailToJSON(value.thumbnail),
  };
}
