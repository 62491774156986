/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateTicketInputTicketType,
    CreateTicketInputTicketTypeFromJSON,
    CreateTicketInputTicketTypeFromJSONTyped,
    CreateTicketInputTicketTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateTicketInput
 */
export interface CreateTicketInput {
    /**
     * 
     * @type {string}
     * @memberof CreateTicketInput
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketInput
     */
    description?: string;
    /**
     * 
     * @type {CreateTicketInputTicketType}
     * @memberof CreateTicketInput
     */
    ticketType?: CreateTicketInputTicketType;
    /**
     * this shows up in Zendesk as an "impersonation link" on the created ticket; leave blank to disable this behaviour; the value must be a URL to https://dashboard.arcticwolf.com
     * @type {string}
     * @memberof CreateTicketInput
     */
    link?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTicketInput
     */
    attachments?: Array<string>;
}

export function CreateTicketInputFromJSON(json: any): CreateTicketInput {
    return CreateTicketInputFromJSONTyped(json, false);
}

export function CreateTicketInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTicketInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ticketType': !exists(json, 'ticket_type') ? undefined : CreateTicketInputTicketTypeFromJSON(json['ticket_type']),
        'link': !exists(json, 'link') ? undefined : json['link'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
    };
}

export function CreateTicketInputToJSON(value?: CreateTicketInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'description': value.description,
        'ticket_type': CreateTicketInputTicketTypeToJSON(value.ticketType),
        'link': value.link,
        'attachments': value.attachments,
    };
}


