/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ContactTime,
  ContactTimeFromJSON,
  ContactTimeFromJSONTyped,
  ContactTimeToJSON,
  EscalationContactAvailability,
  EscalationContactAvailabilityFromJSON,
  EscalationContactAvailabilityFromJSONTyped,
  EscalationContactAvailabilityToJSON,
} from './';

/**
 *
 * @export
 * @interface ContactTimeRange
 */
export interface ContactTimeRange {
  /**
   * Number of day in week, with Sunday as 0
   * @type {number}
   * @memberof ContactTimeRange
   */
  dayOfWeek: number;
  /**
   *
   * @type {EscalationContactAvailability}
   * @memberof ContactTimeRange
   */
  availability: EscalationContactAvailability;
  /**
   *
   * @type {ContactTime}
   * @memberof ContactTimeRange
   */
  start?: ContactTime;
  /**
   *
   * @type {ContactTime}
   * @memberof ContactTimeRange
   */
  end?: ContactTime;
}

export function ContactTimeRangeFromJSON(json: any): ContactTimeRange {
  return ContactTimeRangeFromJSONTyped(json, false);
}

export function ContactTimeRangeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactTimeRange {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dayOfWeek: json['dayOfWeek'],
    availability: EscalationContactAvailabilityFromJSON(json['availability']),
    start: !exists(json, 'start')
      ? undefined
      : ContactTimeFromJSON(json['start']),
    end: !exists(json, 'end') ? undefined : ContactTimeFromJSON(json['end']),
  };
}

export function ContactTimeRangeToJSON(value?: ContactTimeRange | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dayOfWeek: value.dayOfWeek,
    availability: EscalationContactAvailabilityToJSON(value.availability),
    start: ContactTimeToJSON(value.start),
    end: ContactTimeToJSON(value.end),
  };
}
