/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * overview of status for all cloud sensors
 * @export
 * @interface CloudSensorSummary
 */
export interface CloudSensorSummary {
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    unhealthy: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    invalidCredentials: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    configPending: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    disconnectPending: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    healthy: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    notApplicable: number;
    /**
     * 
     * @type {number}
     * @memberof CloudSensorSummary
     */
    notAvailable: number;
}

export function CloudSensorSummaryFromJSON(json: any): CloudSensorSummary {
    return CloudSensorSummaryFromJSONTyped(json, false);
}

export function CloudSensorSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudSensorSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unhealthy': json['unhealthy'],
        'invalidCredentials': json['invalidCredentials'],
        'configPending': json['configPending'],
        'disconnectPending': json['disconnectPending'],
        'healthy': json['healthy'],
        'notApplicable': json['notApplicable'],
        'notAvailable': json['notAvailable'],
    };
}

export function CloudSensorSummaryToJSON(value?: CloudSensorSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unhealthy': value.unhealthy,
        'invalidCredentials': value.invalidCredentials,
        'configPending': value.configPending,
        'disconnectPending': value.disconnectPending,
        'healthy': value.healthy,
        'notApplicable': value.notApplicable,
        'notAvailable': value.notAvailable,
    };
}


