/**
 * This documents the meanings of possible ruleId from the agent audit API
 * Refer to
 * https://github.com/root-secure/awn-scout/blob/dev/scout-server/alertparse/alerthandler.go for all ruleIds
 * https://github.com/root-secure/awn-scout/tree/dev/scout-server/alertparse for parser/schema of rules
 */
export enum AGENT_AUDIT_RULE_ID {
  //Windows Audit Rule Ids

  WindowsTaskList = 100051, //Windows task list rule id
  WindowsWirelessNetwork = 100091, //Windows wireless networks rule id
  WindowsHardware = 100111, //Windows hardware list
  WindowsSoftware = 100121, //Windows software list
  WindowsUsbDevices = 100161, //Windows USB Devices

  //Mac Audit Rule Ids

  MacTaskList = 110000, //Mac task list rule id
  MacWirelessNetwork = 110100, //Mac wireless networks rule id
  MacHardware = 110150, //Mac hardware list
  MacSoftware = 110200, //Mac software list
  MacUsbDevices = 110400, //Mac USB Devices

  //Linux Audit Rule Ids

  LinuxTaskList = 120000, //Linux task list rule id
  LinuxWirelessNetwork = 120100, //Linux wireless networks
  LinuxHardware = 120150, //Linux hardware list
  LinuxSoftware = 120200, //Linux software list
  LinuxUsbDevices = 120400, //Linux USB Devices
}

export enum AGENT_OS {
  WINDOWS = 'WINDOWS',
  MACOS = 'MACOS',
  LINUX = 'LINUX',
}

export enum AuditCategory {
  TASK_LIST = 'TASK_LIST',
  WIRELESS_NETWORK = 'WIRELESS_NETWORK',
  SOFTWARE = 'SOFTWARE',
  USB_DEVICES = 'USB_DEVICES',
  HARDWARE = 'HARDWARE',
}

export const ASSET_CATEGORIES = [
  'unassigned',
  'desktop',
  'firewall',
  'laptop',
  'server',
  'printer',
  'network',
  'mobile',
  'embedded',
  'iot',
  'other',
];

// More collections of rule IDs in alternative data structures

export const AGENT_AUDIT_RULE_ID_MAP: Record<
  AGENT_OS,
  Record<AuditCategory, AGENT_AUDIT_RULE_ID>
> = {
  [AGENT_OS.WINDOWS]: {
    [AuditCategory.TASK_LIST]: AGENT_AUDIT_RULE_ID.WindowsTaskList,
    [AuditCategory.WIRELESS_NETWORK]:
      AGENT_AUDIT_RULE_ID.WindowsWirelessNetwork,
    [AuditCategory.SOFTWARE]: AGENT_AUDIT_RULE_ID.WindowsSoftware,
    [AuditCategory.HARDWARE]: AGENT_AUDIT_RULE_ID.WindowsHardware,
    [AuditCategory.USB_DEVICES]: AGENT_AUDIT_RULE_ID.WindowsUsbDevices,
  },
  [AGENT_OS.MACOS]: {
    [AuditCategory.TASK_LIST]: AGENT_AUDIT_RULE_ID.MacTaskList,
    [AuditCategory.WIRELESS_NETWORK]: AGENT_AUDIT_RULE_ID.MacWirelessNetwork,
    [AuditCategory.SOFTWARE]: AGENT_AUDIT_RULE_ID.MacSoftware,
    [AuditCategory.HARDWARE]: AGENT_AUDIT_RULE_ID.MacHardware,
    [AuditCategory.USB_DEVICES]: AGENT_AUDIT_RULE_ID.MacUsbDevices,
  },
  [AGENT_OS.LINUX]: {
    [AuditCategory.TASK_LIST]: AGENT_AUDIT_RULE_ID.LinuxTaskList,
    [AuditCategory.WIRELESS_NETWORK]: AGENT_AUDIT_RULE_ID.LinuxWirelessNetwork,
    [AuditCategory.SOFTWARE]: AGENT_AUDIT_RULE_ID.LinuxSoftware,
    [AuditCategory.HARDWARE]: AGENT_AUDIT_RULE_ID.LinuxHardware,
    [AuditCategory.USB_DEVICES]: AGENT_AUDIT_RULE_ID.LinuxUsbDevices,
  },
};

export const AGENT_AUDIT_RULE_ID_BY_OS = {
  [AGENT_OS.LINUX]: [
    AGENT_AUDIT_RULE_ID.LinuxTaskList,
    AGENT_AUDIT_RULE_ID.LinuxWirelessNetwork,
    AGENT_AUDIT_RULE_ID.LinuxSoftware,
    AGENT_AUDIT_RULE_ID.LinuxHardware,
    AGENT_AUDIT_RULE_ID.LinuxUsbDevices,
  ],
  [AGENT_OS.MACOS]: [
    AGENT_AUDIT_RULE_ID.MacTaskList,
    AGENT_AUDIT_RULE_ID.MacWirelessNetwork,
    AGENT_AUDIT_RULE_ID.MacSoftware,
    AGENT_AUDIT_RULE_ID.MacHardware,
    AGENT_AUDIT_RULE_ID.MacUsbDevices,
  ],
  [AGENT_OS.WINDOWS]: [
    AGENT_AUDIT_RULE_ID.WindowsTaskList,
    AGENT_AUDIT_RULE_ID.WindowsWirelessNetwork,
    AGENT_AUDIT_RULE_ID.WindowsSoftware,
    AGENT_AUDIT_RULE_ID.MacHardware,
    AGENT_AUDIT_RULE_ID.WindowsUsbDevices,
  ],
};
