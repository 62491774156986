/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LogSearchExport,
  LogSearchExportFromJSON,
  LogSearchExportFromJSONTyped,
  LogSearchExportToJSON,
  LogSearchRequestRequest,
  LogSearchRequestRequestFromJSON,
  LogSearchRequestRequestFromJSONTyped,
  LogSearchRequestRequestToJSON,
} from './';

/**
 *
 * @export
 * @interface LogSearchRequest
 */
export interface LogSearchRequest {
  /**
   *
   * @type {string}
   * @memberof LogSearchRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LogSearchRequest
   */
  user: string;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof LogSearchRequest
   */
  date: Date;
  /**
   *
   * @type {LogSearchRequestRequest}
   * @memberof LogSearchRequest
   */
  request: LogSearchRequestRequest;
  /**
   *
   * @type {number}
   * @memberof LogSearchRequest
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof LogSearchRequest
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof LogSearchRequest
   */
  size: number;
  /**
   *
   * @type {Array<LogSearchExport>}
   * @memberof LogSearchRequest
   */
  exports: Array<LogSearchExport>;
  /**
   *
   * @type {boolean}
   * @memberof LogSearchRequest
   */
  failed: boolean;
}

export function LogSearchRequestFromJSON(json: any): LogSearchRequest {
  return LogSearchRequestFromJSONTyped(json, false);
}

export function LogSearchRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogSearchRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    user: json['user'],
    date: new Date(json['date']),
    request: LogSearchRequestRequestFromJSON(json['request']),
    percent: json['percent'],
    count: json['count'],
    size: json['size'],
    exports: (json['exports'] as Array<any>).map(LogSearchExportFromJSON),
    failed: json['failed'],
  };
}

export function LogSearchRequestToJSON(value?: LogSearchRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user: value.user,
    date: value.date.toISOString(),
    request: LogSearchRequestRequestToJSON(value.request),
    percent: value.percent,
    count: value.count,
    size: value.size,
    exports: (value.exports as Array<any>).map(LogSearchExportToJSON),
    failed: value.failed,
  };
}
