/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ScannerType,
  ScannerTypeFromJSON,
  ScannerTypeFromJSONTyped,
  ScannerTypeToJSON,
  TargetStatus,
  TargetStatusFromJSON,
  TargetStatusFromJSONTyped,
  TargetStatusToJSON,
} from './';

/**
 * a high-level overview of a single scan schedule suitable for rendering in a table
 * @export
 * @interface Target
 */
export interface Target {
  /**
   * name of the target
   * @type {string}
   * @memberof Target
   */
  name?: string;
  /**
   * description of the target
   * @type {string}
   * @memberof Target
   */
  description?: string;
  /**
   *
   * @type {TargetStatus}
   * @memberof Target
   */
  status?: TargetStatus;
  /**
   * if scanning is enabled
   * @type {boolean}
   * @memberof Target
   */
  scanningEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Target
   */
  scanDiscovery?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Target
   */
  strict?: boolean;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  operatingSystem?: string;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  sysmon?: string;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  clientUUID?: string;
  /**
   * an IPv4 or IPv6 address
   * @type {string}
   * @memberof Target
   */
  ipAddress?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Target
   */
  ipAddresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  version?: string;
  /**
   *
   * @type {ScannerType}
   * @memberof Target
   */
  productType?: ScannerType;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  range?: string;
  /**
   *
   * @type {string}
   * @memberof Target
   */
  lastScan?: string;
}

export function TargetFromJSON(json: any): Target {
  return TargetFromJSONTyped(json, false);
}

export function TargetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Target {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    status: !exists(json, 'status')
      ? undefined
      : TargetStatusFromJSON(json['status']),
    scanningEnabled: !exists(json, 'scanningEnabled')
      ? undefined
      : json['scanningEnabled'],
    scanDiscovery: !exists(json, 'scanDiscovery')
      ? undefined
      : json['scanDiscovery'],
    strict: !exists(json, 'strict') ? undefined : json['strict'],
    operatingSystem: !exists(json, 'operatingSystem')
      ? undefined
      : json['operatingSystem'],
    sysmon: !exists(json, 'sysmon') ? undefined : json['sysmon'],
    clientUUID: !exists(json, 'clientUUID') ? undefined : json['clientUUID'],
    ipAddress: !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
    ipAddresses: !exists(json, 'ipAddresses') ? undefined : json['ipAddresses'],
    version: !exists(json, 'version') ? undefined : json['version'],
    productType: !exists(json, 'productType')
      ? undefined
      : ScannerTypeFromJSON(json['productType']),
    range: !exists(json, 'range') ? undefined : json['range'],
    lastScan: !exists(json, 'lastScan') ? undefined : json['lastScan'],
  };
}

export function TargetToJSON(value?: Target | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    status: TargetStatusToJSON(value.status),
    scanningEnabled: value.scanningEnabled,
    scanDiscovery: value.scanDiscovery,
    strict: value.strict,
    operatingSystem: value.operatingSystem,
    sysmon: value.sysmon,
    clientUUID: value.clientUUID,
    ipAddress: value.ipAddress,
    ipAddresses: value.ipAddresses,
    version: value.version,
    productType: ScannerTypeToJSON(value.productType),
    range: value.range,
    lastScan: value.lastScan,
  };
}
