/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A network interface
 * @export
 * @interface NetworkInterface
 */
export interface NetworkInterface {
    /**
     * The name of the network interface
     * @type {string}
     * @memberof NetworkInterface
     */
    name?: string;
    /**
     * The MAC address of the network interface
     * @type {string}
     * @memberof NetworkInterface
     */
    macAddress?: string;
    /**
     * The IP addresses of the network interface
     * @type {Array<string>}
     * @memberof NetworkInterface
     */
    addresses?: Array<string>;
}

export function NetworkInterfaceFromJSON(json: any): NetworkInterface {
    return NetworkInterfaceFromJSONTyped(json, false);
}

export function NetworkInterfaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkInterface {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'macAddress': !exists(json, 'macAddress') ? undefined : json['macAddress'],
        'addresses': !exists(json, 'addresses') ? undefined : json['addresses'],
    };
}

export function NetworkInterfaceToJSON(value?: NetworkInterface | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'macAddress': value.macAddress,
        'addresses': value.addresses,
    };
}


