/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentHostLocation
 */
export interface AgentHostLocation {
    /**
     * The city of the location
     * @type {string}
     * @memberof AgentHostLocation
     */
    city?: string;
    /**
     * The country of the location
     * @type {string}
     * @memberof AgentHostLocation
     */
    country?: string;
    /**
     * The latitude of the location
     * @type {number}
     * @memberof AgentHostLocation
     */
    latitude?: number;
    /**
     * The longitude of the location
     * @type {number}
     * @memberof AgentHostLocation
     */
    longitude?: number;
}

export function AgentHostLocationFromJSON(json: any): AgentHostLocation {
    return AgentHostLocationFromJSONTyped(json, false);
}

export function AgentHostLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentHostLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function AgentHostLocationToJSON(value?: AgentHostLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'country': value.country,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}


