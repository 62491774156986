/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationContactContactTypes
 */
export interface OrganizationContactContactTypes {
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  primary?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  secondary?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  distributionList?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  billing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  executiveSponsorManager?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  serverWindowsAd?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  networkingInfrastructure?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  managedRisk?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactContactTypes
   */
  managedAwareness?: boolean;
}

export function OrganizationContactContactTypesFromJSON(
  json: any
): OrganizationContactContactTypes {
  return OrganizationContactContactTypesFromJSONTyped(json, false);
}

export function OrganizationContactContactTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationContactContactTypes {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    primary: !exists(json, 'primary') ? undefined : json['primary'],
    secondary: !exists(json, 'secondary') ? undefined : json['secondary'],
    distributionList: !exists(json, 'distributionList')
      ? undefined
      : json['distributionList'],
    billing: !exists(json, 'billing') ? undefined : json['billing'],
    executiveSponsorManager: !exists(json, 'executiveSponsorManager')
      ? undefined
      : json['executiveSponsorManager'],
    serverWindowsAd: !exists(json, 'serverWindowsAd')
      ? undefined
      : json['serverWindowsAd'],
    networkingInfrastructure: !exists(json, 'networkingInfrastructure')
      ? undefined
      : json['networkingInfrastructure'],
    managedRisk: !exists(json, 'managedRisk') ? undefined : json['managedRisk'],
    managedAwareness: !exists(json, 'managedAwareness')
      ? undefined
      : json['managedAwareness'],
  };
}

export function OrganizationContactContactTypesToJSON(
  value?: OrganizationContactContactTypes | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    primary: value.primary,
    secondary: value.secondary,
    distributionList: value.distributionList,
    billing: value.billing,
    executiveSponsorManager: value.executiveSponsorManager,
    serverWindowsAd: value.serverWindowsAd,
    networkingInfrastructure: value.networkingInfrastructure,
    managedRisk: value.managedRisk,
    managedAwareness: value.managedAwareness,
  };
}
