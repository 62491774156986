/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CronSchedule,
  CronScheduleFromJSON,
  CronScheduleFromJSONTyped,
  CronScheduleToJSON,
  EvaScheduleHost,
  EvaScheduleHostFromJSON,
  EvaScheduleHostFromJSONTyped,
  EvaScheduleHostToJSON,
  EvaScheduleTasks,
  EvaScheduleTasksFromJSON,
  EvaScheduleTasksFromJSONTyped,
  EvaScheduleTasksToJSON,
} from './';

/**
 *
 * @export
 * @interface EvaScheduleCreate
 */
export interface EvaScheduleCreate {
  /**
   *
   * @type {string}
   * @memberof EvaScheduleCreate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EvaScheduleCreate
   */
  description?: string;
  /**
   *
   * @type {CronSchedule}
   * @memberof EvaScheduleCreate
   */
  schedule?: CronSchedule;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleCreate
   */
  scheduleDisabled?: boolean;
  /**
   *
   * @type {EvaScheduleTasks}
   * @memberof EvaScheduleCreate
   */
  tasks?: EvaScheduleTasks;
  /**
   *
   * @type {Array<EvaScheduleHost>}
   * @memberof EvaScheduleCreate
   */
  hosts?: Array<EvaScheduleHost>;
}

export function EvaScheduleCreateFromJSON(json: any): EvaScheduleCreate {
  return EvaScheduleCreateFromJSONTyped(json, false);
}

export function EvaScheduleCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EvaScheduleCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    schedule: !exists(json, 'schedule')
      ? undefined
      : CronScheduleFromJSON(json['schedule']),
    scheduleDisabled: !exists(json, 'scheduleDisabled')
      ? undefined
      : json['scheduleDisabled'],
    tasks: !exists(json, 'tasks')
      ? undefined
      : EvaScheduleTasksFromJSON(json['tasks']),
    hosts: !exists(json, 'hosts')
      ? undefined
      : (json['hosts'] as Array<any>).map(EvaScheduleHostFromJSON),
  };
}

export function EvaScheduleCreateToJSON(value?: EvaScheduleCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    schedule: CronScheduleToJSON(value.schedule),
    scheduleDisabled: value.scheduleDisabled,
    tasks: EvaScheduleTasksToJSON(value.tasks),
    hosts:
      value.hosts === undefined
        ? undefined
        : (value.hosts as Array<any>).map(EvaScheduleHostToJSON),
  };
}
