/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for enable/disabling agent scan schedules
 * @export
 * @interface ManageAgentScheduleState
 */
export interface ManageAgentScheduleState {
  /**
   *
   * @type {string}
   * @memberof ManageAgentScheduleState
   */
  scheduleId: string;
  /**
   *
   * @type {boolean}
   * @memberof ManageAgentScheduleState
   */
  scanningDisabled: boolean;
}

export function ManageAgentScheduleStateFromJSON(
  json: any
): ManageAgentScheduleState {
  return ManageAgentScheduleStateFromJSONTyped(json, false);
}

export function ManageAgentScheduleStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManageAgentScheduleState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scheduleId: json['scheduleId'],
    scanningDisabled: json['scanningDisabled'],
  };
}

export function ManageAgentScheduleStateToJSON(
  value?: ManageAgentScheduleState | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scheduleId: value.scheduleId,
    scanningDisabled: value.scanningDisabled,
  };
}
