/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CommunicationPreference,
  CommunicationPreferenceFromJSON,
  CommunicationPreferenceFromJSONTyped,
  CommunicationPreferenceToJSON,
} from './';

/**
 *
 * @export
 * @interface EscalationTier
 */
export interface EscalationTier {
  /**
   *
   * @type {number}
   * @memberof EscalationTier
   */
  tierNumber: number;
  /**
   *
   * @type {Array<CommunicationPreference>}
   * @memberof EscalationTier
   */
  communicationPreferences: Array<CommunicationPreference>;
}

export function EscalationTierFromJSON(json: any): EscalationTier {
  return EscalationTierFromJSONTyped(json, false);
}

export function EscalationTierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EscalationTier {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tierNumber: json['tierNumber'],
    communicationPreferences: (json[
      'communicationPreferences'
    ] as Array<any>).map(CommunicationPreferenceFromJSON),
  };
}

export function EscalationTierToJSON(value?: EscalationTier | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tierNumber: value.tierNumber,
    communicationPreferences: (value.communicationPreferences as Array<any>).map(
      CommunicationPreferenceToJSON
    ),
  };
}
