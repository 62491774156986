import React, { Component, Fragment } from 'react';
import { gantt } from 'dhtmlx-gantt';
import './Gantt.css';

const zoomConfig = {
  levels: [
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 75,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: function (date) {
            var dateToStr = gantt.date.date_to_str('%d %M');
            var endDate = gantt.date.add(date, 6, 'day');
            var weekNum = gantt.date.date_to_str('%W')(date);
            return (
              'Week ' +
              weekNum +
              ', ' +
              dateToStr(date) +
              ' - ' +
              dateToStr(endDate)
            );
          },
        },
        { unit: 'day', step: 1, format: '%j %D' },
      ],
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 20,
      scales: [
        { unit: 'month', format: '%F, %Y' },
        { unit: 'day', format: '%j' },
      ],
    },
    {
      name: 'quarter',
      height: 50,
      min_column_width: 250,
      scales: [
        { unit: 'month', step: 1, format: '%M' },
        {
          unit: 'quarter',
          step: 1,
          format: function (date) {
            var dateToStr = gantt.date.date_to_str('%M');
            var endDate = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day'
            );
            return dateToStr(date) + ' - ' + dateToStr(endDate);
          },
        },
      ],
    },
  ],
  trigger: 'wheel',
  useKey: 'altKey',
};

function twoLineName(task) {
  if (task.riskState) {
    return `<div class="two-line-name"><div class="risk-text">${
      task.text
    }</div><div class="risk-state">${task.riskState || ''}</div></div>`;
  }
  return `<div class="two-line-name"><div class="case-text">${
    task.text
  }</div><div class="case-risks">${task.description || ''}</div></div>`;
}

function twoLineDuration(task) {
  return `<div class="two-line-name"><div class="case-text">${
    task.length
  }</div><div class="risk-state">${task.days || ''}</div></div>`;
}

function sortRisks(a, b) {
  a = a.start_date;
  b = b.start_date;
  return a > b ? 1 : a < b ? -1 : 0;
}

function initGantt(data, onClick, ganttContainer) {
  if (!gantt.$eventsAttached) {
    gantt.$eventsAttached = true;

    gantt.config.select_task = true;
    gantt.config.columns = [
      {
        name: 'text',
        label: 'Plan name',
        tree: true,
        width: '*',
        template: twoLineName,
      },
      {
        name: 'length',
        label: 'Duration',
        align: 'center',
        template: twoLineDuration,
      },
    ];

    gantt.config.xml_date = '%Y-%m-%d';

    gantt.templates.grid_file = function (item) {
      return item.icon
        ? `<span class="dot" style="background-color: ${item.icon}"></span>`
        : '<div></div>';
    };

    gantt.isLinkAllowed(false);

    gantt.attachEvent('onTaskDblClick', function (id, e) {
      return false;
    });

    gantt.attachEvent('onBeforeTaskDrag', function (id, mode, e) {
      return false; //allows dragging if the global task index is even
    });

    gantt.attachEvent('onMouseMove', function (id, e) {
      return false;
    });

    gantt.attachEvent('onTaskClick', function (id, e) {
      onClick(id);
      return true;
    });
  }

  gantt.config.columns[1].sort = 'start_date';
  gantt.config.sort = true;

  gantt.plugins({
    marker: true,
  });

  const dateToStr = gantt.date.date_to_str(gantt.config.task_date);

  gantt.ext.zoom.init(zoomConfig);
  const markerId = gantt.addMarker({
    start_date: new Date(),
    css: 'today',
    text: ' ',
    title: dateToStr(new Date()),
  });

  gantt.getMarker(markerId);

  gantt.init(ganttContainer);
  gantt.parse(data);
}

export default class Gantt extends Component {
  componentDidMount() {
    const { data, onClick } = this.props;
    initGantt(data, onClick, this.ganttContainer);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (data.data.length === 0) {
      gantt.clearAll();
    } else if (data.data !== prevProps.data.data) {
      gantt.clearAll();
      gantt.parse(data);
      gantt.sort(sortRisks);
      gantt.render();
    }
  }

  componentWillUnmount() {
    gantt.clearAll();
    gantt.ext.zoom.setLevel('week');
  }

  render() {
    return (
      <Fragment>
        {this.props.isProcessing ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '600px',
              zIndex: 1,
              backgroundColor: '#d9dde4',
              opacity: 0.6,
              textAlign: 'center',
            }}
          >
            <i
              className='fa fa-spinner fa-pulse'
              style={{ fontSize: '200px' }}
            />
          </div>
        ) : null}
        <div
          ref={(input) => {
            this.ganttContainer = input;
          }}
          style={{ width: '100%', minHeight: '600px', height: '100%' }}
        ></div>
      </Fragment>
    );
  }
}
