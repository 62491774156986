/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationContactAccess
 */
export interface OrganizationContactAccess {
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactAccess
   */
  portal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactAccess
   */
  logSearch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactAccess
   */
  activateVlc?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationContactAccess
   */
  managedAwareness?: boolean;
}

export function OrganizationContactAccessFromJSON(
  json: any
): OrganizationContactAccess {
  return OrganizationContactAccessFromJSONTyped(json, false);
}

export function OrganizationContactAccessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationContactAccess {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    portal: !exists(json, 'portal') ? undefined : json['portal'],
    logSearch: !exists(json, 'logSearch') ? undefined : json['logSearch'],
    activateVlc: !exists(json, 'activateVlc') ? undefined : json['activateVlc'],
    managedAwareness: !exists(json, 'managedAwareness')
      ? undefined
      : json['managedAwareness'],
  };
}

export function OrganizationContactAccessToJSON(
  value?: OrganizationContactAccess | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    portal: value.portal,
    logSearch: value.logSearch,
    activateVlc: value.activateVlc,
    managedAwareness: value.managedAwareness,
  };
}
