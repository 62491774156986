/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentHostOs
 */
export interface AgentHostOs {
    /**
     * 
     * @type {string}
     * @memberof AgentHostOs
     */
    type?: AgentHostOsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentHostOs
     */
    name?: string;
}

/**
* @export
* @enum {string}
*/
export enum AgentHostOsTypeEnum {
    Windows = 'windows',
    Mac = 'mac',
    Linux = 'linux'
}

export function AgentHostOsFromJSON(json: any): AgentHostOs {
    return AgentHostOsFromJSONTyped(json, false);
}

export function AgentHostOsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentHostOs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function AgentHostOsToJSON(value?: AgentHostOs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
    };
}


