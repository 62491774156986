/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentFileThumbnail
 */
export interface AttachmentFileThumbnail {
    /**
     * 
     * @type {string}
     * @memberof AttachmentFileThumbnail
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFileThumbnail
     */
    url?: string;
}

export function AttachmentFileThumbnailFromJSON(json: any): AttachmentFileThumbnail {
    return AttachmentFileThumbnailFromJSONTyped(json, false);
}

export function AttachmentFileThumbnailFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentFileThumbnail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function AttachmentFileThumbnailToJSON(value?: AttachmentFileThumbnail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content_type': value.contentType,
        'url': value.url,
    };
}


