/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SecurityPostureLevel {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    Onboarding = 'onboarding',
    Unknown = 'unknown'
}

export function SecurityPostureLevelFromJSON(json: any): SecurityPostureLevel {
    return SecurityPostureLevelFromJSONTyped(json, false);
}

export function SecurityPostureLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityPostureLevel {
    return json as SecurityPostureLevel;
}

export function SecurityPostureLevelToJSON(value?: SecurityPostureLevel | null): any {
    return value as any;
}

