import React from 'react';
import Gauge from './Gauge';
import { useQueryRiskScoreDetails } from '../../Global/TopNav/utils';
import {
  ffBenchmarkRiskScore,
  isFeatureEnabled,
} from '../../../public/js/features';

const Gauges = () => {
  const { data } = useQueryRiskScoreDetails();

  const riskScore = React.useMemo(() => {
    return {
      currentScore: data?.latest?.score,
      industryScore: data?.industry?.score,
      unresolved: data?.unresolvedRisks?.count,
    };
  }, [data]);

  return (
    <div className='row'>
      <Gauge
        gaugeId={'current-risk-score-gauge'}
        title={'Current Risk Score'}
        tooltip={'This value adjusts automatically.'}
        scale={10}
        fractionDigits={1}
        value={riskScore.currentScore}
      />
      <Gauge
        gaugeId={
          isFeatureEnabled(ffBenchmarkRiskScore)
            ? 'benchmark-risk-score-gauge'
            : 'industry-risk-score-gauge'
        }
        title={
          isFeatureEnabled(ffBenchmarkRiskScore)
            ? 'Benchmark Risk Score'
            : 'Industry Risk Scores'
        }
        tooltip={
          'Your score compared to other Arctic Wolf customers in your region, updated daily.'
        }
        scale={10}
        fractionDigits={1}
        value={riskScore.industryScore}
      />
      <Gauge
        gaugeId={'unresolved-risks-gauge'}
        title={'Unresolved Risks'}
        tooltip={
          'The current number of active medium to critical severity vulnerabilities in the network, updated automatically.'
        }
        scale={1}
        fractionDigits={0}
        value={riskScore.unresolved}
      />
    </div>
  );
};

export default Gauges;
