/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkInterface,
    NetworkInterfaceFromJSON,
    NetworkInterfaceFromJSONTyped,
    NetworkInterfaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface AgentHostNetwork
 */
export interface AgentHostNetwork {
    /**
     * 
     * @type {Array<NetworkInterface>}
     * @memberof AgentHostNetwork
     */
    interfaces?: Array<NetworkInterface>;
}

export function AgentHostNetworkFromJSON(json: any): AgentHostNetwork {
    return AgentHostNetworkFromJSONTyped(json, false);
}

export function AgentHostNetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentHostNetwork {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interfaces': !exists(json, 'interfaces') ? undefined : ((json['interfaces'] as Array<any>).map(NetworkInterfaceFromJSON)),
    };
}

export function AgentHostNetworkToJSON(value?: AgentHostNetwork | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interfaces': value.interfaces === undefined ? undefined : ((value.interfaces as Array<any>).map(NetworkInterfaceToJSON)),
    };
}


