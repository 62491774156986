/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ScanEvaBatch
 */
export interface ScanEvaBatch {
  /**
   *
   * @type {string}
   * @memberof ScanEvaBatch
   */
  groupID?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ScanEvaBatch
   */
  targets?: Array<string>;
}

export function ScanEvaBatchFromJSON(json: any): ScanEvaBatch {
  return ScanEvaBatchFromJSONTyped(json, false);
}

export function ScanEvaBatchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScanEvaBatch {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupID: !exists(json, 'groupID') ? undefined : json['groupID'],
    targets: !exists(json, 'targets') ? undefined : json['targets'],
  };
}

export function ScanEvaBatchToJSON(value?: ScanEvaBatch | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groupID: value.groupID,
    targets: value.targets,
  };
}
