/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ContactEmail,
  ContactEmailFromJSON,
  ContactEmailFromJSONTyped,
  ContactEmailToJSON,
  ContactPhone,
  ContactPhoneFromJSON,
  ContactPhoneFromJSONTyped,
  ContactPhoneToJSON,
} from './';

/**
 *
 * @export
 * @interface CommunicationPreference
 */
export interface CommunicationPreference {
  /**
   *
   * @type {string}
   * @memberof CommunicationPreference
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CommunicationPreference
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof CommunicationPreference
   */
  type?: CommunicationPreferenceTypeEnum;
  /**
   *
   * @type {ContactEmail}
   * @memberof CommunicationPreference
   */
  email?: ContactEmail;
  /**
   *
   * @type {ContactPhone}
   * @memberof CommunicationPreference
   */
  phone?: ContactPhone;
}

/**
 * @export
 * @enum {string}
 */
export enum CommunicationPreferenceTypeEnum {
  EmailTo = 'email_to',
  EmailCc = 'email_cc',
  Phone = 'phone',
}

export function CommunicationPreferenceFromJSON(
  json: any
): CommunicationPreference {
  return CommunicationPreferenceFromJSONTyped(json, false);
}

export function CommunicationPreferenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunicationPreference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    type: !exists(json, 'type') ? undefined : json['type'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
  };
}

export function CommunicationPreferenceToJSON(
  value?: CommunicationPreference | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    type: value.type,
    email: value.email,
    phone: value.phone,
  };
}
