/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CSPMObject
 */
export interface CSPMObject {
  /**
   *
   * @type {string}
   * @memberof CSPMObject
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CSPMObject
   */
  accountId: string;
}

export function CSPMObjectFromJSON(json: any): CSPMObject {
  return CSPMObjectFromJSONTyped(json, false);
}

export function CSPMObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CSPMObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    accountId: json['accountId'],
  };
}

export function CSPMObjectToJSON(value?: CSPMObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    accountId: value.accountId,
  };
}
