import React from 'react';
import styled from 'styled-components';

import { DisplayTable } from '../../Reusables/DisplayTable';
import { RowTile } from '../../Reusables/RowTile';
import StatusIcon from '../../Reusables/StatusIcon';

import { QueuedScan } from '../../types/scanner';
import { parseSchedule } from '../../utils/parsers';
import { ipIsInRange } from '../../utils/ipUtils';

import { STATUS, StatusType } from '../../Constants/Labels';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Note:- If the scan has this schedule we can assume its the “default schedule”.
const DEFAULT_SCHEDULE_ID = 'd206a5ae-289c-4daa-8edb-b8db57658ed7';

const MANUALLY_ADDED = 'Manually Added';

const StyledStatus = styled(StatusIcon)`
  border-radius: 13px;
  line-height: 13px;
`;
StyledStatus.displayName = 'StyledStatus';

interface ToolTipLabelProps {
  text: string;
  options?: {};
}
const StyledToolTipText = ({ text, options }: ToolTipLabelProps) => (
  <Tooltip.Text
    style={{
      display: 'block',
      textAlign: 'left',
      ...options,
    }}
  >
    {text}
  </Tooltip.Text>
);

const headers: Array<string> = ['Host', 'Status', 'Last Scan', 'Scan Schedule'];

const FormattedSchedule: React.FC<{
  scan: QueuedScan;
}> = React.memo((props) => {
  const { schedule, target, scanReason } = props.scan;

  if (scanReason === MANUALLY_ADDED) {
    return <div>Manual Scan</div>;
  }

  if (schedule && schedule.id && schedule.id === DEFAULT_SCHEDULE_ID) {
    return <div>Default Schedule</div>;
  }

  if (!(schedule.windows || schedule.targets || schedule.targetGroups)) {
    return <div>Triggering schedule was deleted</div>;
  }

  const { intervals } = parseSchedule(schedule);

  const scheduleMatch = schedule.targets?.find((range) =>
    ipIsInRange(target, [range])
  );
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: '1em' }}>
        {intervals.join(',')} - {scheduleMatch}
      </div>

      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ marginLeft: '0.5em', alignSelf: 'center' }}
            cursor='pointer'
          />
        </Tooltip.Trigger>
        <Tooltip.Content side='top' maxWidth='25rem'>
          <StyledToolTipText
            text='Triggering Schedule'
            options={{ fontWeight: 'bold', marginBottom: '10px' }}
          />
          <StyledToolTipText
            text='Schedule:'
            options={{ fontWeight: 'bold', marginBottom: '5px' }}
          />
          <StyledToolTipText
            text={intervals.join(',')}
            options={{ marginBottom: '10px' }}
          />
          <StyledToolTipText
            text='Targets:'
            options={{ fontWeight: 'bold', marginBottom: '5px' }}
          />
          <StyledToolTipText text={schedule.targets.join(', ')} />
        </Tooltip.Content>
      </Tooltip.Root>
    </div>
  );
});

const ScanningQueue: React.FC<{ scans: QueuedScan[] }> = (props) => {
  const getStatusIcon = (text: StatusType) => {
    switch (text.toLowerCase()) {
      case STATUS.DEGRADED.toLowerCase():
      case STATUS.DISCONNECTED.toLowerCase():
      case STATUS.NO_BRUTE_FORCE.toLowerCase():
      case STATUS.NO_CGI.toLowerCase():
      case STATUS.NOT_CONFIGURED.toLowerCase():
      case STATUS.NOT_SCANNING.toLowerCase():
        return 'fa-times';
      case STATUS.CONNECTED.toLowerCase():
      case STATUS.RUNNING.toLowerCase():
      case STATUS.SCANNING.toLowerCase():
      case STATUS.IDLE.toLowerCase():
        return 'fa-check';
      case STATUS.LOADING.toLowerCase():
        return 'fa-spinner';
      case STATUS.SCHEDULED.toLowerCase():
        return 'fa-calendar';
      case STATUS.WAITING.toLowerCase():
        return 'fa-clock-o';
      default:
        return 'fa-bullseye';
    }
  };
  const customSort = (a: QueuedScan, b: QueuedScan) => {
    if (a.status.toString() < b.status.toString()) return 1;
    else if (a.status.toString() > b.status.toString()) return -1;
    else return 0;
  };

  const scans = props.scans
    .sort(customSort)
    .map((scan) => [
      scan.target,
      <StyledStatus text={scan.status} icon={getStatusIcon(scan.status)} />,
      scan.lastScan,
      <FormattedSchedule scan={scan} />,
    ]);

  return (
    <RowTile
      id='scanning-queue'
      title='Scanning Queue'
      description='The list of current and future scan targets in your network.'
      newStyle
    >
      <DisplayTable
        headers={headers}
        rows={scans}
        defaultSortColumn={1}
        reverseSort
      />
    </RowTile>
  );
};

export default ScanningQueue;
