/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CST,
    CSTFromJSON,
    CSTFromJSONTyped,
    CSTToJSON,
    OnboardingStatus,
    OnboardingStatusFromJSON,
    OnboardingStatusFromJSONTyped,
    OnboardingStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * defined in the Customer Directory, this changes the behaviour of the UI when requesting tickets. It corresponds to a security setting in Zendesk that's per-organization.
     * @type {boolean}
     * @memberof Organization
     */
    zendeskAllUsersCanComment: boolean;
    /**
     * the "customer ID" for the organization
     * @type {string}
     * @memberof Organization
     */
    customerID: string;
    /**
     * 
     * @type {OnboardingStatus}
     * @memberof Organization
     */
    onboardingStatus: OnboardingStatus;
    /**
     * the display name of the organization
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * zendesk ID og the organization
     * @type {string}
     * @memberof Organization
     */
    zendeskID: string;
    /**
     * a list of CSTs for the organization
     * @type {Array<CST>}
     * @memberof Organization
     */
    csts?: Array<CST>;
    /**
     * feature flags for the organization
     * @type {object}
     * @memberof Organization
     */
    flags: object;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zendeskAllUsersCanComment': json['zendeskAllUsersCanComment'],
        'customerID': json['customerID'],
        'onboardingStatus': OnboardingStatusFromJSON(json['onboardingStatus']),
        'name': json['name'],
        'zendeskID': json['zendeskID'],
        'csts': !exists(json, 'csts') ? undefined : ((json['csts'] as Array<any>).map(CSTFromJSON)),
        'flags': json['flags'],
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zendeskAllUsersCanComment': value.zendeskAllUsersCanComment,
        'customerID': value.customerID,
        'onboardingStatus': OnboardingStatusToJSON(value.onboardingStatus),
        'name': value.name,
        'zendeskID': value.zendeskID,
        'csts': value.csts === undefined ? undefined : ((value.csts as Array<any>).map(CSTToJSON)),
        'flags': value.flags,
    };
}


