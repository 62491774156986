/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReportURL
 */
export interface ReportURL {
  /**
   *
   * @type {string}
   * @memberof ReportURL
   */
  url: string;
}

export function ReportURLFromJSON(json: any): ReportURL {
  return ReportURLFromJSONTyped(json, false);
}

export function ReportURLFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportURL {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: json['url'],
  };
}

export function ReportURLToJSON(value?: ReportURL | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
  };
}
