/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CloudSensorDetailStatus,
    CloudSensorDetailStatusFromJSON,
    CloudSensorDetailStatusFromJSONTyped,
    CloudSensorDetailStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface CloudSensor
 */
export interface CloudSensor {
    /**
     * a verbatim response from Console
     * @type {object}
     * @memberof CloudSensor
     */
    secrets: object;
    /**
     * a verbatim response from Console
     * @type {string}
     * @memberof CloudSensor
     */
    ticketID: string;
    /**
     * 
     * @type {CloudSensorDetailStatus}
     * @memberof CloudSensor
     */
    status: CloudSensorDetailStatus;
}

export function CloudSensorFromJSON(json: any): CloudSensor {
    return CloudSensorFromJSONTyped(json, false);
}

export function CloudSensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudSensor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'secrets': json['secrets'],
        'ticketID': json['ticketID'],
        'status': CloudSensorDetailStatusFromJSON(json['status']),
    };
}

export function CloudSensorToJSON(value?: CloudSensor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'secrets': value.secrets,
        'ticketID': value.ticketID,
        'status': CloudSensorDetailStatusToJSON(value.status),
    };
}


