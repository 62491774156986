/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CloudSensorRow,
    CloudSensorRowFromJSON,
    CloudSensorRowFromJSONTyped,
    CloudSensorRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface CloudSensorPage
 */
export interface CloudSensorPage {
    /**
     * the total number of items, across all pages matching the query
     * @type {number}
     * @memberof CloudSensorPage
     */
    total: number;
    /**
     * 
     * @type {Array<CloudSensorRow>}
     * @memberof CloudSensorPage
     */
    items: Array<CloudSensorRow>;
}

export function CloudSensorPageFromJSON(json: any): CloudSensorPage {
    return CloudSensorPageFromJSONTyped(json, false);
}

export function CloudSensorPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudSensorPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(CloudSensorRowFromJSON)),
    };
}

export function CloudSensorPageToJSON(value?: CloudSensorPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': ((value.items as Array<any>).map(CloudSensorRowToJSON)),
    };
}


