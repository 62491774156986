/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObservationPipelineDetails
 */
export interface ObservationPipelineDetails {
    /**
     * number of analyzed events
     * @type {number}
     * @memberof ObservationPipelineDetails
     */
    analyzed?: number;
    /**
     * number of events classified as interesting; a subset of all analyzed events
     * @type {number}
     * @memberof ObservationPipelineDetails
     */
    events?: number;
    /**
     * number of interesting events that were investigated
     * @type {number}
     * @memberof ObservationPipelineDetails
     */
    investigations?: number;
    /**
     * number of escalated alerts
     * @type {number}
     * @memberof ObservationPipelineDetails
     */
    alerts?: number;
}

export function ObservationPipelineDetailsFromJSON(json: any): ObservationPipelineDetails {
    return ObservationPipelineDetailsFromJSONTyped(json, false);
}

export function ObservationPipelineDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObservationPipelineDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analyzed': !exists(json, 'analyzed') ? undefined : json['analyzed'],
        'events': !exists(json, 'events') ? undefined : json['events'],
        'investigations': !exists(json, 'investigations') ? undefined : json['investigations'],
        'alerts': !exists(json, 'alerts') ? undefined : json['alerts'],
    };
}

export function ObservationPipelineDetailsToJSON(value?: ObservationPipelineDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analyzed': value.analyzed,
        'events': value.events,
        'investigations': value.investigations,
        'alerts': value.alerts,
    };
}


