/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DeleteIvaSchedule,
  DeleteIvaScheduleFromJSON,
  DeleteIvaScheduleFromJSONTyped,
  DeleteIvaScheduleToJSON,
} from './';

/**
 * Input to delete iva scan schedules
 * @export
 * @interface DeleteIvaSchedulesInput
 */
export interface DeleteIvaSchedulesInput {
  /**
   *
   * @type {Array<DeleteIvaSchedule>}
   * @memberof DeleteIvaSchedulesInput
   */
  _delete?: Array<DeleteIvaSchedule>;
}

export function DeleteIvaSchedulesInputFromJSON(
  json: any
): DeleteIvaSchedulesInput {
  return DeleteIvaSchedulesInputFromJSONTyped(json, false);
}

export function DeleteIvaSchedulesInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteIvaSchedulesInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _delete: !exists(json, 'delete')
      ? undefined
      : (json['delete'] as Array<any>).map(DeleteIvaScheduleFromJSON),
  };
}

export function DeleteIvaSchedulesInputToJSON(
  value?: DeleteIvaSchedulesInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    delete:
      value._delete === undefined
        ? undefined
        : (value._delete as Array<any>).map(DeleteIvaScheduleToJSON),
  };
}
