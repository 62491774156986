/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CronWindow,
  CronWindowFromJSON,
  CronWindowFromJSONTyped,
  CronWindowToJSON,
  Frequency,
  FrequencyFromJSON,
  FrequencyFromJSONTyped,
  FrequencyToJSON,
  ScanType,
  ScanTypeFromJSON,
  ScanTypeFromJSONTyped,
  ScanTypeToJSON,
  ScheduleStatus,
  ScheduleStatusFromJSON,
  ScheduleStatusFromJSONTyped,
  ScheduleStatusToJSON,
  SourceType,
  SourceTypeFromJSON,
  SourceTypeFromJSONTyped,
  SourceTypeToJSON,
  Target,
  TargetFromJSON,
  TargetFromJSONTyped,
  TargetToJSON,
} from './';

/**
 * a high-level overview of a single scan schedule suitable for rendering in a table
 * @export
 * @interface ScheduleRow
 */
export interface ScheduleRow {
  /**
   *
   * @type {string}
   * @memberof ScheduleRow
   */
  id: string;
  /**
   *
   * @type {SourceType}
   * @memberof ScheduleRow
   */
  source: SourceType;
  /**
   * name of the schedule
   * @type {string}
   * @memberof ScheduleRow
   */
  name: string;
  /**
   * description of the schedule
   * @type {string}
   * @memberof ScheduleRow
   */
  description: string;
  /**
   * if scanning is enabled
   * @type {boolean}
   * @memberof ScheduleRow
   */
  scanningEnabled: boolean;
  /**
   *
   * @type {ScheduleStatus}
   * @memberof ScheduleRow
   */
  status: ScheduleStatus;
  /**
   *
   * @type {Array<CronWindow>}
   * @memberof ScheduleRow
   */
  windows: Array<CronWindow>;
  /**
   *
   * @type {string}
   * @memberof ScheduleRow
   */
  timezone: string;
  /**
   *
   * @type {Frequency}
   * @memberof ScheduleRow
   */
  frequency: Frequency;
  /**
   * date of last scan
   * @type {string}
   * @memberof ScheduleRow
   */
  lastScan: string;
  /**
   * date of next scan
   * @type {string}
   * @memberof ScheduleRow
   */
  nextScan: string;
  /**
   *
   * @type {Array<Target>}
   * @memberof ScheduleRow
   */
  targets: Array<Target>;
  /**
   *
   * @type {Array<Target>}
   * @memberof ScheduleRow
   */
  scheduledTargets?: Array<Target>;
  /**
   *
   * @type {Array<ScanType>}
   * @memberof ScheduleRow
   */
  scanTypes: Array<ScanType>;
  /**
   *
   * @type {string}
   * @memberof ScheduleRow
   */
  scannerId: string;
  /**
   *
   * @type {number}
   * @memberof ScheduleRow
   */
  priority: number;
  /**
   *
   * @type {boolean}
   * @memberof ScheduleRow
   */
  autoEnrollNewClients?: boolean;
}

export function ScheduleRowFromJSON(json: any): ScheduleRow {
  return ScheduleRowFromJSONTyped(json, false);
}

export function ScheduleRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScheduleRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    source: SourceTypeFromJSON(json['source']),
    name: json['name'],
    description: json['description'],
    scanningEnabled: json['scanningEnabled'],
    status: ScheduleStatusFromJSON(json['status']),
    windows: (json['windows'] as Array<any>).map(CronWindowFromJSON),
    timezone: json['timezone'],
    frequency: FrequencyFromJSON(json['frequency']),
    lastScan: json['lastScan'],
    nextScan: json['nextScan'],
    targets: (json['targets'] as Array<any>).map(TargetFromJSON),
    scheduledTargets: !exists(json, 'scheduledTargets')
      ? undefined
      : (json['scheduledTargets'] as Array<any>).map(TargetFromJSON),
    scanTypes: (json['scanTypes'] as Array<any>).map(ScanTypeFromJSON),
    scannerId: json['scannerId'],
    priority: json['priority'],
    autoEnrollNewClients: !exists(json, 'autoEnrollNewClients')
      ? undefined
      : json['autoEnrollNewClients'],
  };
}

export function ScheduleRowToJSON(value?: ScheduleRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    source: SourceTypeToJSON(value.source),
    name: value.name,
    description: value.description,
    scanningEnabled: value.scanningEnabled,
    status: ScheduleStatusToJSON(value.status),
    windows: (value.windows as Array<any>).map(CronWindowToJSON),
    timezone: value.timezone,
    frequency: FrequencyToJSON(value.frequency),
    lastScan: value.lastScan,
    nextScan: value.nextScan,
    targets: (value.targets as Array<any>).map(TargetToJSON),
    scheduledTargets:
      value.scheduledTargets === undefined
        ? undefined
        : (value.scheduledTargets as Array<any>).map(TargetToJSON),
    scanTypes: (value.scanTypes as Array<any>).map(ScanTypeToJSON),
    scannerId: value.scannerId,
    priority: value.priority,
    autoEnrollNewClients: value.autoEnrollNewClients,
  };
}
