/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ScanType
 */
export interface ScanType {
  /**
   *
   * @type {string}
   * @memberof ScanType
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof ScanType
   */
  enabled: boolean;
}

export function ScanTypeFromJSON(json: any): ScanType {
  return ScanTypeFromJSONTyped(json, false);
}

export function ScanTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScanType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    enabled: json['enabled'],
  };
}

export function ScanTypeToJSON(value?: ScanType | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    enabled: value.enabled,
  };
}
