/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the status of a given scanner
 * @export
 * @enum {string}
 */
export enum ScannerStatus {
    Disconnected = 'disconnected',
    Degraded = 'degraded',
    AwaitingActivation = 'awaitingActivation',
    Scanning = 'scanning',
    Idle = 'idle',
    Connected = 'connected',
    NotAvailable = 'notAvailable'
}

export function ScannerStatusFromJSON(json: any): ScannerStatus {
    return ScannerStatusFromJSONTyped(json, false);
}

export function ScannerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScannerStatus {
    return json as ScannerStatus;
}

export function ScannerStatusToJSON(value?: ScannerStatus | null): any {
    return value as any;
}

