/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AttachmentFile,
  AttachmentFileFromJSON,
  AttachmentFileFromJSONTyped,
  AttachmentFileToJSON,
  TicketComment,
  TicketCommentFromJSON,
  TicketCommentFromJSONTyped,
  TicketCommentToJSON,
  TicketStatus,
  TicketStatusFromJSON,
  TicketStatusFromJSONTyped,
  TicketStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TicketWithComments
 */
export interface TicketWithComments {
  /**
   *
   * @type {number}
   * @memberof TicketWithComments
   */
  id: number;
  /**
   *
   * @type {TicketStatus}
   * @memberof TicketWithComments
   */
  status: TicketStatus;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  requester: string;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof TicketWithComments
   */
  canSolve: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TicketWithComments
   */
  canReply: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketWithComments
   */
  type: string;
  /**
   * array of usernames
   * @type {Array<string>}
   * @memberof TicketWithComments
   */
  cc: Array<string>;
  /**
   *
   * @type {Array<TicketComment>}
   * @memberof TicketWithComments
   */
  comments?: Array<TicketComment>;
  /**
   *
   * @type {Array<AttachmentFile>}
   * @memberof TicketWithComments
   */
  attachments?: Array<AttachmentFile>;
}

export function TicketWithCommentsFromJSON(json: any): TicketWithComments {
  return TicketWithCommentsFromJSONTyped(json, false);
}

export function TicketWithCommentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketWithComments {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: TicketStatusFromJSON(json['status']),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    requester: json['requester'],
    subject: json['subject'],
    description: json['description'],
    canSolve: json['can_solve'],
    canReply: json['can_reply'],
    type: json['type'],
    cc: json['cc'],
    comments: !exists(json, 'comments')
      ? undefined
      : (json['comments'] as Array<any>).map(TicketCommentFromJSON),
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentFileFromJSON),
  };
}

export function TicketWithCommentsToJSON(
  value?: TicketWithComments | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: TicketStatusToJSON(value.status),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    requester: value.requester,
    subject: value.subject,
    description: value.description,
    can_solve: value.canSolve,
    can_reply: value.canReply,
    type: value.type,
    cc: value.cc,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(TicketCommentToJSON),
    attachments:
      value.attachments === undefined
        ? undefined
        : (value.attachments as Array<any>).map(AttachmentFileToJSON),
  };
}
