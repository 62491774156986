/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input to request an agent scan
 * @export
 * @interface ScanAgentNowInput
 */
export interface ScanAgentNowInput {
  /**
   *
   * @type {Array<string>}
   * @memberof ScanAgentNowInput
   */
  groupIds?: Array<string>;
}

export function ScanAgentNowInputFromJSON(json: any): ScanAgentNowInput {
  return ScanAgentNowInputFromJSONTyped(json, false);
}

export function ScanAgentNowInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScanAgentNowInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupIds: !exists(json, 'groupIds') ? undefined : json['groupIds'],
  };
}

export function ScanAgentNowInputToJSON(value?: ScanAgentNowInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groupIds: value.groupIds,
  };
}
