/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CloudSensorStatus,
  CloudSensorStatusFromJSON,
  CloudSensorStatusFromJSONTyped,
  CloudSensorStatusToJSON,
} from './';

/**
 * a high-level overview of a single cloud sensor suitable for rendering in a table
 * @export
 * @interface CloudSensorRow
 */
export interface CloudSensorRow {
  /**
   * a human-readable name for the sensor that's meaningful to the customer
   * @type {string}
   * @memberof CloudSensorRow
   */
  name: string;
  /**
   * the ID of the deployment as reported by the customer directory; usually consists of the customer ID followed by a number
   * @type {string}
   * @memberof CloudSensorRow
   */
  deploymentID: string;
  /**
   *
   * @type {CloudSensorStatus}
   * @memberof CloudSensorRow
   */
  status: CloudSensorStatus;
  /**
   * the service monitored by this cloud sensor'
   * @type {string}
   * @memberof CloudSensorRow
   */
  vendor: string;
  /**
   * Cloud sensor type
   * @type {string}
   * @memberof CloudSensorRow
   */
  type?: string;
}

export function CloudSensorRowFromJSON(json: any): CloudSensorRow {
  return CloudSensorRowFromJSONTyped(json, false);
}

export function CloudSensorRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CloudSensorRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    deploymentID: json['deploymentID'],
    status: CloudSensorStatusFromJSON(json['status']),
    vendor: json['vendor'],
    type: !exists(json, 'type') ? undefined : json['type'],
  };
}

export function CloudSensorRowToJSON(value?: CloudSensorRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    deploymentID: value.deploymentID,
    status: CloudSensorStatusToJSON(value.status),
    vendor: value.vendor,
    type: value.type,
  };
}
