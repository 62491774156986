/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for enable/disabling iva scan schedules
 * @export
 * @interface ManageIvaScheduleState
 */
export interface ManageIvaScheduleState {
  /**
   *
   * @type {string}
   * @memberof ManageIvaScheduleState
   */
  scannerId: string;
  /**
   *
   * @type {string}
   * @memberof ManageIvaScheduleState
   */
  scheduleId: string;
  /**
   *
   * @type {boolean}
   * @memberof ManageIvaScheduleState
   */
  scanningDisabled: boolean;
}

export function ManageIvaScheduleStateFromJSON(
  json: any
): ManageIvaScheduleState {
  return ManageIvaScheduleStateFromJSONTyped(json, false);
}

export function ManageIvaScheduleStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManageIvaScheduleState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scannerId: json['scannerId'],
    scheduleId: json['scheduleId'],
    scanningDisabled: json['scanningDisabled'],
  };
}

export function ManageIvaScheduleStateToJSON(
  value?: ManageIvaScheduleState | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scannerId: value.scannerId,
    scheduleId: value.scheduleId,
    scanningDisabled: value.scanningDisabled,
  };
}
