/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentContainment,
    AgentContainmentFromJSON,
    AgentContainmentFromJSONTyped,
    AgentContainmentToJSON,
    AgentHost,
    AgentHostFromJSON,
    AgentHostFromJSONTyped,
    AgentHostToJSON,
} from './';

/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    online: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    inactive: boolean;
    /**
     * A date conveyed in RFC3339 format
     * @type {Date}
     * @memberof Agent
     */
    lastReported: Date;
    /**
     * 
     * @type {AgentContainment}
     * @memberof Agent
     */
    containment: AgentContainment;
    /**
     * 
     * @type {AgentHost}
     * @memberof Agent
     */
    host?: AgentHost;
}

export function AgentFromJSON(json: any): Agent {
    return AgentFromJSONTyped(json, false);
}

export function AgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'online': json['online'],
        'inactive': json['inactive'],
        'lastReported': (new Date(json['lastReported'])),
        'containment': AgentContainmentFromJSON(json['containment']),
        'host': !exists(json, 'host') ? undefined : AgentHostFromJSON(json['host']),
    };
}

export function AgentToJSON(value?: Agent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'online': value.online,
        'inactive': value.inactive,
        'lastReported': (value.lastReported.toISOString()),
        'containment': AgentContainmentToJSON(value.containment),
        'host': AgentHostToJSON(value.host),
    };
}


