// Utility Functions
import { escapeVal, exportCSVFile, getLocalTimeNoSpace } from '../../utils';
import { parseRawData } from '../../utils/parsers';
import {
  isFeatureEnabled,
  ffBenchmarkRiskScore,
} from '../../../public/js/features';

const actionListRawDataArr = [];
const newRisksRawDataArr = [];
const highRiskMitigationsRawDataArr = [];
const acceptedListRawDataArr = [];
const falsePosRawDataArr = [];
const newScansRawDataArr = [];

export function onFalsePositivesCSVbtnClick() {
  if (!document.getElementById('export-false-positives-to-csv').disabled) {
    const formattedDataArr = falsePosRawDataArr.map((item) => ({
      host: escapeVal(item.host),
      issue: escapeVal(item.issue),
      riskScore: escapeVal(item.riskscore),
      state: escapeVal(item.state),
      lastStateChange: escapeVal(item.statets),
      user: escapeVal(item.stateuser),
      reason: escapeVal(item.statereason),
    }));
    const headers = {
      host: 'Host',
      issue: 'Issue',
      riskScore: 'Risk Score',
      state: 'State',
      lastStateChange: 'Last State Change',
      user: 'User',
      reason: 'Reason',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onNewScansCSVbtnClick() {
  if (!document.getElementById('export-new-scans-to-csv').disabled) {
    var formattedDataArr = [];
    newScansRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        cve: escapeVal(item.cve),
        risk: escapeVal(item.cvss),
      });
    });
    var headers = {
      name: 'Name',
      cve: 'CVE',
      risk: 'Risk',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

function onAcceptedRisksCSVbtnClick() {
  if (!document.getElementById('export-accepted-risks-to-csv').disabled) {
    var formattedDataArr = [];
    acceptedListRawDataArr.forEach((item) => {
      formattedDataArr.push({
        host: escapeVal(item.host),
        issue: escapeVal(item.issue),
        riskScore: escapeVal(item.riskscore),
        state: escapeVal(item.state),
        lastStateChange: escapeVal(item.statets),
        user: escapeVal(item.stateuser),
        reason: escapeVal(item.statereason),
      });
    });
    var headers = {
      host: 'Host',
      issue: 'Issue',
      riskScore: 'Risk Score',
      state: 'State',
      lastStateChange: 'Last State Change',
      user: 'User',
      reason: 'Reason',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

function onHighRiskMitigationsCSVbtnClick() {
  if (
    !document.getElementById('export-high-risk-mitigations-to-csv').disabled
  ) {
    var formattedDataArr = [];
    highRiskMitigationsRawDataArr.forEach((item) => {
      formattedDataArr.push({
        host: escapeVal(item[0]),
        issue: escapeVal(item[1]),
        riskScore: escapeVal(item[2]),
        lastDetected: escapeVal(item[3]),
      });
    });
    var headers = {
      host: 'Host',
      issue: 'Issue',
      riskScore: 'Risk Score',
      lastDetected: 'Last Detected',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

function onActionListCSVbtnClick() {
  if (!document.getElementById('export-action-list-to-csv').disabled) {
    var formattedDataArr = [];
    actionListRawDataArr.forEach((item) => {
      formattedDataArr.push({
        host: escapeVal(item.host),
        issue: escapeVal(item.issue),
        riskScore: escapeVal(item.riskscore),
        action: escapeVal(item.action),
        state: escapeVal(item.state),
        details: escapeVal(item.details),
        identifier: escapeVal(item.identifier),
        impact: escapeVal(item.impact),
        lastUpdated: escapeVal(item.lastidentified),
        extra: escapeVal(item.extra),
        status: escapeVal(item.status),
      });
    });
    var headers = {
      host: escapeVal('IP (DNS, Netbios)'),
      issue: 'Issue',
      riskScore: 'Risk Score',
      action: 'Action',
      state: 'State',
      details: 'Details',
      identifier: 'Identifier',
      impact: 'Impact',
      lastUpdated: 'Last Updated',
      extra: 'Extra',
      status: 'Status',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onNewRisksCSVbtnClick() {
  if (!document.getElementById('export-new-risks-to-csv').disabled) {
    var formattedDataArr = [];
    newRisksRawDataArr.forEach((item) => {
      formattedDataArr.push({
        host: escapeVal(item.host),
        name: escapeVal(item.reportname),
        riskScore: escapeVal(item.cvss),
        firstIdentified: escapeVal(item.firstidentified),
        lastScan: escapeVal(item.lastscan),
      });
    });
    var headers = {
      host: 'Host',
      name: 'Name',
      riskScore: 'Risk Score',
      firstIdentified: 'First Identified',
      lastScan: 'Last Scan',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onRiskTrendsCSVbtnClick(riskTrendsRawDataArr) {
  if (!document.getElementById('export-risk-trends-to-csv').disabled) {
    var formattedDataArr = [];
    riskTrendsRawDataArr.forEach((item) => {
      formattedDataArr.push({
        date: escapeVal(item.date),
        yourRiskScore: escapeVal(item.yourRiskScore),
        indRiskScore: escapeVal(item.indRiskScore),
        target: escapeVal(item.target),
      });
    });
    var headers = {
      date: 'Date',
      yourRiskScore: 'Your Risk Score',
      indRiskScore: isFeatureEnabled(ffBenchmarkRiskScore)
        ? 'Benchmark Risk Score'
        : 'Industry Risk Score',
      target: 'Target',
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function initRiskTrendsCSV(riskTrendsRawDataArr) {
  var fileTitle = 'risk-trends';
  var csv = onRiskTrendsCSVbtnClick(riskTrendsRawDataArr);
  return exportCSVFile(csv, fileTitle);
}

export function initRiskViewCSV() {
  var localTime = getLocalTimeNoSpace();
  var zipObj = [];
  zipObj.push({
    file: 'risk-trends_' + localTime + '.csv',
    data: onRiskTrendsCSVbtnClick(),
  });
  zipObj.push({
    file: 'action-list_' + localTime + '.csv',
    data: onActionListCSVbtnClick(),
  });
  zipObj.push({
    file: 'high-risk-mitigations_' + localTime + '.csv',
    data: onHighRiskMitigationsCSVbtnClick(),
  });
  zipObj.push({
    file: 'accepted-risks_' + localTime + '.csv',
    data: onAcceptedRisksCSVbtnClick(),
  });
  zipObj.push({
    file: 'false-positives_' + localTime + '.csv',
    data: onFalsePositivesCSVbtnClick(),
  });
  zipObj.push({
    file: 'new-risks_' + localTime + '.csv',
    data: onNewRisksCSVbtnClick(),
  });
  zipObj.push({
    file: 'new-scans_' + localTime + '.csv',
    data: onNewScansCSVbtnClick(),
  });
  zipObj.push({
    file: 'support-notifications_' + localTime + '.csv',
    data: onNewScansCSVbtnClick(),
  });
  return zipObj;
}
