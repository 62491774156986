/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StructureObject
 */
export interface StructureObject {
    /**
     * 
     * @type {string}
     * @memberof StructureObject
     */
    dns: string;
    /**
     * Either a single ipv4 address or a range of addresses of the format 3.97.17.192 - 3.97.17.207.
     * @type {string}
     * @memberof StructureObject
     */
    subnet?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StructureObject
     */
    ports: Array<string>;
}

export function StructureObjectFromJSON(json: any): StructureObject {
    return StructureObjectFromJSONTyped(json, false);
}

export function StructureObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructureObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dns': json['dns'],
        'subnet': !exists(json, 'subnet') ? undefined : json['subnet'],
        'ports': json['ports'],
    };
}

export function StructureObjectToJSON(value?: StructureObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dns': value.dns,
        'subnet': value.subnet,
        'ports': value.ports,
    };
}


