/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ContactEmail,
  ContactEmailFromJSON,
  ContactEmailFromJSONTyped,
  ContactEmailToJSON,
  ContactPhone,
  ContactPhoneFromJSON,
  ContactPhoneFromJSONTyped,
  ContactPhoneToJSON,
  OrganizationContactAccess,
  OrganizationContactAccessFromJSON,
  OrganizationContactAccessFromJSONTyped,
  OrganizationContactAccessToJSON,
  OrganizationContactContactTypes,
  OrganizationContactContactTypesFromJSON,
  OrganizationContactContactTypesFromJSONTyped,
  OrganizationContactContactTypesToJSON,
  OrganizationContactWork,
  OrganizationContactWorkFromJSON,
  OrganizationContactWorkFromJSONTyped,
  OrganizationContactWorkToJSON,
} from './';

/**
 *
 * @export
 * @interface OrganizationContact
 */
export interface OrganizationContact {
  /**
   * Unique identifier for the contact
   * @type {number}
   * @memberof OrganizationContact
   */
  id: number;
  /**
   * First name
   * @type {string}
   * @memberof OrganizationContact
   */
  firstName: string;
  /**
   * Last name
   * @type {string}
   * @memberof OrganizationContact
   */
  lastName: string;
  /**
   * Title
   * @type {string}
   * @memberof OrganizationContact
   */
  title: string;
  /**
   *
   * @type {Array<ContactEmail>}
   * @memberof OrganizationContact
   */
  emailAddresses: Array<ContactEmail>;
  /**
   *
   * @type {Array<ContactPhone>}
   * @memberof OrganizationContact
   */
  phoneNumbers?: Array<ContactPhone>;
  /**
   *
   * @type {OrganizationContactWork}
   * @memberof OrganizationContact
   */
  work: OrganizationContactWork;
  /**
   *
   * @type {OrganizationContactContactTypes}
   * @memberof OrganizationContact
   */
  contactTypes?: OrganizationContactContactTypes;
  /**
   *
   * @type {OrganizationContactAccess}
   * @memberof OrganizationContact
   */
  access?: OrganizationContactAccess;
}

export function OrganizationContactFromJSON(json: any): OrganizationContact {
  return OrganizationContactFromJSONTyped(json, false);
}

export function OrganizationContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationContact {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    title: json['title'],
    emailAddresses: (json['emailAddresses'] as Array<any>).map(
      ContactEmailFromJSON
    ),
    phoneNumbers: !exists(json, 'phoneNumbers')
      ? undefined
      : (json['phoneNumbers'] as Array<any>).map(ContactPhoneFromJSON),
    work: OrganizationContactWorkFromJSON(json['work']),
    contactTypes: !exists(json, 'contactTypes')
      ? undefined
      : OrganizationContactContactTypesFromJSON(json['contactTypes']),
    access: !exists(json, 'access')
      ? undefined
      : OrganizationContactAccessFromJSON(json['access']),
  };
}

export function OrganizationContactToJSON(
  value?: OrganizationContact | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    title: value.title,
    emailAddresses: (value.emailAddresses as Array<any>).map(
      ContactEmailToJSON
    ),
    phoneNumbers:
      value.phoneNumbers === undefined
        ? undefined
        : (value.phoneNumbers as Array<any>).map(ContactPhoneToJSON),
    work: OrganizationContactWorkToJSON(value.work),
    contactTypes: OrganizationContactContactTypesToJSON(value.contactTypes),
    access: OrganizationContactAccessToJSON(value.access),
  };
}
