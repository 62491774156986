/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input to request cancelling running scans
 * @export
 * @interface CancelEvaScansInput
 */
export interface CancelEvaScansInput {
  /**
   *
   * @type {Array<string>}
   * @memberof CancelEvaScansInput
   */
  groups: Array<string>;
  /**
   * optional timeout in seconds. Default 5 minutes, max 1 hour
   * @type {number}
   * @memberof CancelEvaScansInput
   */
  timeout?: number;
}

export function CancelEvaScansInputFromJSON(json: any): CancelEvaScansInput {
  return CancelEvaScansInputFromJSONTyped(json, false);
}

export function CancelEvaScansInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelEvaScansInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groups: json['groups'],
    timeout: !exists(json, 'timeout') ? undefined : json['timeout'],
  };
}

export function CancelEvaScansInputToJSON(
  value?: CancelEvaScansInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groups: value.groups,
    timeout: value.timeout,
  };
}
