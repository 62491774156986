/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Ticket, TicketFromJSON, TicketFromJSONTyped, TicketToJSON } from './';

/**
 *
 * @export
 * @interface TicketList
 */
export interface TicketList {
  /**
   *
   * @type {Array<Ticket>}
   * @memberof TicketList
   */
  tickets: Array<Ticket>;
  /**
   * a string (ew) containing the next page
   * @type {string}
   * @memberof TicketList
   */
  next: string | null;
}

export function TicketListFromJSON(json: any): TicketList {
  return TicketListFromJSONTyped(json, false);
}

export function TicketListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tickets: (json['tickets'] as Array<any>).map(TicketFromJSON),
    next: json['next'],
  };
}

export function TicketListToJSON(value?: TicketList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tickets: (value.tickets as Array<any>).map(TicketToJSON),
    next: value.next,
  };
}
