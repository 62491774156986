import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { iGetState } from '../configureStore';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { RisksParams } from '../types/risks';
import { CaseActionTypes } from './actionEnums';
import riskAPI from '../utils/riskAPI';
import { iRisk } from './riskReducer';
import { STATUS } from '../Constants/Risks';
import { getAuthToken } from '../utils/auth/authentication';

// ----- Action Constants -----
export const SET_ASSIGNED_RISKS = 'SET_ASSIGNED_RISKS';
export const UPDATE_RISK = 'UPDATE_RISK';
export const REMOVE_RISK = 'REMOVE_RISK';
export const SET_NOTES = 'SET_NOTES';
export const ADD_NOTE = 'ADD_NOTE';
export const SET_ACTION_LIST = 'SET_ACTION_LIST';
export const CLEAR_ASSIGNED_RISKS = 'CLEAR_ASSIGNED_RISKS';
export const SET_ASSIGNMENT = 'SET_ASSIGNMENT';
export const BULK_UPDATE_RISK = 'BULK_UPDATE_RISK';

// ----- Action Creators -----

// ----- Thunk Action Creators -----

export const getRiskNotesThunk =
  (riskId: string, riskType: string) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;
    return riskAPI
      .get(`/rootsecure/v1/customers/${id}/risks/${riskId}/notes`)
      .then((response: AxiosResponse) => {
        dispatch({
          type: SET_NOTES,
          payload: { comments: response.data.data, riskId, riskType },
        });
      })
      .catch((error: AxiosError) =>
        toastr.error(
          'Failed to fetch notes for this risk',
          error.code as string
        )
      );
  };

export const createNoteThunk =
  (note: string, riskId: string, type: string) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;
    return riskAPI
      .post(`/rootsecure/v1/customers/${id}/risks/${riskId}/notes`, { note })
      .then((response: AxiosResponse) => {
        dispatch({
          type: ADD_NOTE,
          payload: {
            type,
            riskId,
            comment: response.data.data,
          },
        });
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to create comment', error.code as string)
      );
  };

type RiskParamType = { [key: string]: string };
export const isActiveRiskBeingMitigated = (
  fieldsToUpdate: RiskParamType[],
  isRiskActive: boolean
) => {
  if (!isRiskActive) {
    return false;
  }

  return fieldsToUpdate.some(
    (fieldObj) =>
      fieldObj.value.toLocaleLowerCase() ===
      STATUS.MITIGATED.toLocaleLowerCase()
  );
};

export const updateRiskThunk =
  (
    riskId: string,
    newData: { [key: string]: string },
    type: string,
    status: string
  ) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    const isRiskMitigatedFromActive = isActiveRiskBeingMitigated(
      [newData],
      status.toLocaleLowerCase() === STATUS.ACTIVE.toLocaleLowerCase()
    );
    if (isRiskMitigatedFromActive) {
      toastr.error(
        'Failed to update Risk',
        'Active risk cannot be set to Mitigated'
      );
      return;
    }

    riskAPI
      .patch(`/rootsecure/v1/customers/${id}/risks/${riskId}`, [newData])
      .then((response: AxiosResponse) => {
        dispatch({
          type: UPDATE_RISK,
          payload: { key: type, risk: response.data.data },
        });
        toastr.success(
          'Success',
          `Risk Updated Successfully, this request will be queued and may take several minutes to update.`
        );
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to update Risk', error.code as string)
      );
  };

export const getRisksThunk_V2 =
  (params: RisksParams = {}, type: string) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .get(`/rootsecure/rida/v1/customers/${id}/risks`, {
        params,
      })
      .then((response: AxiosResponse) => {
        dispatch({
          type,
          payload: {
            risks: response.data.data,
            meta: response.data.meta,
          },
        });
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Risks', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const bulkUpdateRisksThunk =
  (
    riskUpdate: { [key: string]: string },
    caseAssignment: { [key: string]: string },
    caseUpdate: { [key: string]: string },
    selectedRisks: Array<iRisk>,
    updatePage?: () => void
  ) =>
  (dispatch: any, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    const riskIds: string[] = [];
    let isAnyRiskActive = false;

    selectedRisks.forEach((risk) => {
      riskIds.push(risk.id);
      if (
        !isAnyRiskActive &&
        risk.attributes.status.toLocaleLowerCase() ===
          STATUS.ACTIVE.toLocaleLowerCase()
      ) {
        isAnyRiskActive = true;
      }
    });

    if (Object.keys(riskUpdate)?.length > 0) {
      const fieldsToUpdate = Object.entries(riskUpdate).map(
        ([field, value]: any) => ({
          field,
          value,
        })
      );

      const isSomeRiskMitigatedFromActive = isActiveRiskBeingMitigated(
        fieldsToUpdate,
        isAnyRiskActive
      );

      if (isSomeRiskMitigatedFromActive) {
        toastr.error(
          'Failed to update Risks',
          'Active risk cannot be set to Mitigated'
        );
        return;
      }

      riskAPI
        .patch(`/rootsecure/v1/customers/${id}/risks`, {
          fields: fieldsToUpdate,
          ids: riskIds,
        })

        .then(() => {
          toastr.success(
            'Success',
            `${riskIds.length} updated, this request will be queued and may take several minutes to update.`
          );
          updatePage?.();
        })
        .catch((error: AxiosError) =>
          toastr.error('Failed to update Risk Status', error.code!)
        );
    }

    if (
      Object.keys(caseUpdate)?.length > 0 ||
      Object.keys(caseAssignment)?.length > 0
    ) {
      riskAPI
        .patch(
          `/rootsecure/casemgmt/v1/customers/${id}/assignedrisks`,
          riskIds.map((riskId: string) => ({
            riskId,
            caseId: caseAssignment?.selectedCase,
            assignment: caseUpdate,
          }))
        )
        .then(({ data }) => {
          const { data: response } = data;
          dispatch({
            type: SET_ASSIGNMENT,
            payload: { key: 'risks', risks: response },
          });
          toastr.success('Success', `${riskIds.length} updated`);
        })
        .catch((error: AxiosError) =>
          toastr.error('Failed to update Risk assignment', error.code!)
        );
    }
  };

export const getCSVThunk =
  (mitigated: boolean, params?: Readonly<RisksParams>) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const accessToken = await getAuthToken();
    const { config, customer } = getState();
    toastr.message('Preparing CSV', mitigated ? 'Mitigated Risks' : 'Risks');

    const copyParams: RisksParams = { ...params };

    if (params?.page) delete copyParams.page;
    if (copyParams?.limit) delete copyParams.limit;
    const query = GenerateQuerystring(copyParams);

    const servicesHost = config.environment.servicesHost;
    const custId = customer?.currentCustomer?.id;
    const urlParams = query.slice(0, -1);
    const url = `${servicesHost}/rootsecure/rida/v1/customers/${custId}/risksCSV?${urlParams}`;

    fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response: any) => {
        if (!response.ok) {
          throw response;
        }
        return response.blob(); //we only get here if there is no error
      })
      .then((blob: any) => {
        toastr.removeByType('message');
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${mitigated ? 'Mitigated ' : ''}Risks ${moment().format('LLLL')}.csv`
        );
        // 3. Append to html page
        // deepcode ignore DOMXSS: The data used to create this link comes from rootsecure
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .catch((error: any) => {
        const errorDetails =
          error.status && error.statusText
            ? `status ${error.status}: ${error.statusText}`
            : '';

        toastr.removeByType('message');
        toastr.error('Failed to load csv', errorDetails);
      });
  };

export const getRemediationCSVThunk =
  (params: Readonly<RisksParams>) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const accessToken = await getAuthToken();
    const {
      config: {
        environment: { servicesHost },
      },
      customer: {
        currentCustomer: { id: customerID },
      },
    } = getState();

    toastr.message(
      'Risk Remediation Export',
      'This may take a few minutes to generate based upon the number of your results. ' +
        'If the file is too large to open, try filtering the results down before generating the CSV.'
    );

    // the CSV report shouldn't be limited by pagination
    const { page, limit, ...queryObj } = params;

    const query = GenerateQuerystring(queryObj);

    fetch(
      `${servicesHost}/rootsecure/v1/customers/${customerID}/risks-remediation-report?${query}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response: any) => {
        if (!response.ok) {
          throw response;
        }
        return response.blob(); //we only get here if there is no error
      })
      .then((blob: any) => {
        toastr.removeByType('message');
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Risk Remediation Report ${moment().format('LLLL')}.csv`
        );
        // 3. Append to html page
        // deepcode ignore DOMXSS: The data used to create this link comes from rootsecure
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .catch((error: any) => {
        const errorDetails =
          error.status && error.statusText
            ? `status ${error.status}: ${error.statusText}`
            : '';

        toastr.removeByType('message');
        toastr.error('Failed to load csv', errorDetails);
      });
  };

const GenerateQuerystring = (copyParams: RisksParams) => {
  let query = '';
  Object.entries(copyParams).forEach(([key, value]: any) => {
    if (typeof value !== 'undefined') {
      if (Array.isArray(value)) {
        value.forEach((element: string) => {
          query = query + `${key}=${element}&`;
        });
      } else {
        query = query + `${key}=${value}&`;
      }
    }
  });
  return query;
};
