/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * overview of status for all scanners
 * @export
 * @interface SensorSummary
 */
export interface SensorSummary {
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    unhealthy: number;
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    staged: number;
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    awaitingActivation: number;
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    shipped: number;
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    preparingToShip: number;
    /**
     * 
     * @type {number}
     * @memberof SensorSummary
     */
    healthy: number;
}

export function SensorSummaryFromJSON(json: any): SensorSummary {
    return SensorSummaryFromJSONTyped(json, false);
}

export function SensorSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unhealthy': json['unhealthy'],
        'staged': json['staged'],
        'awaitingActivation': json['awaitingActivation'],
        'shipped': json['shipped'],
        'preparingToShip': json['preparingToShip'],
        'healthy': json['healthy'],
    };
}

export function SensorSummaryToJSON(value?: SensorSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unhealthy': value.unhealthy,
        'staged': value.staged,
        'awaitingActivation': value.awaitingActivation,
        'shipped': value.shipped,
        'preparingToShip': value.preparingToShip,
        'healthy': value.healthy,
    };
}


