import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import {
  Button,
  HStack,
  Select,
  SelectOption,
  Primitive,
  TextField,
  Grid,
} from '@rtkwlf/fenrir-react';
import { AssetUpdateFieldsTypeV3 } from './utils';

import _ from 'lodash';

import AssetTagTable, {
  useTagSelection,
  TagCheckedState,
  useFilterTags,
} from './AssetTagTable';
import { useQueryAllTags } from '../../Global/assetTags';

import { bulkUpdateAssets } from '../../Global/assetActions';
import { DataWithDuplicateAssets } from '../../Global/assetReducer';
import { configSelectors } from '../../Global/configReducer';
import { customerSelectors } from '../../Global/customerReducer';
import { CRITICALITY_MAP } from '../../Constants/Common';
import { useAssetCatalog } from './AssetTable/hooks';
import { useAppDispatch } from '../../hooks';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ASSET_CATEGORIES } from '../../Constants/Assets';

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 32px;
`;

const StyledSubtitle = styled.label`
  font-size: 14px;
  font-weight: bold;
`;

export type SelectNumberType = {
  value: number;
  label: string;
};

export type SelectType = {
  value: string;
  label: string;
};

export type Props = {
  data: DataWithDuplicateAssets[];
  visible: boolean;
  setShowBulkUpdateModal: (status: boolean) => void;
  selectedAssetIDs: string[];
};

const CRITICALITY_OPTIONS: SelectOption[] = Object.entries(CRITICALITY_MAP)
  .filter(([key, { value }]) => value !== 'Unassigned')
  .map(([key, { value }]) => ({
    text: value,
    value: key,
  }));

const AssetBulkUpdate = ({
  data,
  visible,
  setShowBulkUpdateModal,
  selectedAssetIDs,
}: Props) => {
  const dispatch = useAppDispatch();
  const { onAssetsDeleteSuccess } = useAssetCatalog();
  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedCriticality, setSelectedCriticality] = useState<number>();
  const [deviceName, setDeviceName] = useState<string>('');
  const [checkedState, setCheckedState] = useState<TagCheckedState>({
    checked: [],
    indeterminates: [],
  });
  const { data: allTagsData } = useQueryAllTags(customerId);

  const assetCatgories = ASSET_CATEGORIES.map((category: string) => ({
    value: category,
    text: category,
  }));

  const uniqueSelectedCriticality = [
    ...new Set(data.map((a) => a.criticality ?? 0)),
  ];
  const uniqueSelectedCategory = [...new Set(data.map((a) => a.category))];
  const initCheckboxes = React.useCallback(() => {
    const checked: number[] = [];
    const indeterminates: number[] = [];
    if (allTagsData) {
      allTagsData.forEach(({ tagID }) => {
        const assetHasTag = (asset: DataWithDuplicateAssets) =>
          !!asset.assetTags?.find((at) => at.tagID === tagID);

        if (data.every(assetHasTag)) {
          checked.push(tagID);
        } else if (data.some(assetHasTag)) {
          indeterminates.push(tagID);
        }
      });
    }
    setCheckedState({ checked, indeterminates });
  }, [allTagsData, data]);
  useEffect(() => {
    initCheckboxes();
  }, [data, allTagsData, selectedAssetIDs, initCheckboxes]);
  const { columns, checkedTags, indeterminates } = useTagSelection(
    allTagsData,
    checkedState
  );
  const { sortedData, filterText, handleSort, handleFilter } = useFilterTags(
    allTagsData || []
  );
  const clearFields = () => {
    setSelectedCategory('');
    setDeviceName('');
    setShowBulkUpdateModal(false);
    initCheckboxes();
    setSelectedCriticality(undefined);
  };

  const onAssetUpdateSuccess = () => {
    onAssetsDeleteSuccess();
    clearFields();
  };

  const handleSubmit = () => {
    const selectedAssetIDsUpdate: Array<AssetUpdateFieldsTypeV3> = [];
    const selectedIds = [...selectedAssetIDs];

    if (selectedCategory && selectedCategory.length > 64) {
      toastr.warning('Update skipped', 'Category Length exceeded');
    }
    if (deviceName?.trim().length > 255) {
      toastr.warning('Update skipped', 'Device Name Length exceeded');
    }

    selectedIds.forEach((assetId) => {
      const assetRow = data.find((row) => row.id === assetId)!;

      const updateCategoryValue = selectedCategory || assetRow.category;
      const updateDeviceNameValue = deviceName?.trim() || assetRow?.deviceName;
      const updateCritValue = selectedCriticality ?? assetRow.criticality;

      const assetTags = assetRow.assetTags || [];
      let updateTagsValue: number[] = assetTags.map((tag) => tag.tagID);

      if (allTagsData) {
        allTagsData.forEach((tag) => {
          const isChecked = checkedTags.includes(tag.tagID);
          // Add if checked and not in array already
          if (isChecked && !updateTagsValue.includes(tag.tagID)) {
            updateTagsValue.push(tag.tagID);
            // Remove if not indeterminate and unchecked
          } else if (!indeterminates.includes(tag.tagID) && !isChecked) {
            updateTagsValue = updateTagsValue.filter((id) => id !== tag.tagID);
          }
        });
      }

      const updateObject = {
        device_id: assetId,
        category: updateCategoryValue ?? '',
        tags: updateTagsValue,
        criticality: updateCritValue ?? 0,
      };

      selectedAssetIDsUpdate.push(
        selectedIds.length === 1
          ? { ...updateObject, deviceName: updateDeviceNameValue ?? '' }
          : updateObject
      );
    });

    dispatch(
      bulkUpdateAssets(
        selectedAssetIDsUpdate,
        servicesHost,
        customerId,
        onAssetUpdateSuccess
      )
    );
  };

  const isChanged = !!(
    selectedCategory ||
    deviceName ||
    !_.isEqual(checkedTags, checkedState.checked) ||
    !_.isEqual(indeterminates, checkedState.indeterminates) ||
    typeof selectedCriticality !== 'undefined'
  );

  const renderWithName: boolean = selectedAssetIDs.length === 1;

  const getGridAreaValue = () => {
    const gridAreaWithName = `
    "category name"
    "criticality null"
    "tagSearch tagSearch"
    "tagTable tagTable"
    `;
    const gridAreaWithoutName = `
    "category criticality"
    "tagSearch tagSearch"
    "tagTable tagTable"
    `;
    return renderWithName ? gridAreaWithName : gridAreaWithoutName;
  };

  return (
    <ModalBox
      title='Update Selected Assets'
      onCloseModal={clearFields}
      isOpen={visible}
      width='1100px'
      position={ModalPositionType.top}
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button
            data-testid='bulk-update-asset-button'
            style={{ marginRight: '8px' }}
            onClick={handleSubmit}
            isDisabled={!isChanged}
          >
            Update Asset(s)
          </Button>
          <Button onClick={clearFields} variant='secondary'>
            Cancel
          </Button>
        </HStack>
      }
    >
      <Primitive.div paddingX='large' paddingBottom='large'>
        <StyledTitle>{selectedAssetIDs.length} Asset(s) Selected</StyledTitle>
        <Grid.Root
          columns='1fr 1fr'
          gap='default'
          marginBottom='default'
          areas={getGridAreaValue()}
        >
          <Grid.Item area='category'>
            <StyledSubtitle>Category</StyledSubtitle>
            <Select
              options={assetCatgories}
              onChange={(value) => {
                setSelectedCategory(value);
              }}
              placeholder={
                uniqueSelectedCategory.length > 1
                  ? 'Mixed'
                  : uniqueSelectedCategory[0]
              }
            />
          </Grid.Item>
          {renderWithName && (
            <Grid.Item data-testid='update-asset-name' area='name'>
              <StyledSubtitle>Device Name</StyledSubtitle>
              <TextField
                value={deviceName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDeviceName(e.target.value)
                }
                placeholder='Enter Device Name'
              />
            </Grid.Item>
          )}
          <Grid.Item data-testid='update-asset-criticality' area='criticality'>
            <StyledSubtitle>Asset Criticality</StyledSubtitle>
            <Select
              options={CRITICALITY_OPTIONS}
              onChange={(value) => {
                setSelectedCriticality(Number(value));
              }}
              placeholder={
                uniqueSelectedCriticality.length > 1
                  ? 'Mixed'
                  : uniqueSelectedCriticality.length > 0
                    ? CRITICALITY_MAP[uniqueSelectedCriticality[0]].value
                    : 'Select...'
              }
            />
          </Grid.Item>
          <Grid.Item area='tagSearch'>
            <StyledSubtitle>Asset Tags</StyledSubtitle>
            <TextField
              value={filterText}
              onChange={handleFilter}
              placeholder='Search'
              iconLeft={<FontAwesomeIcon icon={faSearch} />}
            />
          </Grid.Item>
          <Grid.Item area='tagTable'>
            <AssetTagTable
              data={sortedData}
              columns={columns}
              handleSort={handleSort}
            />
          </Grid.Item>
        </Grid.Root>
      </Primitive.div>
    </ModalBox>
  );
};

export default AssetBulkUpdate;
