/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * a single subscription; the subscriptionType field defines the key containing the specific subscription details.
 * @export
 * @interface SubscriptionDetail
 */
export interface SubscriptionDetail {
  /**
   * true if the organization has an active logSearch subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  rawLogSearch: boolean;
  /**
   * true if the organization has an active managed detection response subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  managedDetectionResponse: boolean;
  /**
   * true if the organization has an active managed risk subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  managedRisk: boolean;
  /**
   * true if the organization has an active managed awareness subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  managedAwareness: boolean;
  /**
   * true if the organization has an active managed awareness plus subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  managedAwarenessPlus: boolean;
  /**
   * true if the organization has an active data exploration subscription; false otherwise
   * @type {boolean}
   * @memberof SubscriptionDetail
   */
  dataExploration: boolean;
}

export function SubscriptionDetailFromJSON(json: any): SubscriptionDetail {
  return SubscriptionDetailFromJSONTyped(json, false);
}

export function SubscriptionDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubscriptionDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rawLogSearch: json['rawLogSearch'],
    managedDetectionResponse: json['managedDetectionResponse'],
    managedRisk: json['managedRisk'],
    managedAwareness: json['managedAwareness'],
    managedAwarenessPlus: json['managedAwarenessPlus'],
    dataExploration: json['dataExploration'],
  };
}

export function SubscriptionDetailToJSON(
  value?: SubscriptionDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rawLogSearch: value.rawLogSearch,
    managedDetectionResponse: value.managedDetectionResponse,
    managedRisk: value.managedRisk,
    managedAwareness: value.managedAwareness,
    managedAwarenessPlus: value.managedAwarenessPlus,
    dataExploration: value.dataExploration,
  };
}
