/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SecurityPostureLevel,
    SecurityPostureLevelFromJSON,
    SecurityPostureLevelFromJSONTyped,
    SecurityPostureLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface CoverageScore
 */
export interface CoverageScore {
    /**
     * 
     * @type {string}
     * @memberof CoverageScore
     */
    score?: string;
    /**
     * 
     * @type {SecurityPostureLevel}
     * @memberof CoverageScore
     */
    level?: SecurityPostureLevel;
}

export function CoverageScoreFromJSON(json: any): CoverageScore {
    return CoverageScoreFromJSONTyped(json, false);
}

export function CoverageScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoverageScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': !exists(json, 'score') ? undefined : json['score'],
        'level': !exists(json, 'level') ? undefined : SecurityPostureLevelFromJSON(json['level']),
    };
}

export function CoverageScoreToJSON(value?: CoverageScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'level': SecurityPostureLevelToJSON(value.level),
    };
}


