/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RiskCount,
    RiskCountFromJSON,
    RiskCountFromJSONTyped,
    RiskCountToJSON,
    RiskScore,
    RiskScoreFromJSON,
    RiskScoreFromJSONTyped,
    RiskScoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface RiskScoreDetails
 */
export interface RiskScoreDetails {
    /**
     * 
     * @type {RiskScore}
     * @memberof RiskScoreDetails
     */
    industry: RiskScore;
    /**
     * 
     * @type {RiskScore}
     * @memberof RiskScoreDetails
     */
    latest: RiskScore;
    /**
     * 
     * @type {RiskCount}
     * @memberof RiskScoreDetails
     */
    unresolvedRisks: RiskCount;
    /**
     * 
     * @type {RiskCount}
     * @memberof RiskScoreDetails
     */
    newRisks: RiskCount;
    /**
     * 
     * @type {RiskCount}
     * @memberof RiskScoreDetails
     */
    mitigatedRisks: RiskCount;
    /**
     * 
     * @type {RiskCount}
     * @memberof RiskScoreDetails
     */
    acceptedRisks: RiskCount;
}

export function RiskScoreDetailsFromJSON(json: any): RiskScoreDetails {
    return RiskScoreDetailsFromJSONTyped(json, false);
}

export function RiskScoreDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskScoreDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industry': RiskScoreFromJSON(json['industry']),
        'latest': RiskScoreFromJSON(json['latest']),
        'unresolvedRisks': RiskCountFromJSON(json['unresolvedRisks']),
        'newRisks': RiskCountFromJSON(json['newRisks']),
        'mitigatedRisks': RiskCountFromJSON(json['mitigatedRisks']),
        'acceptedRisks': RiskCountFromJSON(json['acceptedRisks']),
    };
}

export function RiskScoreDetailsToJSON(value?: RiskScoreDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industry': RiskScoreToJSON(value.industry),
        'latest': RiskScoreToJSON(value.latest),
        'unresolvedRisks': RiskCountToJSON(value.unresolvedRisks),
        'newRisks': RiskCountToJSON(value.newRisks),
        'mitigatedRisks': RiskCountToJSON(value.mitigatedRisks),
        'acceptedRisks': RiskCountToJSON(value.acceptedRisks),
    };
}


