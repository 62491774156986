import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DAYS_TO_SHOW_BANNER } from '../../public/js/MaintenanceConstants';

const Bar = styled.div`
  display: flex;
  align-items: baseline;
  margin: -10px;
  padding: 10px;
  border: 1px solid #2b90d2;
  border-radius: 3px;
  background: #e1f4ff;
`;

const InfoIcon = styled.a`
  color: #337ab7;
  margin: 0px 10px 0px 0px;
  vertical-align: middle;
`;

type Props = {
  endTime: moment.Moment;
  message: string;
};

export const MaintenanceBanner = ({ endTime, message }: Props) => {
  const visible =
    moment() < endTime &&
    moment() > moment(endTime).subtract(DAYS_TO_SHOW_BANNER, 'days');
  if (visible) {
    return (
      <Bar data-testid='maintenance-banner'>
        <div>
          <InfoIcon className='fa fa-info-circle' />
        </div>
        <div>
          <b>Planned Maintenance</b>
          <div>
            {message}
            <a href='mailto:Security@arcticwolf.com'>Security@arcticwolf.com</a>
          </div>
        </div>
      </Bar>
    );
  }
  return null;
};
