/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for a single scanner scan with hosts
 * @export
 * @interface IvaScanRequest
 */
export interface IvaScanRequest {
  /**
   *
   * @type {string}
   * @memberof IvaScanRequest
   */
  scannerId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IvaScanRequest
   */
  hosts?: Array<string>;
}

export function IvaScanRequestFromJSON(json: any): IvaScanRequest {
  return IvaScanRequestFromJSONTyped(json, false);
}

export function IvaScanRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IvaScanRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scannerId: !exists(json, 'scannerId') ? undefined : json['scannerId'],
    hosts: !exists(json, 'hosts') ? undefined : json['hosts'],
  };
}

export function IvaScanRequestToJSON(value?: IvaScanRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scannerId: value.scannerId,
    hosts: value.hosts,
  };
}
