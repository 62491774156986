import { RSAuthenticate, getAuthToken } from '../utils/auth/authentication.js';
import { Configuration, DefaultApi, HTTPHeaders } from './rendall';
export const AUTH_TOKEN = 'access_token';

/**
 * This hook enables to make end point calls to Rendall backend through Open
 * API generated models and api client. Since Rendall base urls are based on
 * Organization's Region it uses the selected org's pod.
 *
 * @param headers HTTP Headers for fetch call.
 * @returns Returns Rendall api client to make fetch requests with Authentication Headers included.
 */

/**
 * Usage: Example
 * Import useRendallApi hook.
 * const api = useRendallApi();
 * async () => api.getContactInfo()
 * **/

export const useRendallApi = (headers?: HTTPHeaders): DefaultApi => {
  const basePath = (() => {
    const isE2eTesting = window.localStorage.getItem('e2e_testing');
    const pod = window.localStorage.getItem('apiPod');

    if (isE2eTesting === 'true') return 'http://localhost:8080';
    else {
      return pod && pod !== 'n/a'
        ? `https://rendall.${pod}.arcticwolf.net`
        : 'https://rendall.us001-prod.arcticwolf.net'; // fallback case, should almost never get hit
    }
  })();

  /**
   * Set access token to the returned promise from getAuthToken()
   * after authentication has been refreshed.
   *
   * RSAuthenticate maintains a single promise whether started
   * by this call, or the one in index-init.js
   */
  const api = new DefaultApi(
    new Configuration({
      basePath,
      headers,
      accessToken: RSAuthenticate().then(
        () => getAuthToken() as Promise<string>
      ),
    })
  );
  return api;
};
