/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskScore
 */
export interface RiskScore {
    /**
     * 
     * @type {number}
     * @memberof RiskScore
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof RiskScore
     */
    delta: number;
}

export function RiskScoreFromJSON(json: any): RiskScore {
    return RiskScoreFromJSONTyped(json, false);
}

export function RiskScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'delta': json['delta'],
    };
}

export function RiskScoreToJSON(value?: RiskScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'delta': value.delta,
    };
}


