/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OnboardingStatus {
    Onboarded = 'onboarded',
    Onboarding = 'onboarding'
}

export function OnboardingStatusFromJSON(json: any): OnboardingStatus {
    return OnboardingStatusFromJSONTyped(json, false);
}

export function OnboardingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingStatus {
    return json as OnboardingStatus;
}

export function OnboardingStatusToJSON(value?: OnboardingStatus | null): any {
    return value as any;
}

