/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CancelIvaScansInputScannerTargets,
  CancelIvaScansInputScannerTargetsFromJSON,
  CancelIvaScansInputScannerTargetsFromJSONTyped,
  CancelIvaScansInputScannerTargetsToJSON,
} from './';

/**
 * Input to request cancelling running scans
 * @export
 * @interface CancelIvaScansInput
 */
export interface CancelIvaScansInput {
  /**
   * List of scanner target objects.
   * @type {Array<CancelIvaScansInputScannerTargets>}
   * @memberof CancelIvaScansInput
   */
  scannerTargets?: Array<CancelIvaScansInputScannerTargets>;
}

export function CancelIvaScansInputFromJSON(json: any): CancelIvaScansInput {
  return CancelIvaScansInputFromJSONTyped(json, false);
}

export function CancelIvaScansInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelIvaScansInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scannerTargets: !exists(json, 'scannerTargets')
      ? undefined
      : (json['scannerTargets'] as Array<any>).map(
          CancelIvaScansInputScannerTargetsFromJSON
        ),
  };
}

export function CancelIvaScansInputToJSON(
  value?: CancelIvaScansInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scannerTargets:
      value.scannerTargets === undefined
        ? undefined
        : (value.scannerTargets as Array<any>).map(
            CancelIvaScansInputScannerTargetsToJSON
          ),
  };
}
