import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { iProfileHistory } from '../../Global/assetReducer';
import { RequiredError } from '../../apiClient/rendall';
import {
  assetProfileHistoryType,
  getAssetDetails,
  getAssetProfileData,
} from './AssetDetails/utils';

type SelectFn =
  | ((data: iProfileHistory[]) => Array<assetProfileHistoryType>)
  | undefined;

export const useAssetProfileData = (
  servicesHost: string,
  initialCustomerId: string,
  assetId: string,
  select?: SelectFn
): UseQueryResult<assetProfileHistoryType[], RequiredError> => {
  return useQuery({
    queryKey: [`getProfileData-${assetId}`],
    queryFn: async () => {
      const apiAssetProfileURL: string = `${servicesHost}/rootsecure/v1/customers/${initialCustomerId}/assets/${assetId}/histories`;
      return getAssetProfileData(apiAssetProfileURL);
    },
    refetchOnMount: true,
    select,
    enabled: !!assetId,
  });
};

export const useQueryAssetDetails = (
  customerId: string,
  assetId: string,
  servicesHost: string,
  clientId: string
) => {
  const queryKey = assetId || clientId || '';

  return useQuery({
    queryKey: [`getAssetDetails-${queryKey}`],
    queryFn: () => getAssetDetails(customerId, assetId, servicesHost, clientId),
    retry: false,
    enabled: !!queryKey,
  });
};
