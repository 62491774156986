/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ContactTimeRange,
  ContactTimeRangeFromJSON,
  ContactTimeRangeFromJSONTyped,
  ContactTimeRangeToJSON,
  EscalationTier,
  EscalationTierFromJSON,
  EscalationTierFromJSONTyped,
  EscalationTierToJSON,
} from './';

/**
 *
 * @export
 * @interface EscalationConfig
 */
export interface EscalationConfig {
  /**
   * an integer that uniquely identifies an escalation config, this is for internal use and will not be returned
   * @type {number}
   * @memberof EscalationConfig
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  sitesToNotifyFor?: string;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  usersToNotifyFor?: string;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  devicesToNotifyFor?: string;
  /**
   * This flag is true when customer should not be informed
   * @type {boolean}
   * @memberof EscalationConfig
   */
  doNotEscalate: boolean;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof EscalationConfig
   */
  created?: Date;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof EscalationConfig
   */
  updated?: Date;
  /**
   *
   * @type {string}
   * @memberof EscalationConfig
   */
  timeType: EscalationConfigTimeTypeEnum;
  /**
   *
   * @type {Array<ContactTimeRange>}
   * @memberof EscalationConfig
   */
  timeRange?: Array<ContactTimeRange>;
  /**
   * IANA timezone string, can be empty
   * @type {string}
   * @memberof EscalationConfig
   */
  timezone?: string;
  /**
   *
   * @type {Array<EscalationTier>}
   * @memberof EscalationConfig
   */
  tiers: Array<EscalationTier>;
}

/**
 * @export
 * @enum {string}
 */
export enum EscalationConfigTimeTypeEnum {
  Any = 'any',
  DuringBusinessHours = 'during_business_hours',
  OutsideBusinessHours = 'outside_business_hours',
}

export function EscalationConfigFromJSON(json: any): EscalationConfig {
  return EscalationConfigFromJSONTyped(json, false);
}

export function EscalationConfigFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EscalationConfig {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    displayName: json['displayName'],
    category: !exists(json, 'category') ? undefined : json['category'],
    sitesToNotifyFor: !exists(json, 'sitesToNotifyFor')
      ? undefined
      : json['sitesToNotifyFor'],
    usersToNotifyFor: !exists(json, 'usersToNotifyFor')
      ? undefined
      : json['usersToNotifyFor'],
    devicesToNotifyFor: !exists(json, 'devicesToNotifyFor')
      ? undefined
      : json['devicesToNotifyFor'],
    doNotEscalate: json['doNotEscalate'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    updated: !exists(json, 'updated') ? undefined : new Date(json['updated']),
    timeType: json['timeType'],
    timeRange: !exists(json, 'timeRange')
      ? undefined
      : (json['timeRange'] as Array<any>).map(ContactTimeRangeFromJSON),
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
    tiers: (json['tiers'] as Array<any>).map(EscalationTierFromJSON),
  };
}

export function EscalationConfigToJSON(value?: EscalationConfig | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    displayName: value.displayName,
    category: value.category,
    sitesToNotifyFor: value.sitesToNotifyFor,
    usersToNotifyFor: value.usersToNotifyFor,
    devicesToNotifyFor: value.devicesToNotifyFor,
    doNotEscalate: value.doNotEscalate,
    created:
      value.created === undefined ? undefined : value.created.toISOString(),
    updated:
      value.updated === undefined ? undefined : value.updated.toISOString(),
    timeType: value.timeType,
    timeRange:
      value.timeRange === undefined
        ? undefined
        : (value.timeRange as Array<any>).map(ContactTimeRangeToJSON),
    timezone: value.timezone,
    tiers: (value.tiers as Array<any>).map(EscalationTierToJSON),
  };
}
