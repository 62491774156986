/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the status of a given cloud sensor
 * @export
 * @enum {string}
 */
export enum CloudSensorStatus {
    Unhealthy = 'unhealthy',
    InvalidCredentials = 'invalidCredentials',
    ConfigPending = 'configPending',
    DisconnectPending = 'disconnectPending',
    Healthy = 'healthy',
    NotApplicable = 'notApplicable',
    NotAvailable = 'notAvailable'
}

export function CloudSensorStatusFromJSON(json: any): CloudSensorStatus {
    return CloudSensorStatusFromJSONTyped(json, false);
}

export function CloudSensorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudSensorStatus {
    return json as CloudSensorStatus;
}

export function CloudSensorStatusToJSON(value?: CloudSensorStatus | null): any {
    return value as any;
}

