/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CronSchedule
 */
export interface CronSchedule {
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  minute?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  hour?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  dayOfWeek?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  dayOfMonth?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  month?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CronSchedule
   */
  year?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CronSchedule
   */
  length?: number;
}

export function CronScheduleFromJSON(json: any): CronSchedule {
  return CronScheduleFromJSONTyped(json, false);
}

export function CronScheduleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CronSchedule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    minute: !exists(json, 'minute') ? undefined : json['minute'],
    hour: !exists(json, 'hour') ? undefined : json['hour'],
    dayOfWeek: !exists(json, 'dayOfWeek') ? undefined : json['dayOfWeek'],
    dayOfMonth: !exists(json, 'dayOfMonth') ? undefined : json['dayOfMonth'],
    month: !exists(json, 'month') ? undefined : json['month'],
    year: !exists(json, 'year') ? undefined : json['year'],
    length: !exists(json, 'length') ? undefined : json['length'],
  };
}

export function CronScheduleToJSON(value?: CronSchedule | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    minute: value.minute,
    hour: value.hour,
    dayOfWeek: value.dayOfWeek,
    dayOfMonth: value.dayOfMonth,
    month: value.month,
    year: value.year,
    length: value.length,
  };
}
