/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentRow,
    AgentRowFromJSON,
    AgentRowFromJSONTyped,
    AgentRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface AgentPage
 */
export interface AgentPage {
    /**
     * the total number of items, across all pages matching the query
     * @type {number}
     * @memberof AgentPage
     */
    total: number;
    /**
     * 
     * @type {Array<AgentRow>}
     * @memberof AgentPage
     */
    items: Array<AgentRow>;
}

export function AgentPageFromJSON(json: any): AgentPage {
    return AgentPageFromJSONTyped(json, false);
}

export function AgentPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(AgentRowFromJSON)),
    };
}

export function AgentPageToJSON(value?: AgentPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': ((value.items as Array<any>).map(AgentRowToJSON)),
    };
}


