/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Containment details
 * @export
 * @interface AgentContainment
 */
export interface AgentContainment {
    /**
     * Whether the agent is currently contained
     * @type {boolean}
     * @memberof AgentContainment
     */
    contained: boolean;
    /**
     * A date conveyed in RFC3339 format
     * @type {Date}
     * @memberof AgentContainment
     */
    date?: Date;
}

export function AgentContainmentFromJSON(json: any): AgentContainment {
    return AgentContainmentFromJSONTyped(json, false);
}

export function AgentContainmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentContainment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contained': json['contained'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function AgentContainmentToJSON(value?: AgentContainment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contained': value.contained,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
    };
}


