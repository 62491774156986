import { StatusType } from '../Constants/Labels';

export enum CronType {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
  CONTINUOUS = 'Continuous',
}

/**
 * Depending on the type of cron, different fields will be defined:
 *  - continuous: None (i.e. empty object)
 *  - daily: hour, minute
 *  - weekly: hour, minute, dayOfWeek
 *  - monthly: hour, minute, dayOfMonth
 */
export type Cron = {
  minute: number[] | null | undefined; // 0 - 59
  hour: number[] | null | undefined; // 0 - 23
  dayOfMonth: number[] | null | undefined; // 1 - 31
  month: number[] | null | undefined; // 1 - 12
  dayOfWeek: number[] | null | undefined; // 0 - 6
  year: number[] | null | undefined; // 1970–2099
};

export type ScheduleWindow = {
  cron: Cron;
  lengthMinute: number;
};

type TagDetails = {
  description: string;
  name: string;
};

export type ScheduleData = {
  id: string;
  targets: string[];
  targetGroups?: string[] | null;
  windows: ScheduleWindow[];
  priority: number;
  timezone: string;
  tags?: TagDetails;
};

export type SchedulePost = {
  id?: string;
  targets: string[];
  windows: ScheduleWindow[];
  priority: number;
  timezone: string;
};

export type Schedule = {
  id: string;
  nextScanWindow?: string;
  createdTime?: string;
  updatedTIme?: string; // the capital I is a typo in the API response from backend
  disabled?: boolean;
} & ScheduleData;

export type QueuedScan = {
  lastScan: string;
  scanReason: string;
  status: StatusType;
  target: string; // IP
  schedule: Schedule;
};

export type ScannerConfig = {
  id: string;
  ip: string;
  netmask: string;
  connectionStatus: 'red' | 'yellow' | 'green' | 'na';
  scanningStatus: 'red' | 'yellow' | 'green' | 'na';
  scans: boolean;
  nmap: boolean;
  blacklist: string[];
  networks: string[];
  dns_servers: string[];
};

type SensorLogStats = {
  logtype: string;
  port: string;
  lastingest: string;
};

/**
 * Response schema of GET /rootsecure/v1/sensor/{id}/network
 */
export type SensorNetwork = {
  ipaddr: string;
  ipnet: string;
  gateway: string;
};

/**
 * Response schema of GET /rootsecure/v1/sensor/{id}/status
 * Response schema of GET /rootsecure/v1/sensordata/{id}
 */
export type SensorStatus = {
  agedirect: string;
  agelog: string;
  agenmap: string;
  agescan: string;
  agetopic: string;
  connstatus: 'red' | 'yellow' | 'green' | 'na';
  customerID: string;
  deploymentID: string;
  label: string;
  lastdirect: string;
  lastlog: string;
  lastnmap: string;
  lastscan: string;
  lasttopic: string;
  logdetails: string;
  logstats: SensorLogStats[] | null;
  logstatus: string;
  reportstatus: 'red' | 'yellow' | 'green' | 'na';
  reportstatusstring: string;
  targetversion: string;
  uuid: string;
  version: string;
};

/**
 * Response schema of GET /rootsecure/v1/sensor/{id}/config
 */
export type SensorConfig = {
  scans: boolean;
  nmap: boolean;
  pingOnly: boolean;
  blacklist: string[] | null;
  networks: string[] | null;
  hardstop: boolean;
  capabilities: string[] | null;
  dns_servers: string[] | null;
  msgID: number;
  ackMsgTypes: string[] | null;
};

export type SensorCredentialType = 'up' | 'usk';

export type SensorCredential = {
  id: string;
  type: SensorCredentialType;
  name: string;
  comment: string;
  hosts: string[] | null;
};

/**
 * Response schema of GET /rootsecure/v1/credentials/sensor/{id}
 */
export type SensorCredentials = SensorCredential[];

/**
 * Response schema of POST & PUT /rootsecure/v1/credentials/sensor/{id}
 */
export type SensorCredentialUpdate = Omit<SensorCredential, 'id' | 'type'> & {
  credential?: {
    type: string;
    login: string;
    password: string;
    privkey: string;
    community: string;
    authalgorithm: string;
    privalgorithm: string;
    certificate: string;
  };
};

type OVSPreference = {
  id: string;
  name: string;
  value: string;
  nvtID: string;
};

export type SensorPreference = {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  ovsPreferences: OVSPreference[];
};

/**
 * Response schema of GET /rootsecure/v2/scanners/{id}/preferences
 */
export type SensorPreferences = SensorPreference[];

export type ScannerSummary = {
  id: string;
  deploymentID: string;
  label: string;
};

/**
 * Response schema of GET /rootsecure/v1/customers/{customerID}/scanners
 */
export type V1CustomerScannersResponse = {
  data: ScannerSummary[];
};

export type V3ScannersScanResponse = QueuedScan[];
