/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GlobalContact,
  GlobalContactFromJSON,
  GlobalContactFromJSONTyped,
  GlobalContactToJSON,
} from './';

/**
 * Contact info for Arctic Wolf
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
  /**
   *
   * @type {string}
   * @memberof ContactInfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfo
   */
  phoneNumber: string;
  /**
   *
   * @type {Array<GlobalContact>}
   * @memberof ContactInfo
   */
  globalContacts: Array<GlobalContact>;
}

export function ContactInfoFromJSON(json: any): ContactInfo {
  return ContactInfoFromJSONTyped(json, false);
}

export function ContactInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    phoneNumber: json['phoneNumber'],
    globalContacts: (json['globalContacts'] as Array<any>).map(
      GlobalContactFromJSON
    ),
  };
}

export function ContactInfoToJSON(value?: ContactInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    phoneNumber: value.phoneNumber,
    globalContacts: (value.globalContacts as Array<any>).map(
      GlobalContactToJSON
    ),
  };
}
