/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input to delete agent scan schedules
 * @export
 * @interface DeleteAgentSchedulesInput
 */
export interface DeleteAgentSchedulesInput {
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteAgentSchedulesInput
   */
  _delete?: Array<string>;
}

export function DeleteAgentSchedulesInputFromJSON(
  json: any
): DeleteAgentSchedulesInput {
  return DeleteAgentSchedulesInputFromJSONTyped(json, false);
}

export function DeleteAgentSchedulesInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteAgentSchedulesInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _delete: !exists(json, 'delete') ? undefined : json['delete'],
  };
}

export function DeleteAgentSchedulesInputToJSON(
  value?: DeleteAgentSchedulesInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    delete: value._delete,
  };
}
