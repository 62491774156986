/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  EscalationConfig,
  EscalationConfigFromJSON,
  EscalationConfigFromJSONTyped,
  EscalationConfigToJSON,
} from './';

/**
 *
 * @export
 * @interface EscalationConfigs
 */
export interface EscalationConfigs {
  /**
   * the total number of items
   * @type {number}
   * @memberof EscalationConfigs
   */
  total: number;
  /**
   *
   * @type {Array<EscalationConfig>}
   * @memberof EscalationConfigs
   */
  items: Array<EscalationConfig>;
}

export function EscalationConfigsFromJSON(json: any): EscalationConfigs {
  return EscalationConfigsFromJSONTyped(json, false);
}

export function EscalationConfigsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EscalationConfigs {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json['total'],
    items: (json['items'] as Array<any>).map(EscalationConfigFromJSON),
  };
}

export function EscalationConfigsToJSON(value?: EscalationConfigs | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(EscalationConfigToJSON),
  };
}
