/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * https://developer.zendesk.com/api-reference/ticketing/tickets/ticket-requests/#update-request a comment body is required if solved is not true
 * @export
 * @interface CommentInput
 */
export interface CommentInput {
    /**
     * 
     * @type {boolean}
     * @memberof CommentInput
     */
    solved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentInput
     */
    body?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentInput
     */
    attachments?: Array<string>;
}

export function CommentInputFromJSON(json: any): CommentInput {
    return CommentInputFromJSONTyped(json, false);
}

export function CommentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'solved': !exists(json, 'solved') ? undefined : json['solved'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
    };
}

export function CommentInputToJSON(value?: CommentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'solved': value.solved,
        'body': value.body,
        'attachments': value.attachments,
    };
}


