/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuotaInformation,
    QuotaInformationFromJSON,
    QuotaInformationFromJSONTyped,
    QuotaInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LicenseUsage
 */
export interface LicenseUsage {
    /**
     * 
     * @type {{ [key: string]: QuotaInformation; }}
     * @memberof LicenseUsage
     */
    quotas?: { [key: string]: QuotaInformation; };
}

export function LicenseUsageFromJSON(json: any): LicenseUsage {
    return LicenseUsageFromJSONTyped(json, false);
}

export function LicenseUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quotas': !exists(json, 'quotas') ? undefined : (mapValues(json['quotas'], QuotaInformationFromJSON)),
    };
}

export function LicenseUsageToJSON(value?: LicenseUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quotas': value.quotas === undefined ? undefined : (mapValues(value.quotas, QuotaInformationToJSON)),
    };
}


