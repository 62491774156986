/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for deletion of eva scan schedules
 * @export
 * @interface DeleteEvaSchedule
 */
export interface DeleteEvaSchedule {
  /**
   *
   * @type {string}
   * @memberof DeleteEvaSchedule
   */
  groupId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteEvaSchedule
   */
  hostIds?: Array<string>;
}

export function DeleteEvaScheduleFromJSON(json: any): DeleteEvaSchedule {
  return DeleteEvaScheduleFromJSONTyped(json, false);
}

export function DeleteEvaScheduleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteEvaSchedule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupId: !exists(json, 'groupId') ? undefined : json['groupId'],
    hostIds: !exists(json, 'hostIds') ? undefined : json['hostIds'],
  };
}

export function DeleteEvaScheduleToJSON(value?: DeleteEvaSchedule | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groupId: value.groupId,
    hostIds: value.hostIds,
  };
}
