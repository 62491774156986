/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationContactWork
 */
export interface OrganizationContactWork {
  /**
   * IANA timezone string, can be empty
   * @type {string}
   * @memberof OrganizationContactWork
   */
  timezone: string;
  /**
   * Work start time
   * @type {string}
   * @memberof OrganizationContactWork
   */
  startTime: string;
  /**
   * Work end time
   * @type {string}
   * @memberof OrganizationContactWork
   */
  endTime: string;
  /**
   * A comma separated list of working days, with Monday as the start with a value of 0.
   * Can be empty
   * @type {string}
   * @memberof OrganizationContactWork
   */
  workDays: string;
}

export function OrganizationContactWorkFromJSON(
  json: any
): OrganizationContactWork {
  return OrganizationContactWorkFromJSONTyped(json, false);
}

export function OrganizationContactWorkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationContactWork {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timezone: json['timezone'],
    startTime: json['startTime'],
    endTime: json['endTime'],
    workDays: json['workDays'],
  };
}

export function OrganizationContactWorkToJSON(
  value?: OrganizationContactWork | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timezone: value.timezone,
    startTime: value.startTime,
    endTime: value.endTime,
    workDays: value.workDays,
  };
}
