/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScannerConnectionStatus,
    ScannerConnectionStatusFromJSON,
    ScannerConnectionStatusFromJSONTyped,
    ScannerConnectionStatusToJSON,
    ScannerSetting,
    ScannerSettingFromJSON,
    ScannerSettingFromJSONTyped,
    ScannerSettingToJSON,
    ScannerStatus,
    ScannerStatusFromJSON,
    ScannerStatusFromJSONTyped,
    ScannerStatusToJSON,
} from './';

/**
 * detailed information of the scanner
 * @export
 * @interface ScannerDetails
 */
export interface ScannerDetails {
    /**
     * 
     * @type {string}
     * @memberof ScannerDetails
     */
    id: string;
    /**
     * an IPv4 or IPv6 address
     * @type {string}
     * @memberof ScannerDetails
     */
    ip: string;
    /**
     * binary mask used to divide an IP address into subnets
     * @type {string}
     * @memberof ScannerDetails
     */
    netmask: string;
    /**
     * 
     * @type {ScannerConnectionStatus}
     * @memberof ScannerDetails
     */
    connectionStatus: ScannerConnectionStatus;
    /**
     * 
     * @type {ScannerStatus}
     * @memberof ScannerDetails
     */
    scannerStatus: ScannerStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScannerDetails
     */
    denyList: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScannerDetails
     */
    dnsServers: Array<string>;
    /**
     * 
     * @type {Array<ScannerSetting>}
     * @memberof ScannerDetails
     */
    settings: Array<ScannerSetting>;
}

export function ScannerDetailsFromJSON(json: any): ScannerDetails {
    return ScannerDetailsFromJSONTyped(json, false);
}

export function ScannerDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScannerDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ip': json['ip'],
        'netmask': json['netmask'],
        'connectionStatus': ScannerConnectionStatusFromJSON(json['connectionStatus']),
        'scannerStatus': ScannerStatusFromJSON(json['scannerStatus']),
        'denyList': json['denyList'],
        'dnsServers': json['dnsServers'],
        'settings': ((json['settings'] as Array<any>).map(ScannerSettingFromJSON)),
    };
}

export function ScannerDetailsToJSON(value?: ScannerDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ip': value.ip,
        'netmask': value.netmask,
        'connectionStatus': ScannerConnectionStatusToJSON(value.connectionStatus),
        'scannerStatus': ScannerStatusToJSON(value.scannerStatus),
        'denyList': value.denyList,
        'dnsServers': value.dnsServers,
        'settings': ((value.settings as Array<any>).map(ScannerSettingToJSON)),
    };
}


