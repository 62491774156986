/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for deletion of iva scan schedules
 * @export
 * @interface DeleteIvaSchedule
 */
export interface DeleteIvaSchedule {
  /**
   *
   * @type {string}
   * @memberof DeleteIvaSchedule
   */
  scannerId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteIvaSchedule
   */
  scheduleIds?: Array<string>;
}

export function DeleteIvaScheduleFromJSON(json: any): DeleteIvaSchedule {
  return DeleteIvaScheduleFromJSONTyped(json, false);
}

export function DeleteIvaScheduleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteIvaSchedule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scannerId: !exists(json, 'scannerId') ? undefined : json['scannerId'],
    scheduleIds: !exists(json, 'scheduleIds') ? undefined : json['scheduleIds'],
  };
}

export function DeleteIvaScheduleToJSON(value?: DeleteIvaSchedule | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scannerId: value.scannerId,
    scheduleIds: value.scheduleIds,
  };
}
