/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EvaScheduleTasks
 */
export interface EvaScheduleTasks {
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleTasks
   */
  vulnerability?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleTasks
   */
  webCrawler?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleTasks
   */
  darkWeb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaScheduleTasks
   */
  webserver?: boolean;
}

export function EvaScheduleTasksFromJSON(json: any): EvaScheduleTasks {
  return EvaScheduleTasksFromJSONTyped(json, false);
}

export function EvaScheduleTasksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EvaScheduleTasks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vulnerability: !exists(json, 'vulnerability')
      ? undefined
      : json['vulnerability'],
    webCrawler: !exists(json, 'webCrawler') ? undefined : json['webCrawler'],
    darkWeb: !exists(json, 'darkWeb') ? undefined : json['darkWeb'],
    webserver: !exists(json, 'webserver') ? undefined : json['webserver'],
  };
}

export function EvaScheduleTasksToJSON(value?: EvaScheduleTasks | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vulnerability: value.vulnerability,
    webCrawler: value.webCrawler,
    darkWeb: value.darkWeb,
    webserver: value.webserver,
  };
}
