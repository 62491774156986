import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  HStack,
  Primitive,
  useToastContext,
} from '@rtkwlf/fenrir-react';
import { CSVLink } from 'react-csv';
import PanelTitle from '../../Reusables/PanelTitle';
import AssetTable, { DuplicateAssetNestedRenderer } from './AssetTable';
import AssetUpdatedDataModal from './AssetUpdatedDataModal';
import AssetBulkUpdate from './AssetBulkUpdateV3';
import AssetCreateTag from './AssetCreateTag';

import {
  DataWithDuplicateAssets,
  iAssetCatalogDataType,
} from '../../Global/assetReducer';
import { configSelectors } from '../../Global/configReducer';
import { customerSelectors } from '../../Global/customerReducer';

import {
  assetsUpdatedFieldsType,
  getDuplicateDataWithCSVTags,
  sortData,
  updateAssetsByCSV_v2,
} from './utils';
import { GREY, WHITE } from '../../Constants/Styles';
import { getAssetDataThunk } from '../../Global/assetActions';
import { EXPORT_CSV_TOOLTIP, SortState } from '../../Constants/Common';
import { DownloadCSVIcon } from '../../Reusables/DownloadCSVIcon';
import { RisksParams } from '../../types/risks';
import DuplicateAssets from './DuplicateAssets';
import { IsAWNUser } from '../../utils/auth/auth';
import { ColumnProps } from '../../Reusables/table/Table';
import { AssetCatalogContext } from './AssetTable/hooks';
import { AssetSourceRenderer } from './AssetTable/AssetSourceRenderer';
import { getRiskColor } from '../../utils/riskUtils';
import { AssetBulkDeleteModal } from './AssetTable/AssetBulkDeleteModal';
import TagPills from '../../Reusables/TagPills';
import { useQueryAllTags } from '../../Global/assetTags';
import { isEVAorFreshAgent } from './AssetTable/utils';
import AssetDeleteButton from './AssetTable/AssetDeleteButton';
import { SOURCE } from '../../Constants/Risks';
import RescanControl from '../../Reusables/RescanControl';
import { useAppDispatch } from '../../hooks';

import riskAPI from '../../utils/riskAPI';
import { useMutation } from '@tanstack/react-query';
import { CriticalityPill } from './AssetDetails/InfoRowRenderers/CriticalityRenderer';
import { ModalBox } from '../../Reusables/ModalBox';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { useExportAssetsHeaders } from './use-export-assets-headers';

const WrapStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid ${GREY.lightTertiary};
  box-sizing: border-box;
  border-radius: 4px;
  background: ${WHITE.primary};
`;

// needed to fix alignment of anchor tags wrapping buttons
const StyledCSVLink = styled(CSVLink)`
  display: inherit;
`;

const RiskColumnStyle = styled.a`
  display: flex;
  width: 66px;
  color: ${WHITE.primary};
  border-radius: 36px;
  justify-content: space-evenly;
  align-items: center;
`;

const StylePaginationHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0px;
  background-color: #eeeeee;
  padding: 5px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
`;
const StyleSpan = styled.span`
  margin: 0 10px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 0px;
`;

export type DuplicateCriteria = {
  name: string;
  field: string;
  isRequired?: boolean;
};

export const DUPLICATE_CRITERIA: Array<DuplicateCriteria> = [
  { name: 'Source', field: 'source', isRequired: true },
  { name: 'Device Name', field: 'deviceName' },
  { name: 'IP', field: 'ip' },
  { name: 'MAC Address', field: 'mac' },
];

// List of duplicate criteria fields
const DEFAULT_SELECTED_CRITERIA: Array<string> = ['source', 'deviceName'];

export type AssetCSVHeader = {
  label: string;
  key: keyof DataWithDuplicateAssets;
};

const csvHeaders: AssetCSVHeader[] = [
  { label: 'Asset ID', key: 'id' },
  { label: 'IP', key: 'ip' },
  { label: 'Source', key: 'sourceName' },
  { label: 'Device Name', key: 'deviceName' },
  { label: 'MAC', key: 'mac' },
  { label: 'OS', key: 'os' },
  { label: 'Category', key: 'category' },
  { label: 'Risk', key: 'risk' },
  { label: 'Vulnerabilities', key: 'vulns' },
  {
    label: 'Manufacturer',
    key: 'manufacturer',
  },
  { label: 'Last Seen', key: 'lastSeen' },
  { label: 'Last Successful Scan', key: 'lastScannedTime' },
  { label: 'Asset Criticality', key: 'critCSV' },
  { label: 'Tags', key: 'tagCSV' },
];

const columnKeysToHideFromDropdown: string[] = ['all-assets', 'delete'];

const defaultColumnsToHideFromAssetTable: string[] = [
  'lastSeen',
  'manufacturer',
  'risk',
  'vulns',
  'mac',
];

type Props = {
  filters: RisksParams;
  data: Array<iAssetCatalogDataType>;
  isProcessing: boolean;
};

const isAWN = IsAWNUser();

type HostData = {
  source: string;
  hosts?: string[];
  clientuuids?: string[];
  ScanName?: string;
  ScanType?: string;
};
type RescanObject = HostData & { uuid: string };

const AssetCatalog = ({ filters, data, isProcessing }: Props) => {
  const dispatch = useAppDispatch();
  const [sortState, setSortState] = useState<SortState>('none');
  const [sortCol, setSortCol] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [visibleUpload, setUploadVisibility] = useState(false);
  const [fileName, setFileName] = useState('');
  const [updatedData, setUpdatedData] = useState<assetsUpdatedFieldsType[]>([]);
  const [skippedData, setSkippedData] = useState<assetsUpdatedFieldsType[]>([]);
  const [selectedAssetIDs, setSelectedAssetIDs] = useState<string[]>([]);
  const [allSelectedAssets, setAllSelectedAssets] = useState<boolean>(false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] =
    useState<boolean>(false);
  const [showCreateTagModal, setCreateTagModal] = useState<boolean>(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const [showAssetRescanModal, setShowAssetRescanModal] = React.useState(false);
  const [rescanIsLoading, setRescanIsLoading] = useState<boolean>(false);

  const [duplicateCriteria, setDuplicateCriteria] = useState(
    DEFAULT_SELECTED_CRITERIA
  );

  const [defaultIsExcludeUnknown, toggleExcludeUnknown] = React.useState(false);
  const [defaultOnlyShowDuplicate, toggleOnlyShowDuplicate] =
    React.useState(false);

  const { addToast } = useToastContext();

  const { data: allTags } = useQueryAllTags(customerId);

  const tableData = useMemo(() => {
    // skip organizing duplicates if not an AWN user or Only Show Duplicate Assets flag is disabled
    const isOrganizeDuplicates = IsAWNUser() && defaultOnlyShowDuplicate;

    const duplicateTableData = getDuplicateDataWithCSVTags(
      data,
      isOrganizeDuplicates ? duplicateCriteria : [],
      defaultIsExcludeUnknown,
      defaultOnlyShowDuplicate,
      allTags
    );

    if (sortState === 'none') {
      return duplicateTableData;
    }

    return sortData(
      sortState === 'dsc',
      sortCol as keyof iAssetCatalogDataType,
      [...duplicateTableData]
    );
  }, [
    defaultOnlyShowDuplicate,
    data,
    duplicateCriteria,
    defaultIsExcludeUnknown,
    allTags,
    sortState,
    sortCol,
  ]);

  const flattenedData = useMemo(() => {
    const data: DataWithDuplicateAssets[] = [];

    tableData.forEach((a) => {
      data.push(a);

      a.duplicateData?.forEach((dup) => {
        data.push(dup);
      });
    });

    return data;
  }, [tableData]);

  const selectedAssets = useMemo(
    () => flattenedData.filter((a) => selectedAssetIDs.includes(a.id)),
    [flattenedData, selectedAssetIDs]
  );

  const importFileRef = useRef<HTMLInputElement>(null);

  const onAssetsDeleteSuccess = useCallback(() => {
    setSelectedAssetIDs([]);
    setAllSelectedAssets(false);
    setShowBulkDeleteModal(false);
    dispatch(getAssetDataThunk(filters));
  }, [dispatch, filters]);

  React.useEffect(() => {
    setSelectedAssetIDs([]);
    setAllSelectedAssets(false);
  }, [filters]);

  const handleSort = useCallback((column: string, state: SortState) => {
    setSortCol(column);
    setSortState(state);
  }, []);

  const checkAssets = React.useCallback(
    (checked: boolean, assetId: string) => {
      let checkedRows = [...selectedAssetIDs];

      if (checked) {
        checkedRows.push(assetId);
      } else {
        setAllSelectedAssets(false);
        checkedRows = checkedRows.filter((id) => id !== assetId);
      }

      setSelectedAssetIDs(checkedRows);
    },
    [selectedAssetIDs]
  );

  const selectAllAssets = (checked: boolean) => {
    setAllSelectedAssets(!allSelectedAssets);
    if (checked) {
      const selected = [...selectedAssetIDs];
      tableData.forEach(({ id }) => {
        if (!selected.includes(id)) {
          selected.push(id);
        }
      });

      setSelectedAssetIDs(selected);
    } else {
      const selected = selectedAssetIDs.filter((id) => {
        return !tableData.some(({ id: assetID }) => assetID === id);
      });
      setSelectedAssetIDs(selected);
    }
  };

  const assetsColumns: ColumnProps<DataWithDuplicateAssets>[] = [
    {
      key: 'all-assets',
      title: (
        <input
          type='checkbox'
          key={'check-all-assets'}
          name={'check-all-assets'}
          checked={allSelectedAssets}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            selectAllAssets(e.target.checked);
          }}
          style={{ marginRight: '3px' }}
        />
      ),
      width: 1,
      justify: 'center',
      render: ({ record: { id } }) => (
        <input
          type='checkbox'
          key={id}
          name={id}
          checked={selectedAssetIDs.includes(id)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            checkAssets(e.target.checked, id);
          }}
          style={{ marginRight: '3px' }}
        />
      ),
    },
    {
      key: 'source',
      title: 'Source',
      width: 8,
      render: AssetSourceRenderer,
      sortable: true,
    },
    { key: 'ip', dataIndex: 'ip', title: 'IP', width: 7, sortable: true },
    {
      key: 'deviceName',
      dataIndex: 'deviceName',
      title: 'Device Name',
      width: 10,
      sortable: true,
    },
    { key: 'mac', dataIndex: 'mac', title: 'MAC', width: 10, sortable: true },
    { key: 'os', dataIndex: 'os', title: 'OS', width: 10, sortable: true },
    {
      key: 'category',
      dataIndex: 'category',
      title: 'Category',
      width: 7,
      sortable: true,
    },
    {
      key: 'lastSeen',
      dataIndex: 'lastSeen',
      title: 'Last Seen',
      sortable: true,
      width: 14,
    },
    {
      key: 'lastScannedTime',
      dataIndex: 'lastScannedTime',
      title: 'Last Successful Scan',
      sortable: true,
      width: 14,
    },
    {
      key: 'manufacturer',
      dataIndex: 'manufacturer',
      title: 'Manufacturer',
      sortable: true,
    },
    {
      key: 'risk',
      dataIndex: 'risk',
      title: 'Risk Score',
      width: 5,
      render: ({ record: { risk, id } }) => (
        <RiskColumnStyle
          style={{
            background: getRiskColor(risk),
            color: 'black',
            fontWeight: 700,
          }}
          href={`/risks?assetID=${id}&fromLevel=${risk.toFixed(1)}`}
        >
          {risk.toFixed(1)}
          <i className={`fa fa-chevron-right`} />
        </RiskColumnStyle>
      ),
      sortable: true,
    },
    {
      key: 'criticality',
      sortable: true,
      dataIndex: 'criticality',
      title: 'Asset Criticality',
      justify: 'flex-start',
      width: 10,
      render: ({ record: { criticality } }) => (
        <CriticalityPill criticality={criticality} />
      ),
    },
    {
      key: 'vulns',
      dataIndex: 'vulns',
      title: 'Vulnerabilities',
      width: 7,
      justify: 'center',
      render: ({ record: { vulns, id } }) => (
        <>
          <a
            href={`/risks?assetID=${id}&fromLevel=4&source=sensor&source=reach&source=agent&state=Open&state=Mitigation%2FFix%20in%20Progress&state=Acknowledged%2C%20In-Planning&state=Fixed%2C%20Waiting%20Validation&state=Unsuccessful%20Validation&status=Active&status=Inactive&toLevelInclusive=10`}
          >
            <u>{vulns}</u>
          </a>
        </>
      ),
      sortable: true,
    },
    {
      key: 'tag',
      dataIndex: 'tag',
      title: 'Asset Tags',
      width: 10,
      render: ({ record: { assetTags } }) => (
        <TagPills tags={assetTags} tooltipPosition='left' />
      ),
    },
  ];

  assetsColumns.push({
    key: 'delete',
    title: '',
    width: 3,
    justify: 'center',
    render: ({ record }) => <AssetDeleteButton asset={record} />,
  });

  const columns = assetsColumns.filter((col) => col.visible ?? true);

  const onCSVUploadCancel = useCallback(() => {
    setUploadVisibility(false);
  }, []);

  const onCSVImportSuccess = useCallback(
    () => dispatch(getAssetDataThunk(filters)),
    [dispatch, filters]
  );

  const onCSVUploadOk = () => {
    updateAssetsByCSV_v2(
      customerId,
      servicesHost,
      tableData,
      uploadedFile,
      setUpdatedData,
      setSkippedData,
      onCSVImportSuccess,
      allTags
    );
    setUploadVisibility(false);
  };

  const handleCSVUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files.length) {
      const file = e.target.files[0];

      setFileName(file.name);
      setUploadedFile(file);
    }

    setUploadVisibility(true);
  };

  const [selectedPageLimit, setPageLimit] = React.useState<string>('5');

  const rescanMutation = useMutation({
    mutationFn: (rescanObject: RescanObject) => {
      const hostData: HostData = {
        hosts: rescanObject.hosts || [],
        source: rescanObject.source,
        clientuuids: rescanObject.clientuuids || [],
      };

      if (rescanObject.ScanName) hostData.ScanName = rescanObject.ScanName;
      if (rescanObject.ScanType) hostData.ScanType = rescanObject.ScanType;

      return new Promise<string>((resolve, reject) => {
        riskAPI
          .post(
            `/rootsecure/v1/sensor/${rescanObject.uuid}/scannow`,
            `hostdata=${JSON.stringify(hostData)}`
          )
          .then(() => {
            resolve('Successfully scanned IVA');
          })
          .catch((e) => {
            console.warn(e);
            reject('Failed to rescan IVA');
          });
      });
    },
  });

  const updateAssetsBlock = useMemo(() => {
    const deselectAssets = () => {
      setAllSelectedAssets(false);
      setSelectedAssetIDs([]);
    };

    if (!selectedAssets.length) {
      return null;
    }

    const hasEVAorFreshAgent = selectedAssets.some(isEVAorFreshAgent);

    const deleteBtn = (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Primitive.div cursor='pointer'>
            <StyledButton
              appearance='danger'
              isDisabled={hasEVAorFreshAgent}
              onClick={() => setShowBulkDeleteModal(true)}
              iconLeft={<i className='fa fa-trash' />}
            >
              Delete Selected
            </StyledButton>
          </Primitive.div>
        </Tooltip.Trigger>
        {hasEVAorFreshAgent && (
          <Tooltip.Content side='top' style={{ maxWidth: '25rem' }}>
            <Tooltip.Text
              style={{
                display: 'block',
                marginBottom: '20px',
                textAlign: 'left',
              }}
            >
              You cannot delete External Vulnerability Assessment (EVA) assets
              or Agent assets that were active in the last 24 hours.
            </Tooltip.Text>
            <Tooltip.Text
              style={{
                display: 'block',
                marginBottom: '20px',
                textAlign: 'left',
              }}
            >
              Deselect the EVA assets or recently seen Agent assets to proceed
              with your bulk deletion.
            </Tooltip.Text>
            <Tooltip.Text style={{ textAlign: 'left' }}>
              To delete EVA assets, remove them from the relevant scan
              configuration.
            </Tooltip.Text>
          </Tooltip.Content>
        )}
      </Tooltip.Root>
    );

    const hasEVA = selectedAssets.some(
      (a: iAssetCatalogDataType) => a.source === SOURCE.REACH
    );

    const rescanSelected = async (): Promise<void> => {
      setRescanIsLoading(true);

      const ivaHosts = new Map<string, string[]>(); // IVA assets need to pass in ip addresses
      const clientUuids: string[] = []; // Agent assets need to pass in client uuid

      // safe guard while EVA rescan is not yet functional
      if (hasEVA) {
        setRescanIsLoading(false);
        setSelectedAssetIDs([]);
        addToast({
          title: 'Rescan Request',
          message: 'This functionality is currently not available for EVA.',
          appearance: 'danger',
          duration: 20,
        });
        return Promise.resolve();
      }

      // no EVA assets should be selected, get ip addresses and client uuids respectively
      for (const asset of selectedAssets) {
        if (asset.sourceName === 'IVA') {
          // map scanner UUID to host IPs
          const hosts = ivaHosts.has(asset.scannerUUID)
            ? ivaHosts.get(asset.scannerUUID)
            : [];
          hosts?.push(asset.ip);
          ivaHosts.set(asset.scannerUUID, hosts ? hosts : []);
        } else if (asset.sourceName === 'Agent') {
          clientUuids.push(asset.clientUUID);
        }
      }

      // rescan IVA
      const rescanIVA: Promise<string>[] = [];
      ivaHosts.forEach((ipHosts, scannerUUID) => {
        if (ipHosts.length > 0) {
          rescanIVA.push(
            rescanMutation.mutateAsync({
              uuid: scannerUUID,
              source: 'sensor',
              hosts: [...new Set(ipHosts)],
            })
          );
        }
      });

      // rescan Agent
      const rescanAgent =
        clientUuids.length > 0
          ? rescanMutation.mutateAsync({
              uuid: customerId,
              source: 'scout',
              clientuuids: clientUuids,
              ScanType: 'vulnerability',
              ScanName: '',
            })
          : Promise.resolve('No Agent assets to rescan');

      return Promise.all([...rescanIVA, rescanAgent])
        .then(() => {
          addToast({
            title: 'Rescan Request',
            message: 'The request to rescan the selected asset(s) has started.',
            appearance: 'success',
            duration: 20,
          });
        })
        .catch(() => {
          addToast({
            title: 'Rescan Request',
            message: 'The request to rescan the selected asset(s) has failed.',
            appearance: 'danger',
            duration: 20,
          });
        })
        .finally(() => {
          setRescanIsLoading(false);
          setAllSelectedAssets(false);
          setSelectedAssetIDs([]);
        });
    };

    return (
      <StylePaginationHeader>
        <StyleSpan>{`${selectedAssets.length} item(s) selected`}</StyleSpan>
        <StyledButton
          onClick={() => setShowBulkUpdateModal(true)}
          iconLeft={<i className='fa fa-refresh' />}
        >
          Update Selected
        </StyledButton>

        <RescanControl
          isRescanDisabled={hasEVA}
          isModalOpen={showAssetRescanModal}
          isLoading={rescanIsLoading}
          modalTitle='Rescan Assets'
          modalMessage={
            <>
              Rescanning is for vulnerability scanning.
              <br />
              Are you sure you want to rescan the selected assets?
            </>
          }
          handleRescanClick={() => setShowAssetRescanModal(true)}
          handleModalConfirm={rescanSelected}
          handleModalCancel={() => setShowAssetRescanModal(false)}
        />

        <HStack position='absolute' right='0' marginRight='smedium' gap='small'>
          {deleteBtn}
          <Button
            marginTop='zero'
            marginBottom='zero'
            marginRight='smedium'
            marginLeft='small'
            variant='link'
            onClick={() => deselectAssets()}
            iconRight={<i className='fa fa-times' />}
          >
            Deselect All
          </Button>
        </HStack>
      </StylePaginationHeader>
    );
  }, [
    selectedAssets,
    showAssetRescanModal,
    rescanIsLoading,
    rescanMutation,
    customerId,
    addToast,
  ]);

  return (
    <AssetCatalogContext.Provider
      value={{
        selectedAssetIDs,
        toggleAsset: checkAssets,
        selectedCriteriaKeys: duplicateCriteria,
        onAssetsDeleteSuccess,
      }}
    >
      <WrapStyle data-testid='asset-catalog-container'>
        <HStack xAlign='between' yAlign='top' width='full'>
          <PanelTitle title={'Asset Catalog'} />
          <HStack xAlign='between' yAlign='top' gap='zero'>
            <StyledCSVLink
              data={flattenedData}
              headers={useExportAssetsHeaders()}
              filename={'Export Assets.csv'}
              onClick={() => !!flattenedData.length}
            >
              <Button isDisabled={!flattenedData.length} variant='secondary'>
                Export Assets
              </Button>
            </StyledCSVLink>
            <Button
              variant='secondary'
              onClick={() => importFileRef.current?.click()}
            >
              Import Assets
            </Button>
            <input
              style={{ display: 'none' }}
              ref={importFileRef}
              type='file'
              id='import-asset-file'
              accept='.csv'
              onChange={handleCSVUpload}
              value={''}
            />
            <StyledCSVLink
              data={flattenedData}
              headers={csvHeaders}
              filename={'Asset Catalog.csv'}
              onClick={() => !!flattenedData.length}
            >
              <DownloadCSVIcon
                disabled={!flattenedData.length}
                tooltipText={EXPORT_CSV_TOOLTIP}
              />
            </StyledCSVLink>
          </HStack>
        </HStack>
        <div style={{ overflow: 'visible' }}>
          {updateAssetsBlock}
          <AssetTable<DataWithDuplicateAssets>
            data={tableData}
            columns={columns}
            nestedRow={
              isAWN
                ? {
                    visible: (a) =>
                      !!(a.duplicateData && a.duplicateData.length > 0),
                    render: DuplicateAssetNestedRenderer,
                  }
                : undefined
            }
            setPageLimit={setPageLimit}
            pageLimit={selectedPageLimit}
            handleSort={handleSort}
            hideColumnsFromMenu={columnKeysToHideFromDropdown}
            hideDefaultTableColumn={defaultColumnsToHideFromAssetTable}
            headerChildren={
              <>
                <Button
                  margin='zero'
                  variant='tertiary'
                  onClick={() => setCreateTagModal(true)}
                  iconLeft={<i className='fa fa-tag' />}
                >
                  Asset Tag Management
                </Button>
                <DuplicateAssets
                  criteria={DUPLICATE_CRITERIA}
                  appliedCriteria={duplicateCriteria}
                  setDuplicateCriteria={setDuplicateCriteria}
                  defaultOnlyShowDuplicate={defaultOnlyShowDuplicate}
                  toggleOnlyShowDuplicate={toggleOnlyShowDuplicate}
                  defaultIsExcludeUnknown={defaultIsExcludeUnknown}
                  toggleExcludeUnknown={toggleExcludeUnknown}
                />
              </>
            }
            isProcessing={isProcessing}
          />
        </div>

        <ModalBox
          title={'Confirm Upload'}
          onCloseModal={onCSVUploadCancel}
          isOpen={visibleUpload}
          width='520px'
          footer={
            <HStack yAlign='center' xAlign='right' gap='xsmall'>
              <Button onClick={onCSVUploadOk} variant='primary'>
                Upload
              </Button>
              <Button onClick={onCSVUploadCancel} variant='secondary'>
                Close
              </Button>
            </HStack>
          }
        >
          <Primitive.div paddingX='large' paddingBottom='small'>
            {`Are you sure you would like to continue to upload ${fileName} ?`}
          </Primitive.div>
        </ModalBox>

        <AssetCreateTag
          visible={showCreateTagModal}
          setShowCreateTagModal={setCreateTagModal}
        />

        <AssetUpdatedDataModal
          data={updatedData}
          skippedData={skippedData}
          setUpdatedData={setUpdatedData}
          setSkippedData={setSkippedData}
        />

        <AssetBulkDeleteModal
          isOpen={showBulkDeleteModal}
          onClose={() => setShowBulkDeleteModal(false)}
        />

        <AssetBulkUpdate
          data={selectedAssets}
          visible={showBulkUpdateModal}
          setShowBulkUpdateModal={setShowBulkUpdateModal}
          selectedAssetIDs={selectedAssetIDs}
        />
      </WrapStyle>
    </AssetCatalogContext.Provider>
  );
};
export default AssetCatalog;
