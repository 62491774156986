/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ScheduleRow,
  ScheduleRowFromJSON,
  ScheduleRowFromJSONTyped,
  ScheduleRowToJSON,
} from './';

/**
 *
 * @export
 * @interface SchedulePage
 */
export interface SchedulePage {
  /**
   *
   * @type {Array<ScheduleRow>}
   * @memberof SchedulePage
   */
  items: Array<ScheduleRow>;
}

export function SchedulePageFromJSON(json: any): SchedulePage {
  return SchedulePageFromJSONTyped(json, false);
}

export function SchedulePageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SchedulePage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(ScheduleRowFromJSON),
  };
}

export function SchedulePageToJSON(value?: SchedulePage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(ScheduleRowToJSON),
  };
}
