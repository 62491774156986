import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AssetCatalog from './AssetCatalog';
import queryString from 'query-string';
import {
  assetSelectors,
  iAssetCatalogDataType,
} from '../../Global/assetReducer';
import { getAssetCatalogData } from './utils';
import { getAssetDataThunk } from '../../Global/assetActions';
import { customerSelectors } from '../../Global/customerReducer';

import { SOURCE } from '../../Constants/Risks';
import { RisksParams } from '../../types/risks';

import styled from 'styled-components';
import { AssetsParams } from '../../types/assets';
import Filters from '../../Reusables/Filters/index';
import { useAppDispatch } from '../../hooks';
import _ from 'lodash';

const StyledContainer = styled.div`
  margin: 0px -10px 0px -10px;
`;

const initialFilterObj: RisksParams = {
  category: [],
  search: '',
  tagID: [],
  criticality: [],
  fromLevel: 0,
  toLevelInclusive: 10,
  source: [SOURCE.SENSOR, SOURCE.REACH, SOURCE.AGENT],
  firstIdentifiedAfter: undefined,
  firstIdentifiedBefore: undefined,
  firstScannedAfter: undefined,
  firstScannedBefore: undefined,
  resolvedAfter: undefined,
  resolvedBefore: undefined,
};

const Assets = () => {
  const searchParams = window.location.search.slice(
    window.location.search.indexOf('?') + 1
  );

  const customerId = useSelector(customerSelectors.getCustomerId);
  const dispatch = useAppDispatch();
  const isProcessing = useSelector(assetSelectors.getAllAssetIsProcessing);
  const asset = useSelector(assetSelectors.getAssetData);
  const [filterObj, setFilters] = useState<RisksParams>({
    ...(initialFilterObj ? initialFilterObj : {}),
    ...getUrlFilters(
      queryString.parse(searchParams, {
        parseNumbers: true,
      })
    ),
  });

  const delayedUpdate = React.useRef(
    _.debounce((nFilters: RisksParams) => {
      const assetFilters: AssetsParams = {
        category: nFilters.category,
        fromScore: nFilters.fromLevel,
        search: nFilters.search,
        source: nFilters.source,
        toScore: nFilters.toLevelInclusive,
        afterDiscoveryDate: nFilters.firstIdentifiedAfter,
        beforeDiscoveryDate: nFilters.firstIdentifiedBefore,
        afterScanDate: nFilters.firstScannedAfter,
        beforeScanDate: nFilters.firstScannedBefore,
        tagID: nFilters.tagID,
        criticality: nFilters.criticality,
      };

      // toScore is exclusive per API, add 0.1 to it
      if (assetFilters.toScore) {
        assetFilters.toScore += 0.1;
      }

      dispatch(getAssetDataThunk(assetFilters));
    }, 500)
  ).current;

  React.useEffect(() => {
    delayedUpdate(filterObj);
  }, [filterObj, delayedUpdate]);

  const assetsCatalogData: iAssetCatalogDataType[] = useMemo(() => {
    return getAssetCatalogData(asset);
  }, [asset]);

  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setUrlFilters({ ...initialFilterObj });
      setFilters({ ...initialFilterObj });
    }
  }, [customerId]);

  const filters = useMemo(() => {
    const onFilterChange = (updatedFilters: RisksParams) => {
      setUrlFilters(updatedFilters);
      setFilters(updatedFilters);
    };

    return (
      <Filters
        assets={asset.assets}
        filters={filterObj}
        multiSearch
        initialFilters={initialFilterObj}
        defaultRiskScores={[...Array(11).keys()]}
        handleFilters={onFilterChange}
        showLastSuccessfulDate
      />
    );
  }, [asset.assets, filterObj]);

  return (
    <Fragment>
      <StyledContainer>
        <div className='row'>
          <div className='col-md-12 col-sm-12 col-xs-12'>
            {filters}
            <AssetCatalog
              filters={filterObj}
              data={assetsCatalogData}
              isProcessing={isProcessing}
            />
          </div>
        </div>
      </StyledContainer>
    </Fragment>
  );
};

export default Assets;

const getUrlFilters = (inputFilters: RisksParams | undefined) => {
  const urlFilters: RisksParams = {
    category: [],
    search: '',
    fromLevel: 0,
    toLevelInclusive: 10,
    source: [SOURCE.SENSOR, SOURCE.REACH, SOURCE.AGENT],
    firstIdentifiedAfter: undefined,
    firstIdentifiedBefore: undefined,
    firstScannedAfter: undefined,
    firstScannedBefore: undefined,
    resolvedAfter: undefined,
    resolvedBefore: undefined,
    tagID: [],
    criticality: [],
  };
  if (!inputFilters) {
    return urlFilters;
  }
  return { ...urlFilters, ...inputFilters };
};

const setUrlFilters = (filters: RisksParams): void => {
  const urlParams: RisksParams = {
    category: filters.category,
    source: filters.source,
    firstIdentifiedBefore: filters.firstIdentifiedBefore,
    firstIdentifiedAfter: filters.firstIdentifiedAfter,
    firstScannedBefore: filters.firstScannedBefore,
    firstScannedAfter: filters.firstScannedAfter,
    fromLevel: filters.fromLevel,
    toLevelInclusive: filters.toLevelInclusive,
    tagID: filters.tagID,
    criticality: filters.criticality,
    page: filters.page,
  };
  if (!!filters.search) {
    urlParams.search = filters.search;
  }
  const stringified = queryString.stringify(urlParams);
  let newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname;
  if (stringified) newUrl += '?' + stringified;
  window.history.pushState({ path: newUrl }, '', newUrl);
};
