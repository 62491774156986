/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskCount
 */
export interface RiskCount {
    /**
     * 
     * @type {number}
     * @memberof RiskCount
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof RiskCount
     */
    delta: number;
}

export function RiskCountFromJSON(json: any): RiskCount {
    return RiskCountFromJSONTyped(json, false);
}

export function RiskCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'delta': json['delta'],
    };
}

export function RiskCountToJSON(value?: RiskCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'delta': value.delta,
    };
}


