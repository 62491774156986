/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TicketStatus,
  TicketStatusFromJSON,
  TicketStatusFromJSONTyped,
  TicketStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   *
   * @type {number}
   * @memberof Ticket
   */
  id: number;
  /**
   *
   * @type {TicketStatus}
   * @memberof Ticket
   */
  status: TicketStatus;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  requester: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof Ticket
   */
  canSolve: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Ticket
   */
  canReply: boolean;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  type: string;
  /**
   * array of usernames
   * @type {Array<string>}
   * @memberof Ticket
   */
  cc: Array<string>;
}

export function TicketFromJSON(json: any): Ticket {
  return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Ticket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: TicketStatusFromJSON(json['status']),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    requester: json['requester'],
    subject: json['subject'],
    description: json['description'],
    canSolve: json['can_solve'],
    canReply: json['can_reply'],
    type: json['type'],
    cc: json['cc'],
  };
}

export function TicketToJSON(value?: Ticket | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: TicketStatusToJSON(value.status),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    requester: value.requester,
    subject: value.subject,
    description: value.description,
    can_solve: value.canSolve,
    can_reply: value.canReply,
    type: value.type,
    cc: value.cc,
  };
}
