import { Table } from '../../Reusables/table/Table';
import React from 'react';
import styled from 'styled-components';
import { BLUE } from '../../Constants/Styles';
import { Observation } from '../../types/risks';
import {
  ffLittleWinsMR20240528,
  isFeatureEnabled,
} from '../../../public/js/features';

const StyledContainer = styled.div<{ $isModal: boolean }>`
  width: ${(props) => (props.$isModal ? '1080px' : 'auto')};
  margin: ${(props) => (props.$isModal ? '40px' : '0')};
  max-height: 90vh;
  overflow: auto;
`;

const StyledTitle = styled.h3`
  margin-left: 14px;
  font-family: Lato;
  font-style: normal;
  color: ${BLUE.primary};
`;

const StyledTextFF = styled.p`
  margin-top: 1rem;
  font-family: monospace;
  font-size: 13px;
  white-space: pre-wrap;
  background: #efefef;
  padding: 0.5em;
  border-radius: 0.25em;
`;

const StyledText = styled.p`
  margin-left: 15px;
  max-width: 1000px;
  font-family: Lato;
  font-style: normal;
`;

const StyledTable = styled(Table)`
  tr:last-child > td {
    border-bottom: none;
  }
`;

interface Props {
  observations: Observation[];
  isModal?: boolean;
}

export const Observations: React.FC<Props> = ({
  observations,
  isModal = true,
}) => {
  return (
    <StyledContainer $isModal={isModal}>
      {isModal && <StyledTitle>Observation</StyledTitle>}
      {observations?.map((observation: Observation, index: number) => {
        if (observation.table) {
          observation.table.formattedRows = [];
          observation.table.rows?.forEach((row: string[], index: number) => {
            let newRow = { key: index };
            row.forEach((value: string, vindex: number) => {
              newRow = {
                ...newRow,
                [observation.table.columns[vindex]]: value,
              };
            });
            observation.table?.formattedRows.push(newRow);
          });
        }
        return (
          <div key={`observation-${index}`}>
            {observation.text &&
              (isFeatureEnabled(ffLittleWinsMR20240528) ? (
                <StyledTextFF>{observation.text}</StyledTextFF>
              ) : (
                <StyledText>{observation.text}</StyledText>
              ))}
            {observation.table &&
              observation.table.columns &&
              (isFeatureEnabled(ffLittleWinsMR20240528) ? (
                <StyledTable
                  data={observation.table.formattedRows}
                  columns={observation.table.columns.map((col: string) => ({
                    key: col,
                    title: col,
                    dataIndex: col,
                    width: 100 / observation.table.columns.length,
                  }))}
                  emptyComponent={<div>No Data Available</div>}
                />
              ) : (
                // @ts-ignore
                <Table
                  data={observation.table.formattedRows}
                  columns={observation.table.columns.map((col: string) => ({
                    key: col,
                    title: col,
                    dataIndex: col,
                    width: 100 / observation.table.columns.length,
                  }))}
                  emptyComponent={<div>No Data Available</div>}
                />
              ))}
          </div>
        );
      })}
    </StyledContainer>
  );
};
