import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { MaintenanceBanner } from '../../Reusables/MaintenanceBanner';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Tile from './Tile';
import { useQueryRiskScoreDetails } from './utils';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import RiskScore from '../../Modals/Modals/RiskScore';
import { ComingSoonBanner } from '../../Reusables/ComingSoonBanner';
import {
  ffBenchmarkRiskScore,
  ffJulyReleaseV3,
  isFeatureEnabled,
} from '../../../public/js/features';
import { END_TIME, MESSAGE } from '../../../public/js/MaintenanceConstants';

const StyledContainer = styled.div`
  padding: 0px 20px;
`;

const TopTiles = () => {
  const [showModal, setShowModal] = useState(false);
  const { data, isError } = useQueryRiskScoreDetails();

  React.useEffect(() => {
    if (isError) {
      toastr.error('Failed to fetch Risk Score details', '');
    }
  }, [isError]);

  const openScoreModal = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const RisksList = React.useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const firstIdentifiedAfter = Math.round(date.getTime() / 1000);

    return {
      'Current Risk Score': {
        icon: 'fa fa-gear',
        value: data?.latest?.score || 0,
        displayValue: data?.latest?.score?.toFixed(1),
        lastValue: data?.latest?.delta || 0,
        helpText: 'Click here to view how we calculated this score.',
        iconId: 'js-risk-score-icon',
        href: 'risks',
      },
      'Benchmark Risk Score': {
        icon: 'fa fa-group',
        value: data?.industry?.score || 0,
        displayValue: data?.industry?.score?.toFixed(1),
        lastValue: data?.industry?.delta || 0,
        helpText: isFeatureEnabled(ffBenchmarkRiskScore)
          ? 'Your score compared to other Arctic Wolf customers in your region, updated daily.'
          : 'Your score compared to other Arctic Wolf customers in the same industry, updated daily.',
        iconId: 'js-industry-score-icon',
        href: 'risks?unresolvedOnly=true&fromLevel=4&acknowledged=false',
      },
      'Unresolved Risks': {
        icon: 'fa fa-fire',
        value: data?.unresolvedRisks?.count || 0,
        lastValue: data?.unresolvedRisks?.delta || 0,
        helpText:
          'The current number of active medium to critical severity vulnerabilities in the network, updated automatically.',
        iconId: 'js-unresolved-score-icon',
        href: 'risks?fromLevel=4&state=Open&state=Fixed%2C%20Waiting%20Validation&state=Acknowledged%2C%20In-Planning&state=Mitigation%2FFix%20in%20Progress&state=Unsuccessful%20Validation&status=Active&status=Inactive',
      },
      'New Risks': {
        icon: 'fa fa-bullseye',
        value: data?.newRisks?.count || 0,
        lastValue: data?.newRisks?.delta || 0,
        helpText: 'New risks that were discovered in the last 30 days.',
        iconId: 'js-new-score-icon',
        href: `risks?firstIdentifiedAfter=${firstIdentifiedAfter}&fromLevel=4&status=Active&status=Inactive`,
      },
      'Mitigated Risks': {
        icon: 'fa fa-fire-extinguisher',
        value: data?.mitigatedRisks?.count || 0,
        lastValue: data?.mitigatedRisks?.delta || 0,
        helpText: 'The risks that were resolved in the last 90 days.',
        iconId: 'js-mitigated-score-icon',
        positiveIncrease: true,
        href: 'risks?fromLevel=4&status=Obsolete&status=Mitigated',
      },
      'Accepted Risks': {
        icon: 'fa fa-check',
        value: data?.acceptedRisks?.count || 0,
        lastValue: data?.acceptedRisks?.delta || 0,
        helpText:
          'The number of risks that you have acknowledged and are no longer included in your risk score.',
        iconId: 'js-accepted-score-icon',
        href: 'risks?fromLevel=4&state=Accepted&status=Active&status=Inactive',
      },
    };
  }, [data]);

  return (
    <Fragment>
      <StyledContainer>
        <MaintenanceBanner endTime={END_TIME} message={MESSAGE} />
      </StyledContainer>
      <StyledContainer className='row tile_count'>
        {Object.entries(RisksList).map(([key, data]) => (
          <Tile
            {...data}
            key={key}
            title={
              key === 'Benchmark Risk Score'
                ? isFeatureEnabled(ffBenchmarkRiskScore)
                  ? 'Benchmark Risk Score'
                  : 'Industry Risk Score'
                : key
            }
            onIconClick={
              key === 'Current Risk Score' ? openScoreModal : undefined
            }
          />
        ))}
      </StyledContainer>
      <StyledContainer>
        <ComingSoonBanner featureFlagName={ffJulyReleaseV3} />
      </StyledContainer>
      <div className='clearfix' />

      {showModal ? (
        <ModalBox
          onCloseModal={() => setShowModal(false)}
          position={ModalPositionType.top}
        >
          <RiskScore />
        </ModalBox>
      ) : null}
    </Fragment>
  );
};

export default TopTiles;
