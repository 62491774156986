import React, { useEffect, useState } from 'react';
import moment from 'moment';
import riskAPI from '../../utils/riskAPI';
import { customerSelectors } from '../../Global/customerReducer';
import { useSelector } from 'react-redux';

const Score = (score: any) => (
  <p className='risk-score-severity-score'>{score?.toFixed(1)}</p>
);

const Severity = (severity: any) => (
  <div className='risk-score-severity'>
    <p>{severity?.severity}</p>
    <p>Count: {severity?.count}</p>
    <p>Weight: {severity?.weight}</p>
    <p>Average Score: {severity?.avgScore.toFixed(1)}</p>
    <p>Scores:</p>
    <div className='risk-score-severity-scores'>
      {severity?.scores?.map(Score)}
    </div>
  </div>
);

const Category = ({ category }: any) => (
  <div className='risk-score-category'>
    <p>Category: {category.category}</p>
    <p>Score: {Math.round(category.score * 10) / 10}</p>
    <p>Severities: </p>
    <div>{category?.severities?.map(Severity)}</div>
  </div>
);

export const RiskScore = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [riskData, setRiskData] = useState<any>({});

  useEffect(() => {
    riskAPI
      .get(
        `/rootsecure/rida/v1/customers/${customerId}/riskSummaries?iskSummaries?createdAfter=${moment().unix()}&includeRiskScoreDetails=true`
      )
      .then((response) => {
        const data = response.data.data;
        setRiskData(data[data.length - 1].summaryData.scoreDetail);
      })
      .catch((error) => console.error(JSON.stringify(error)));
  }, [customerId]);
  return (
    <div style={{ width: '1200px' }}>
      <div className='modal-content risk-score-modal-container'>
        <div className='modal-body' id='risk-score-modal-body'>
          <h3 className='risk-score-title'>Risk Score</h3>
          <p>This is how we calculated your risk score.</p>
          <h4 className='risk-score-subtitle' id='js-risk-score'>
            {Math.round(riskData?.score * 10) / 10}
          </h4>
          <div className='risk-score-data' id='js-risk-score-data'>
            {riskData?.categories?.map((category: any) => (
              <Category category={category} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskScore;
