/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AgentStatus,
  AgentStatusFromJSON,
  AgentStatusFromJSONTyped,
  AgentStatusToJSON,
} from './';

/**
 * a high-level overview of a single agent suitable for rendering in a table
 * @export
 * @interface AgentRow
 */
export interface AgentRow {
  /**
   *
   * @type {string}
   * @memberof AgentRow
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AgentRow
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof AgentRow
   */
  hostname: string;
  /**
   *
   * @type {AgentStatus}
   * @memberof AgentRow
   */
  status: AgentStatus;
  /**
   *
   * @type {string}
   * @memberof AgentRow
   */
  osName: string;
  /**
   *
   * @type {string}
   * @memberof AgentRow
   */
  sysmonVersion: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AgentRow
   */
  ipAddresses: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AgentRow
   */
  macAddresses?: Array<string>;
  /**
   * A date conveyed in RFC3339 format
   * @type {Date}
   * @memberof AgentRow
   */
  lastReported: Date;
}

export function AgentRowFromJSON(json: any): AgentRow {
  return AgentRowFromJSONTyped(json, false);
}

export function AgentRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgentRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    version: json['version'],
    hostname: json['hostname'],
    status: AgentStatusFromJSON(json['status']),
    osName: json['osName'],
    sysmonVersion: json['sysmonVersion'],
    ipAddresses: json['ipAddresses'],
    macAddresses: !exists(json, 'macAddresses')
      ? undefined
      : json['macAddresses'],
    lastReported: new Date(json['lastReported']),
  };
}

export function AgentRowToJSON(value?: AgentRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    version: value.version,
    hostname: value.hostname,
    status: AgentStatusToJSON(value.status),
    osName: value.osName,
    sysmonVersion: value.sysmonVersion,
    ipAddresses: value.ipAddresses,
    macAddresses: value.macAddresses,
    lastReported: value.lastReported.toISOString(),
  };
}
