import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  HStack,
  Primitive,
  Select,
  TextField,
} from '@rtkwlf/fenrir-react';
import { BLUE, GREY } from '../../Constants/Styles';
import { iGetState } from '../../configureStore';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import qs from 'qs';
import moment from 'moment';
import riskAPI from '../../utils/riskAPI';
import { useAppDispatch } from '../../hooks';
import { getAuthToken } from '../../utils/auth/authentication';
import {
  ffLittleWinsMR20240528,
  isFeatureEnabled,
} from '../../../public/js/features';

const StyledRow = styled.div`
  padding: 10px 22px;
  display: flex;
  gap: 2rem;
`;

const StyledCheckRow = styled.div`
  padding: 10px 22px 0px 22px;
  display: flex;
  gap: 2rem;
`;

const WarningText = styled.div`
  padding-top: 0px;
  padding-left: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  display: flex;
  gap: 2rem;
`;

const StyledTitle = styled.h2`
  margin-left: 10px;
  color: ${BLUE.primary};
  font-size: 24px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const StyledCheckbox = styled.input`
  &&& {
    margin: auto 5px;
  }
`;

const StyledLabel = styled.label`
  font-family: Lato;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const StyledBottomRow = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  background-color: ${GREY.secondary};
`;

// TODO: this is hardly a thunk. disconnect it from Redux and make it a plain async function.
export const getReportThunk =
  (
    summary: boolean,
    minScore: number,
    options: { [key: string]: string | boolean }
  ) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const accessToken = await getAuthToken();
    const { config, customer } = getState();

    const reportData = {
      clevel: {
        criskscore: true,
        iriskscore: true,
        uriskcount: true,
        scoretrend: true,
        top5: true,
        rbysev: true,
        rbyinfra: true,
        rbyclass: true,
        arisks: true,
        scans: true,
        indet: true,
        support: true,
        notify: true,
      },
      devicemap: {
        heatmap: true,
        assetmap: false,
      },
      actionlist: {
        actionlist: true,
        acceptedlist: true,
        minrisk: summary ? 9.0 : minScore,
        maxcount: isFeatureEnabled(ffLittleWinsMR20240528) ? 1000 : -1,
      },
      custom: {
        title: summary
          ? 'Arctic Wolf Executive Risk Summary'
          : 'Arctic Wolf Risk Assessment Report',
        uuid: customer.currentCustomer.id,
        // XXX disable partner styled reports
        partnerId: 0, //getPartnerId(),
        preparedfor: options.preparedFor || '',
      },
    };

    if (!options.networkRiskCheckBox) {
      reportData.clevel.criskscore = false;
      reportData.clevel.iriskscore = false;
      reportData.clevel.uriskcount = false;
    }
    if (!options.scoreTrendsCheckBox) {
      reportData.clevel.scoretrend = false;
    }
    if (!options.riskSummaryCheckBox) {
      reportData.clevel.top5 = false;
      reportData.clevel.rbysev = false;
    }
    if (!options.classSummaryCheckBox) {
      reportData.clevel.rbyinfra = false;
      reportData.clevel.rbyclass = false;
    }
    if (!options.atWorkSummaryCheckBox) {
      reportData.clevel.arisks = false;
      reportData.clevel.scans = false;
      reportData.clevel.indet = false;
      reportData.clevel.support = false;
      reportData.clevel.notify = false;
    }
    if (!options.heatMapCheckBox) {
      reportData.devicemap.heatmap = false;
    }
    if (!options.idRisksCheckBox) {
      reportData.actionlist.actionlist = false;
    }
    if (!options.acceptedRisksCheckBox) {
      reportData.actionlist.acceptedlist = false;
    }

    toastr.message(
      'Preparing Report',
      summary
        ? 'Arctic Wolf Executive Risk Summary'
        : 'Arctic Wolf Risk Assessment Report'
    );
    return riskAPI
      .post(
        `${config.environment.servicesHost}/rootsecure/v2/report/${customer.currentCustomer.id}?format=pdf`,
        qs.stringify({ userdata: JSON.stringify(reportData) }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response: any) => {
        fetch(`${config.environment.servicesHost}${response.data}`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: any) => response.blob())
          .then((blob) => {
            toastr.removeByType('message');
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${
                summary ? 'Executive Summary' : 'Risk Assessment'
              } ${moment().format('LLLL')}.pdf`
            );
            // 3. Append to html page
            // deepcode ignore DOMXSS: The data used to create this download comes from rootsecure
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode?.removeChild(link);
          })
          .catch((error: any) => {
            toastr.removeByType('message');
            toastr.error('Failed to load report', error);
          });
      })
      .catch((error: any) => {
        toastr.removeByType('message');
        toastr.error('Failed to load report', error);
      });
  };

type Props = {
  summary: boolean;
  onClose: () => void;
};

const minRiskOptions = [...Array(9).keys()].map((k) => ({
  value: String(k + 1),
  text: String(k + 1),
}));

export const PdfFilter: React.FC<Props> = ({ summary, onClose }) => {
  const pdfFFEnabled = isFeatureEnabled(ffLittleWinsMR20240528);
  const dispatch = useAppDispatch();
  const [preparedFor, setPreparedFor] = useState('');
  const [networkRiskCheckBox, setNetworkRiskCheckBox] = useState(true);
  const [scoreTrendsCheckBox, setScoreTrendsCheckBox] = useState(true);
  const [riskSummaryCheckBox, setRiskSummaryCheckBox] = useState(true);
  const [classSummaryCheckBox, setClassSummaryCheckBox] = useState(true);
  const [atWorkSummaryCheckBox, setAtWorkSummaryCheckBox] = useState(true);
  const [heatMapCheckBox, setHeatMapCheckBox] = useState(true);
  const [idRisksCheckBox, setIdRisksCheckBox] = useState(!pdfFFEnabled);
  const [acceptedRisksCheckBox, setAcceptedRisksCheckBox] =
    useState(!pdfFFEnabled);
  const [minScore, setMinScore] = useState(minRiskOptions[8].value);

  return (
    <Fragment>
      <StyledRow>
        <StyledTitle>
          {summary ? 'Executive Summary' : 'Risk Assessment'}
        </StyledTitle>
      </StyledRow>

      <HStack width='full' paddingX='large'>
        <Primitive.div width='full'>
          <StyledLabel>Prepared For</StyledLabel>
          <TextField
            value={preparedFor}
            data-lpignore='true'
            autoComplete='off'
            onChange={(e) => setPreparedFor(e.target.value)}
          />
        </Primitive.div>
        {summary === false && (
          <Primitive.div width='10rem'>
            <StyledLabel>Min Score</StyledLabel>
            <Select
              isClearable={false}
              value={minScore}
              options={minRiskOptions}
              onSelection={(option) => setMinScore(option!.value)}
            />
          </Primitive.div>
        )}
      </HStack>

      <StyledCheckRow>
        <StyledColumn>
          <StyledLabel>
            Network Risk Summary
            <StyledCheckbox
              type='checkbox'
              checked={networkRiskCheckBox}
              onChange={(e: any) => setNetworkRiskCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            Risk Severity Summary
            <StyledCheckbox
              type='checkbox'
              checked={riskSummaryCheckBox}
              onChange={(e: any) => setRiskSummaryCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            30 Days Summary
            <StyledCheckbox
              type='checkbox'
              checked={atWorkSummaryCheckBox}
              onChange={(e: any) => setAtWorkSummaryCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            Identified Risks
            <StyledCheckbox
              type='checkbox'
              checked={idRisksCheckBox}
              onChange={(e: any) => setIdRisksCheckBox(e.target.checked)}
            />
          </StyledLabel>
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>
            Risk Score Trends
            <StyledCheckbox
              type='checkbox'
              checked={scoreTrendsCheckBox}
              onChange={(e: any) => setScoreTrendsCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            Risk classification Summary
            <StyledCheckbox
              type='checkbox'
              checked={classSummaryCheckBox}
              onChange={(e: any) => setClassSummaryCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            Network Risk Overview
            <StyledCheckbox
              type='checkbox'
              checked={heatMapCheckBox}
              onChange={(e: any) => setHeatMapCheckBox(e.target.checked)}
            />
          </StyledLabel>
          <StyledLabel>
            Accepted Risks
            <StyledCheckbox
              type='checkbox'
              checked={acceptedRisksCheckBox}
              onChange={(e: any) => setAcceptedRisksCheckBox(e.target.checked)}
            />
          </StyledLabel>
        </StyledColumn>
      </StyledCheckRow>
      {pdfFFEnabled && (
        <WarningText>
          * Up to 1000 Risks will be included. All Risks can be downloaded as
          CSV
        </WarningText>
      )}

      <StyledBottomRow>
        <Button
          onClick={() => {
            dispatch(
              getReportThunk(summary, Number(minScore), {
                preparedFor,
                networkRiskCheckBox,
                scoreTrendsCheckBox,
                riskSummaryCheckBox,
                classSummaryCheckBox,
                atWorkSummaryCheckBox,
                heatMapCheckBox,
                idRisksCheckBox,
                acceptedRisksCheckBox,
              })
            );

            onClose();
          }}
        >
          Download PDF
        </Button>
      </StyledBottomRow>
    </Fragment>
  );
};
