/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CredentialField,
  CredentialFieldFromJSON,
  CredentialFieldFromJSONTyped,
  CredentialFieldToJSON,
} from './';

/**
 *
 * @export
 * @interface Credential
 */
export interface Credential {
  /**
   * Returns the user-displayable name of the credential type
   * @type {string}
   * @memberof Credential
   */
  displayName: string;
  /**
   * True for cloud service monitoring that is included as part of the base service for no additional cost to customer
   * @type {boolean}
   * @memberof Credential
   */
  noCharge: boolean;
  /**
   * Image representing the credential
   * @type {string}
   * @memberof Credential
   */
  image: string;
  /**
   * URL to setup guide
   * @type {string}
   * @memberof Credential
   */
  setupGuide: string | null;
  /**
   *
   * @type {Array<CredentialField>}
   * @memberof Credential
   */
  fields: Array<CredentialField>;
  /**
   *
   * @type {string}
   * @memberof Credential
   */
  name?: string;
  /**
   * One of 'log' or 'scan'
   * @type {string}
   * @memberof Credential
   */
  purpose?: string;
}

export function CredentialFromJSON(json: any): Credential {
  return CredentialFromJSONTyped(json, false);
}

export function CredentialFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Credential {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    displayName: json['displayName'],
    noCharge: json['noCharge'],
    image: json['image'],
    setupGuide: json['setupGuide'],
    fields: (json['fields'] as Array<any>).map(CredentialFieldFromJSON),
    name: !exists(json, 'name') ? undefined : json['name'],
    purpose: !exists(json, 'purpose') ? undefined : json['purpose'],
  };
}

export function CredentialToJSON(value?: Credential | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    displayName: value.displayName,
    noCharge: value.noCharge,
    image: value.image,
    setupGuide: value.setupGuide,
    fields: (value.fields as Array<any>).map(CredentialFieldToJSON),
    name: value.name,
    purpose: value.purpose,
  };
}
