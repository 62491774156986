/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CancelIvaScansInputTargets
 */
export interface CancelIvaScansInputTargets {
  /**
   * an IPv4 or IPv6 address
   * @type {string}
   * @memberof CancelIvaScansInputTargets
   */
  target?: string;
  /**
   *
   * @type {string}
   * @memberof CancelIvaScansInputTargets
   */
  scanType?: string;
}

export function CancelIvaScansInputTargetsFromJSON(
  json: any
): CancelIvaScansInputTargets {
  return CancelIvaScansInputTargetsFromJSONTyped(json, false);
}

export function CancelIvaScansInputTargetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelIvaScansInputTargets {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    target: !exists(json, 'target') ? undefined : json['target'],
    scanType: !exists(json, 'scanType') ? undefined : json['scanType'],
  };
}

export function CancelIvaScansInputTargetsToJSON(
  value?: CancelIvaScansInputTargets | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    target: value.target,
    scanType: value.scanType,
  };
}
