/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ManageAgentScheduleState,
  ManageAgentScheduleStateFromJSON,
  ManageAgentScheduleStateFromJSONTyped,
  ManageAgentScheduleStateToJSON,
} from './';

/**
 * Input to request for enabling/disabling agent scan schedules
 * @export
 * @interface ManageAgentScheduleStateInput
 */
export interface ManageAgentScheduleStateInput {
  /**
   *
   * @type {Array<ManageAgentScheduleState>}
   * @memberof ManageAgentScheduleStateInput
   */
  schedules?: Array<ManageAgentScheduleState>;
}

export function ManageAgentScheduleStateInputFromJSON(
  json: any
): ManageAgentScheduleStateInput {
  return ManageAgentScheduleStateInputFromJSONTyped(json, false);
}

export function ManageAgentScheduleStateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManageAgentScheduleStateInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    schedules: !exists(json, 'schedules')
      ? undefined
      : (json['schedules'] as Array<any>).map(ManageAgentScheduleStateFromJSON),
  };
}

export function ManageAgentScheduleStateInputToJSON(
  value?: ManageAgentScheduleStateInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    schedules:
      value.schedules === undefined
        ? undefined
        : (value.schedules as Array<any>).map(ManageAgentScheduleStateToJSON),
  };
}
