/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AttachmentFile,
  AttachmentFileFromJSON,
  AttachmentFileFromJSONTyped,
  AttachmentFileToJSON,
} from './';

/**
 * Comments that get attached to returned tickets
 * @export
 * @interface TicketComment
 */
export interface TicketComment {
  /**
   *
   * @type {number}
   * @memberof TicketComment
   */
  id: number;
  /**
   * Name of the user who created the comment
   * @type {string}
   * @memberof TicketComment
   */
  requester: string;
  /**
   * true if the requester is a zendesk agent
   * @type {boolean}
   * @memberof TicketComment
   */
  requesterAgent: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketComment
   */
  createdAt?: string;
  /**
   * comment body
   * @type {string}
   * @memberof TicketComment
   */
  description: string;
  /**
   *
   * @type {Array<AttachmentFile>}
   * @memberof TicketComment
   */
  attachments?: Array<AttachmentFile>;
}

export function TicketCommentFromJSON(json: any): TicketComment {
  return TicketCommentFromJSONTyped(json, false);
}

export function TicketCommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketComment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    requester: json['requester'],
    requesterAgent: json['requester_agent'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    description: json['description'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentFileFromJSON),
  };
}

export function TicketCommentToJSON(value?: TicketComment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    requester: value.requester,
    requester_agent: value.requesterAgent,
    created_at: value.createdAt,
    description: value.description,
    attachments:
      value.attachments === undefined
        ? undefined
        : (value.attachments as Array<any>).map(AttachmentFileToJSON),
  };
}
