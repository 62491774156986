/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input to add/update the Federated Auth connection
 * @export
 * @interface FederatedAuthInput
 */
export interface FederatedAuthInput {
  /**
   * Client ID provided by the Customer
   * @type {string}
   * @memberof FederatedAuthInput
   */
  clientId: string;
  /**
   * Client secret provided by the Customer
   * @type {string}
   * @memberof FederatedAuthInput
   */
  clientSecret: string;
  /**
   * URL of the discovery document of the OpenID Connect provider
   * @type {string}
   * @memberof FederatedAuthInput
   */
  issuerUrl: string;
  /**
   * Type of connection
   * @type {string}
   * @memberof FederatedAuthInput
   */
  type: FederatedAuthInputTypeEnum;
  /**
   * Customer domain name
   * @type {string}
   * @memberof FederatedAuthInput
   */
  domainName: string;
  /**
   * Customer domain aliases
   * @type {Array<string>}
   * @memberof FederatedAuthInput
   */
  domainAliases?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum FederatedAuthInputTypeEnum {
  BackChannel = 'back_channel',
  FrontChannel = 'front_channel',
}

export function FederatedAuthInputFromJSON(json: any): FederatedAuthInput {
  return FederatedAuthInputFromJSONTyped(json, false);
}

export function FederatedAuthInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FederatedAuthInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    clientId: json['clientId'],
    clientSecret: json['clientSecret'],
    issuerUrl: json['issuerUrl'],
    type: json['type'],
    domainName: json['domainName'],
    domainAliases: !exists(json, 'domainAliases')
      ? undefined
      : json['domainAliases'],
  };
}

export function FederatedAuthInputToJSON(
  value?: FederatedAuthInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    clientId: value.clientId,
    clientSecret: value.clientSecret,
    issuerUrl: value.issuerUrl,
    type: value.type,
    domainName: value.domainName,
    domainAliases: value.domainAliases,
  };
}
