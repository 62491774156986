/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReportRecipient,
  ReportRecipientFromJSON,
  ReportRecipientFromJSONTyped,
  ReportRecipientToJSON,
} from './';

/**
 *
 * @export
 * @interface ScheduledReport
 */
export interface ScheduledReport {
  /**
   *
   * @type {string}
   * @memberof ScheduledReport
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledReport
   */
  schedule: string;
  /**
   *
   * @type {Date}
   * @memberof ScheduledReport
   */
  nextReport: Date;
  /**
   * a list of primary users that the report will be sent to
   * @type {Array<ReportRecipient>}
   * @memberof ScheduledReport
   */
  recipientsTo: Array<ReportRecipient>;
  /**
   * a list of secondary users that the report will be sent to
   * @type {Array<ReportRecipient>}
   * @memberof ScheduledReport
   */
  recipientsCc: Array<ReportRecipient>;
}

export function ScheduledReportFromJSON(json: any): ScheduledReport {
  return ScheduledReportFromJSONTyped(json, false);
}

export function ScheduledReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScheduledReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json['title'],
    schedule: json['schedule'],
    nextReport: new Date(json['nextReport']),
    recipientsTo: (json['recipientsTo'] as Array<any>).map(
      ReportRecipientFromJSON
    ),
    recipientsCc: (json['recipientsCc'] as Array<any>).map(
      ReportRecipientFromJSON
    ),
  };
}

export function ScheduledReportToJSON(value?: ScheduledReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    schedule: value.schedule,
    nextReport: value.nextReport.toISOString(),
    recipientsTo: (value.recipientsTo as Array<any>).map(ReportRecipientToJSON),
    recipientsCc: (value.recipientsCc as Array<any>).map(ReportRecipientToJSON),
  };
}
