/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ScannerStatus,
  ScannerStatusFromJSON,
  ScannerStatusFromJSONTyped,
  ScannerStatusToJSON,
} from './';

/**
 * a high-level overview of a single scanner suitable for rendering in a table
 * @export
 * @interface ScannerRow
 */
export interface ScannerRow {
  /**
   *
   * @type {string}
   * @memberof ScannerRow
   */
  id: string;
  /**
   * a human-readable name for the sensor that's meaningful to the customer
   * @type {string}
   * @memberof ScannerRow
   */
  name: string;
  /**
   *
   * @type {ScannerStatus}
   * @memberof ScannerRow
   */
  status: ScannerStatus;
  /**
   * Indicates the type of Scanner - physical or virtual
   * @type {string}
   * @memberof ScannerRow
   */
  type: string;
  /**
   * Scanner version
   * @type {string}
   * @memberof ScannerRow
   */
  version: string;
  /**
   * an IPv4 or IPv6 address
   * @type {string}
   * @memberof ScannerRow
   */
  ip: string;
  /**
   *
   * @type {string}
   * @memberof ScannerRow
   */
  deploymentID?: string;
}

export function ScannerRowFromJSON(json: any): ScannerRow {
  return ScannerRowFromJSONTyped(json, false);
}

export function ScannerRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScannerRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: ScannerStatusFromJSON(json['status']),
    type: json['type'],
    version: json['version'],
    ip: json['ip'],
    deploymentID: !exists(json, 'deploymentID')
      ? undefined
      : json['deploymentID'],
  };
}

export function ScannerRowToJSON(value?: ScannerRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: ScannerStatusToJSON(value.status),
    type: value.type,
    version: value.version,
    ip: value.ip,
    deploymentID: value.deploymentID,
  };
}
