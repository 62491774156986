/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SensorStatus,
    SensorStatusFromJSON,
    SensorStatusFromJSONTyped,
    SensorStatusToJSON,
} from './';

/**
 * a high-level overview of a single sensor suitable for rendering in a table
 * @export
 * @interface SensorRow
 */
export interface SensorRow {
    /**
     * a human-readable name for the sensor that's meaningful to the customer
     * @type {string}
     * @memberof SensorRow
     */
    name: string;
    /**
     * the serial number of the sensor; commonly used as a primary key for lookups
     * @type {string}
     * @memberof SensorRow
     */
    serialNumber: string;
    /**
     * the ID of the deployment as reported by the customer directory; usually consists of the customer ID followed by a number
     * @type {string}
     * @memberof SensorRow
     */
    deploymentID: string;
    /**
     * 
     * @type {SensorStatus}
     * @memberof SensorRow
     */
    status: SensorStatus;
    /**
     * the "product type" of the sensor; usually "virtual" or "physical"
     * @type {string}
     * @memberof SensorRow
     */
    product: string;
    /**
     * the "series" of the sensor
     * @type {string}
     * @memberof SensorRow
     */
    model: string;
    /**
     * how the sensor is configured; "inline", "internal tap", etc.
     * @type {string}
     * @memberof SensorRow
     */
    config: string;
    /**
     * an IPv4 or IPv6 address
     * @type {string}
     * @memberof SensorRow
     */
    ip: string;
    /**
     * A date conveyed in RFC3339 format
     * @type {Date}
     * @memberof SensorRow
     */
    shippedDate?: Date | null;
}

export function SensorRowFromJSON(json: any): SensorRow {
    return SensorRowFromJSONTyped(json, false);
}

export function SensorRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'serialNumber': json['serialNumber'],
        'deploymentID': json['deploymentID'],
        'status': SensorStatusFromJSON(json['status']),
        'product': json['product'],
        'model': json['model'],
        'config': json['config'],
        'ip': json['ip'],
        'shippedDate': !exists(json, 'shippedDate') ? undefined : (json['shippedDate'] === null ? null : new Date(json['shippedDate'])),
    };
}

export function SensorRowToJSON(value?: SensorRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'serialNumber': value.serialNumber,
        'deploymentID': value.deploymentID,
        'status': SensorStatusToJSON(value.status),
        'product': value.product,
        'model': value.model,
        'config': value.config,
        'ip': value.ip,
        'shippedDate': value.shippedDate === undefined ? undefined : (value.shippedDate === null ? null : value.shippedDate.toISOString()),
    };
}


