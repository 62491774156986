import { useQuery, useQueries } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { customerSelectors } from '../../Global/customerReducer';
import { configSelectors } from '../../Global/configReducer';
import riskAPI from '../../utils/riskAPI';
import { Agent } from './AscAgent/AgentNestedRenderer';
import styled from 'styled-components';
import IPCIDR from 'ip-cidr';
import {
  Client,
  Group,
  Host,
  IvaData,
  ReachData,
  ReachGroup,
  Scanner,
  ScannerSchedule,
  ScannerTarget,
  ScoutData,
} from '../../types/attackSurface';
import _ from 'lodash';

export const getPercentageString = (
  numerator: number,
  denominator: number
): string => {
  if (denominator === 0) return '0.00';
  const unrounded = numerator / denominator;
  const rounded = Math.round((unrounded + Number.EPSILON) * 10000) / 100;
  return `${rounded.toFixed(2)} %`;
};

export const useQueryScoutClients = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQuery({
    queryKey: ['getAttackSurfaceCoverage-ScoutClients', customerId],
    queryFn: () =>
      riskAPI.get(
        `${servicesHost}/rootsecure/scout/v1/customers/${customerId}/clients`
      ),
  });
};

export const useQueryScoutGroups = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQuery({
    queryKey: ['getAttackSurfaceCoverage-ScoutGroups', customerId],
    queryFn: () =>
      riskAPI.get(
        `${servicesHost}/rootsecure/scout/v1/customers/${customerId}/groupaggregates`
      ),
  });
};

export const useQueryReachGroups = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQuery({
    queryKey: ['getAttackSurfaceCoverage-ReachGroups', customerId],
    queryFn: () =>
      riskAPI.get(
        `${servicesHost}/rootsecure/reach/v1/customers/${customerId}/views/dashboard`
      ),
  });
};

export const useQueryScanners = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQuery({
    queryKey: ['getAttackSurfaceCoverage-Scanners', customerId],
    queryFn: () =>
      riskAPI.get(
        `${servicesHost}/rootsecure/v1/customers/${customerId}/scanners`
      ),
  });
};

export const useQueryScannerAssets = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQuery({
    queryKey: ['getAttackSurfaceCoverage-ScannerAssets', customerId],
    queryFn: () =>
      riskAPI.get(
        `${servicesHost}/rootsecure/v1/customers/${customerId}/assets?fromScore=0&search=&source=sensor&toScore=10.1`
      ),
  });
};

export const useQueriesScannerSchedules = (scannerIds: string[]) => {
  const servicesHost = useSelector(configSelectors.getServiceHost);
  return useQueries({
    queries: scannerIds.map((scannerId) => {
      return {
        queryKey: ['getAttackSurfaceCoverage-Scanners', scannerId],
        queryFn: () =>
          riskAPI
            .get(
              `${servicesHost}/rootsecure/v3/scanners/${scannerId}/schedules`
            )
            .then((response) => {
              response.data.map(
                (result: ScannerSchedule) => (result.scannerId = scannerId)
              );
              return response;
            }),
      };
    }),
  });
};

export interface AscAgentInfo {
  agentList: string[];
  agentCount: number;
  agentPercent: string;
}

export interface ReachTableColumn {
  hosts: string[];
  count: number;
  percent: string;
}

export interface ReachScheduleTableColumn {
  total: number;
  missedTargets: string[];
  scheduledTargets: string[];
}

export interface AscSchedule {
  scheduleCount: number;
  scheduleContent: Agent[] | {}[];
}

export interface Iva {
  scannerId: string;
  scheduleId: string;
  deploymentIds: string[];
}

export interface IvaSchedule {
  deviceCount?: number;
  scheduleCapacity?: number;
  emptyTargets?: number;
  targetName: string;
  networkcapacity: number;
  targetCount: number;
}
export const StyledContainer = styled.div`
  display: block;
  overflow-x: auto;
  padding-bottom: 40px;

  table thead th {
    white-space: nowrap;
  }

  tbody tr td {
    vertical-align: top;
  }
`;

export const ContainerDiv = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: 10px;
`;

export const getUsableIpAddresses = (ip: string): number => {
  try {
    if (ip.includes('-')) {
      if (!isIpStringValidRange(ip)) return 0;
      else {
        const ipRangeIsLongForm = ipRangeLongForm(ip);
        const [startIp, endIp] = ipRangeIsLongForm
          ? [ip.split('-')[0].trim(), ip.split('-')[1].trim()]
          : [ip.split('-')[0].trim(), buildEndIpString(ip)];

        const startInt = convertIptoInteger(startIp);
        const endInt = convertIptoInteger(endIp);

        return endInt - startInt;
        //
      }
    } else {
      if (isIpAddressValid(ip)) {
        return 1;
      } else if (!IPCIDR.isValidAddress(ip)) {
        return 0;
      } else {
        const cidr = new IPCIDR(ip);
        // - 2 to only show "usable" ip count
        // https://www.freecodecamp.org/news/subnet-cheat-sheet-24-subnet-mask-30-26-27-29-and-other-ip-address-cidr-network-references/
        const cidrCount = cidr.toArray().length;
        return cidrCount <= 2 ? cidrCount : cidrCount - 2;
      }
    }
  } catch {
    return 0;
  }
};

/*
 *	Gets Scout client for output.
 *	@param {string} clientId - required id of client to get
 *	@returns {Object} a Scout client object.
 */
const getScoutClient = (clientId: string, scouts: ScoutData) => {
  clientId = clientId.toLowerCase();
  if (!_.has(scouts.clients, clientId)) return false;
  return scouts.clients[clientId];
};

export const getScoutTargetGroupClients = (
  scouts: ScoutData,
  group_id: string
) => {
  group_id = group_id.toLowerCase();
  if (!_.has(scouts.groups, group_id)) return [];

  return _.map(scouts.groups[group_id].clients, (c) => {
    return getScoutClient(c.clientUuid, scouts);
  });
};

/*
 *	Gets Scout clients, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes a client and returns true if it should be included
 *	@returns {Object[]} An array of Scout client objects.
 */
const getScoutClients = (fIncludeFilter: Function, scouts: ScoutData) => {
  const clients: Client[] = [];
  _.forEach(scouts.clients, (c) => {
    if (!fIncludeFilter || fIncludeFilter(c)) {
      const client = getScoutClient(c.clientUuid, scouts);
      if (client) clients.push(client);
    }
  });
  return clients;
};

/*
 *	Gets all Scout clients not in active cve groups.
 *	@returns {object[]} An array of client objects (empty array if none).
 */
export const getScoutClientsNotInActiveCVEGroup = (scouts: ScoutData) => {
  return getScoutClients((c: Client) => {
    return !c.in_active_cve_group;
  }, scouts);
};

/*
 *	Gets all Scout clients not in active benchmark groups.
 *	@returns {object[]} An array of client objects (empty array if none).
 */
export const getScoutClientsNotInActiveBenchmarkGroup = (scouts: ScoutData) => {
  return getScoutClients((c: Client) => {
    return !c.in_active_benchmark_group;
  }, scouts);
};

/*
 *	Gets Scout target groups, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes a target group and returns true if it should be included
 *	@returns {Object[]} An array of Scout target group objects.
 */
export const getScoutTargetGroups = (
  scouts: ScoutData,
  fIncludeFilter?: Function
) => {
  const groups: Group[] = [];
  _.forEach(scouts.groups, (g) => {
    if (!fIncludeFilter || fIncludeFilter(g)) {
      g.group.clientCount = g.clients.length;
      groups.push(g.group);
    }
  });
  return groups;
};

/*
 *	Gets reach hosts, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes a reach host and returns true if it should be included
 *	@returns {Object[]} An array of Reach host objects.
 */
const getReachHosts = (fIncludeFilter: Function, reach: ReachData) => {
  const hosts: Host[] = [];
  _.forEach(reach.hosts, (h) => {
    if (!fIncludeFilter || fIncludeFilter(h)) hosts.push(h);
  });
  return hosts;
};

/*
 *	Gets all Reach hosts whose last scan failed.
 *	@returns {object[]} An array of host objects (empty array if none).
 */
export const getReachHostsLastScanFailed = (reach: ReachData) => {
  return getReachHosts((h: Host) => {
    return h.last_scan_status === 'failed';
  }, reach);
};

/*
 *	Gets all Reach hosts no scan scheduled in future.
 *	@returns {object[]} An array of host objects (empty array if none).
 */
export const getReachHostsNoScanScheduled = (reach: ReachData) => {
  return getReachHosts((h: Host) => {
    return !h.next_scan_time;
  }, reach);
};

/*
 *	Gets Reach target groups, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes a target group and returns true if it should be included
 *	@returns {Object[]} An array of Reach target group objects.
 */
export const getReachTargetGroupsHost = (
  host_or_cspm: string,
  reach: ReachData
) => {
  const cspm = host_or_cspm.toLowerCase() === 'cspm';
  const groups: ReachGroup[] = [];
  _.forEach(reach.groups, (g) => {
    if ((cspm && g.hasCspmAccounts) || (!cspm && g.hasHosts)) {
      // add hosts
      const hosts: Host[] = [];
      const missedHosts: Host[] = [];
      _.forEach(g.hosts, (h) => {
        if ((cspm && h.is_cspm) || (!cspm && h.is_host)) {
          const host = h;
          host.missed =
            host.last_scan_status === 'failed' || host.last_scan_time === '';
          hosts.push(host);
          if (host.missed) {
            missedHosts.push(host);
          }
        }
      });
      const group: ReachGroup = {
        ...g,
        ...{
          hosts: hosts,
          missedHosts: missedHosts,
          hostCount: hosts.length,
          missedHostCount: missedHosts.length,
        },
      };
      groups.push(group);
    }
  });
  return groups;
};

/*
 *	Gets all Reach target groups.
 *	@returns {object[]} An array of Reach target group objects (empty array if none).
 */
export const getReachTargetGroups = (reach: ReachData) => {
  return getReachTargetGroupsHost('host', reach);
};

/*
 *	Gets all Reach CSPM target groups.
 *	@returns {object[]} An array of Reach CSPM target group objects (empty array if none).
 */
export const getCspmTargetGroups = (reach: ReachData) => {
  return getReachTargetGroupsHost('cspm', reach);
};

/*
 *	Gets CSPM accounts, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes a cspm account and returns true if it should be included
 *	@returns {Object[]} An array of CSPM account objects.
 */
const getCspmAccounts = (fIncludeFilter: Function, reach: ReachData) => {
  const accounts: Host[] = [];
  _.forEach(reach.cspm_accounts, (ca) => {
    if (!fIncludeFilter || fIncludeFilter(ca)) accounts.push(ca);
  });
  return accounts;
};

/*
 *	Gets number of Reach CSPM Accounts.
 *	@returns {int} Number of Reach CSPM Accounts.
 */
export const getCspmAccountsTotal = (reach: ReachData) => {
  return _.size(reach.cspm_accounts);
};

/*
 *	Gets all Reach CSPM Accounts whose last scan failed.
 *	@returns {object[]} An array of CSPM account objects (empty array if none).
 */
export const getCspmAccountsLastScanFailed = (reach: ReachData) => {
  return getCspmAccounts((ca: Host) => {
    return ca.last_scan_status === 'failed';
  }, reach);
};

/*
 *	Gets all Reach CSPM Accounts no scan scheduled in future.
 *	@returns {object[]} An array of CSPM account objects (empty array if none).
 */
export const getCspmAccountsNoScanScheduled = (reach: ReachData) => {
  return getCspmAccounts((ca: Host) => {
    return !ca.next_scan_time;
  }, reach);
};

/*
 *	Gets all iVA target objects for a given scanner and schedule.
 *	@param {string} scanner_id - required id of scanner
 *	@param {string} schedule_id - required id of schedule
 *	@returns {object[]} An array of iVA target objects (empty array if none).
 */
export const getIvaScannerScheduleTargets = (
  scanner_id: string,
  schedule_id: string,
  iva: IvaData,
  fIncludeFilter?: Function
) => {
  if (!scanner_id || !_.has(iva.scanners, scanner_id.toLowerCase())) return [];
  const scanner = iva.scanners[scanner_id.toLowerCase()];
  if (!schedule_id || !_.has(scanner.schedules, schedule_id.toLowerCase()))
    return [];
  const schedule = scanner.schedules[schedule_id.toLowerCase()];

  const targets: ScannerTarget[] = [];
  _.forEach(schedule.mappedTargets, (target) => {
    if (fIncludeFilter && !fIncludeFilter(target)) return;
    target.assetCount = target.assets.length;
    targets.push(target);
  });
  return targets;
};

/*
 *	Gets iVA scanners, optionally filtered, for output.
 *	@param {function} fIncludeFilter - optional function that takes an iVA scanner and returns true if it should be included
 *	@returns {Object[]} An array of iVA scanner objects.
 */
export const getIvaScanners = (iva: IvaData, fIncludeFilter?: Function) => {
  const scanners: Scanner[] = [];
  _.forEach(iva.scanners, (s) => {
    if (fIncludeFilter && !fIncludeFilter(s)) return;
    const scanner = {
      ...s,
      ...{
        assetCount: s.assets.length,
        scheduleCount: _.size(s.schedules),
      },
    };
    scanners.push(scanner);
  });
  return scanners;
};

/*
 *	Gets iVA schedules for scanner, optionally filtered, for output.
 *	@param {string} scanner_id - required id of scanner
 *	@param {function} fIncludeFilter - optional function that takes an iVA schedule and returns true if it should be included
 *	@returns {Object[]} An array of iVA scanner objects.
 */
export const getIvaScannerSchedules = (
  scanner_id: string,
  iva: IvaData,
  fIncludeFilter?: Function
) => {
  if (!scanner_id || !_.has(iva.scanners, scanner_id.toLowerCase())) return [];
  const scanner = iva.scanners[scanner_id.toLowerCase()];
  const schedules: ScannerSchedule[] = [];
  _.forEach(scanner.schedules, (s) => {
    if (fIncludeFilter && !fIncludeFilter(s)) return;
    const schedule = {
      ...s,
      ...{
        assetCount: s.assets.length,
        targetsCount: _.size(s.targets),
      },
    };
    schedules.push(schedule);
  });
  return schedules;
};

// 10.0.0.11-10.0.0.224 or 10.0.0.11 - 10.0.0.224
const ipRangeLongForm = (ipRange: string) => {
  const pattern =
    /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\s*-\s*(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  return pattern.test(ipRange);
};
// 10.0.0.11-224
const ipRangeShortForm = (ipRange: string) => {
  const pattern = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\s*-\s*(\d{1,3})$/;
  return pattern.test(ipRange);
};

// Single IP address
const isIpAddressValid = (ip: string) => {
  const pattern = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  return pattern.test(ip);
};

export const isIpStringValidRange = (ip: string) => {
  return ipRangeLongForm(ip) || ipRangeShortForm(ip) ? true : false;
};

const convertIptoInteger = (ip: string) => {
  return ip
    .split('.')
    .reduce((result, octet) => (result << 8) + parseInt(octet, 10), 0);
};

const buildEndIpString = (ipRange: string) => {
  return ipRange
    .split('-')[0]
    .trim()
    .split('.')
    .slice(0, -1)
    .concat(ipRange.split('-')[1].trim())
    .join('.');
};

export const isIpInRange = (ipRange: string, ip: string) => {
  const ipRangeIsLongForm = ipRangeLongForm(ipRange);

  const [startIp, endIp] = ipRangeIsLongForm
    ? [ipRange.split('-')[0].trim(), ipRange.split('-')[1].trim()]
    : [ipRange.split('-')[0].trim(), buildEndIpString(ipRange)];

  const ipAsNumerical = convertIptoInteger(ip);

  return (
    ipAsNumerical >= convertIptoInteger(startIp) &&
    ipAsNumerical <= convertIptoInteger(endIp)
  );
};
