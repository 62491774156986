/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Report, ReportFromJSON, ReportFromJSONTyped, ReportToJSON } from './';

/**
 *
 * @export
 * @interface Reports
 */
export interface Reports {
  /**
   * the total number of reports across all pages
   * @type {number}
   * @memberof Reports
   */
  total: number;
  /**
   *
   * @type {Array<Report>}
   * @memberof Reports
   */
  items: Array<Report>;
}

export function ReportsFromJSON(json: any): Reports {
  return ReportsFromJSONTyped(json, false);
}

export function ReportsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Reports {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json['total'],
    items: (json['items'] as Array<any>).map(ReportFromJSON),
  };
}

export function ReportsToJSON(value?: Reports | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(ReportToJSON),
  };
}
