/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AgentScanType,
  AgentScanTypeFromJSON,
  AgentScanTypeFromJSONTyped,
  AgentScanTypeToJSON,
  CronSchedule,
  CronScheduleFromJSON,
  CronScheduleFromJSONTyped,
  CronScheduleToJSON,
  Frequency,
  FrequencyFromJSON,
  FrequencyFromJSONTyped,
  FrequencyToJSON,
  Target,
  TargetFromJSON,
  TargetFromJSONTyped,
  TargetToJSON,
} from './';

/**
 * Input to request creating or editing an agent scan schedule
 * @export
 * @interface AgentScheduleInput
 */
export interface AgentScheduleInput {
  /**
   *
   * @type {string}
   * @memberof AgentScheduleInput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AgentScheduleInput
   */
  description?: string;
  /**
   *
   * @type {Array<AgentScanType>}
   * @memberof AgentScheduleInput
   */
  scanType?: Array<AgentScanType>;
  /**
   *
   * @type {boolean}
   * @memberof AgentScheduleInput
   */
  scanningEnabled?: boolean;
  /**
   *
   * @type {Frequency}
   * @memberof AgentScheduleInput
   */
  frequency?: Frequency;
  /**
   * scheduled times for scan
   * @type {Array<CronSchedule>}
   * @memberof AgentScheduleInput
   */
  windows?: Array<CronSchedule>;
  /**
   * scan window in hours
   * @type {number}
   * @memberof AgentScheduleInput
   */
  scanWindow?: number;
  /**
   *
   * @type {boolean}
   * @memberof AgentScheduleInput
   */
  autoEnrollNewClients?: boolean;
  /**
   * Targets added when creating schedule
   * @type {Array<Target>}
   * @memberof AgentScheduleInput
   */
  targets?: Array<Target>;
  /**
   * Targets newly added when editing schedule
   * @type {Array<Target>}
   * @memberof AgentScheduleInput
   */
  targetsNewlyAdded?: Array<Target>;
  /**
   * Targets deleted when editing schedule
   * @type {Array<Target>}
   * @memberof AgentScheduleInput
   */
  targetsRemoved?: Array<Target>;
}

export function AgentScheduleInputFromJSON(json: any): AgentScheduleInput {
  return AgentScheduleInputFromJSONTyped(json, false);
}

export function AgentScheduleInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgentScheduleInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    scanType: !exists(json, 'scanType')
      ? undefined
      : (json['scanType'] as Array<any>).map(AgentScanTypeFromJSON),
    scanningEnabled: !exists(json, 'scanningEnabled')
      ? undefined
      : json['scanningEnabled'],
    frequency: !exists(json, 'frequency')
      ? undefined
      : FrequencyFromJSON(json['frequency']),
    windows: !exists(json, 'windows')
      ? undefined
      : (json['windows'] as Array<any>).map(CronScheduleFromJSON),
    scanWindow: !exists(json, 'scanWindow') ? undefined : json['scanWindow'],
    autoEnrollNewClients: !exists(json, 'autoEnrollNewClients')
      ? undefined
      : json['autoEnrollNewClients'],
    targets: !exists(json, 'targets')
      ? undefined
      : (json['targets'] as Array<any>).map(TargetFromJSON),
    targetsNewlyAdded: !exists(json, 'targetsNewlyAdded')
      ? undefined
      : (json['targetsNewlyAdded'] as Array<any>).map(TargetFromJSON),
    targetsRemoved: !exists(json, 'targetsRemoved')
      ? undefined
      : (json['targetsRemoved'] as Array<any>).map(TargetFromJSON),
  };
}

export function AgentScheduleInputToJSON(
  value?: AgentScheduleInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    scanType:
      value.scanType === undefined
        ? undefined
        : (value.scanType as Array<any>).map(AgentScanTypeToJSON),
    scanningEnabled: value.scanningEnabled,
    frequency: FrequencyToJSON(value.frequency),
    windows:
      value.windows === undefined
        ? undefined
        : (value.windows as Array<any>).map(CronScheduleToJSON),
    scanWindow: value.scanWindow,
    autoEnrollNewClients: value.autoEnrollNewClients,
    targets:
      value.targets === undefined
        ? undefined
        : (value.targets as Array<any>).map(TargetToJSON),
    targetsNewlyAdded:
      value.targetsNewlyAdded === undefined
        ? undefined
        : (value.targetsNewlyAdded as Array<any>).map(TargetToJSON),
    targetsRemoved:
      value.targetsRemoved === undefined
        ? undefined
        : (value.targetsRemoved as Array<any>).map(TargetToJSON),
  };
}
