/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input to request cancelling running scans
 * @export
 * @interface CancelAgentScansInput
 */
export interface CancelAgentScansInput {
  /**
   * List of agent groupIDs for which to cancel running scans. Use * to cancel scans for all groups.
   * @type {Array<string>}
   * @memberof CancelAgentScansInput
   */
  groupIds: Array<string>;
}

export function CancelAgentScansInputFromJSON(
  json: any
): CancelAgentScansInput {
  return CancelAgentScansInputFromJSONTyped(json, false);
}

export function CancelAgentScansInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelAgentScansInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupIds: json['groupIds'],
  };
}

export function CancelAgentScansInputToJSON(
  value?: CancelAgentScansInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groupIds: value.groupIds,
  };
}
