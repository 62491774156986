/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * possible values for the connection status of a given scanner
 * @export
 * @enum {string}
 */
export enum ScannerConnectionStatus {
    Disconnected = 'disconnected',
    Connected = 'connected'
}

export function ScannerConnectionStatusFromJSON(json: any): ScannerConnectionStatus {
    return ScannerConnectionStatusFromJSONTyped(json, false);
}

export function ScannerConnectionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScannerConnectionStatus {
    return json as ScannerConnectionStatus;
}

export function ScannerConnectionStatusToJSON(value?: ScannerConnectionStatus | null): any {
    return value as any;
}

