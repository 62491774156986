/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SensorRow,
    SensorRowFromJSON,
    SensorRowFromJSONTyped,
    SensorRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface SensorPage
 */
export interface SensorPage {
    /**
     * the total number of items, across all pages matching the query
     * @type {number}
     * @memberof SensorPage
     */
    total: number;
    /**
     * 
     * @type {Array<SensorRow>}
     * @memberof SensorPage
     */
    items: Array<SensorRow>;
}

export function SensorPageFromJSON(json: any): SensorPage {
    return SensorPageFromJSONTyped(json, false);
}

export function SensorPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(SensorRowFromJSON)),
    };
}

export function SensorPageToJSON(value?: SensorPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': ((value.items as Array<any>).map(SensorRowToJSON)),
    };
}


