import React from 'react';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { Button } from '@rtkwlf/fenrir-react';
import { initRiskTrendsCSV } from './RiskScoreTrendUtils';
import { connect, ConnectedProps } from 'react-redux';
import { RowTile } from '../../Reusables/RowTile';
import { iIndustryData, iRiskSummary } from '../../types/stat';
import moment from 'moment';
import {
  ffBenchmarkRiskScore,
  isFeatureEnabled,
} from '../../../public/js/features';

export const rs_echarts_theme = {
  color: [
    '#23618E',
    '#34495E',
    '#BDC3C7',
    '#3498DB',
    '#9B59B6',
    '#8abb6f',
    '#759c6a',
    '#bfd3b7',
  ],

  title: {
    itemGap: 8,
    textStyle: {
      fontWeight: 'normal',
      color: '#408829',
    },
  },

  dataRange: {
    color: ['#1f610a', '#97b58d'],
  },

  toolbox: {
    color: ['#408829', '#408829', '#408829', '#408829'],
  },

  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    axisPointer: {
      type: 'line',
      lineStyle: {
        color: '#408829',
        type: 'dashed',
      },
      crossStyle: {
        color: '#408829',
      },
      shadowStyle: {
        color: 'rgba(200,200,200,0.3)',
      },
    },
  },

  dataZoom: {
    dataBackgroundColor: '#eee',
    fillerColor: 'rgba(64,136,41,0.2)',
    handleColor: '#408829',
  },
  grid: {
    borderWidth: 0,
  },

  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: '#408829',
      },
    },
    splitLine: {
      lineStyle: {
        color: ['#eee'],
      },
    },
  },

  valueAxis: {
    axisLine: {
      lineStyle: {
        color: '#408829',
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.1)', 'rgba(200,200,200,0.1)'],
      },
    },
    splitLine: {
      lineStyle: {
        color: ['#eee'],
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#408829',
    },
    emphasis: {
      color: '#408829',
      controlStyle: {
        color: '#408829',
      },
    },
  },

  k: {
    itemStyle: {
      color: '#68a54a',
      color0: '#a9cba2',
      lineStyle: {
        width: 1,
        color: '#408829',
        color0: '#86b379',
      },
    },
  },
  map: {
    itemStyle: {
      areaStyle: {
        color: '#ddd',
      },
      label: {
        textStyle: {
          color: '#c12e34',
        },
      },
      emphasis: {
        areaStyle: {
          color: '#99d2dd',
        },
        label: {
          textStyle: {
            color: '#c12e34',
          },
        },
      },
    },
  },
  force: {
    itemStyle: {
      linkStyle: {
        strokeColor: '#408829',
      },
    },
  },
  chord: {
    padding: 4,
    itemStyle: {
      lineStyle: {
        width: 1,
        color: 'rgba(128, 128, 128, 0.5)',
      },
      chordStyle: {
        lineStyle: {
          width: 1,
          color: 'rgba(128, 128, 128, 0.5)',
        },
      },
      emphasis: {
        lineStyle: {
          width: 1,
          color: 'rgba(128, 128, 128, 0.5)',
        },
        chordStyle: {
          lineStyle: {
            width: 1,
            color: 'rgba(128, 128, 128, 0.5)',
          },
        },
      },
    },
  },
  gauge: {
    startAngle: 225,
    endAngle: -45,
    axisLine: {
      show: true,
      lineStyle: {
        color: [
          [0.2, '#86b379'],
          [0.8, '#68a54a'],
          [1, '#408829'],
        ],
        width: 8,
      },
    },
    axisTick: {
      splitNumber: 10,
      length: 12,
      lineStyle: {
        color: 'auto',
      },
    },
    axisLabel: {
      textStyle: {
        color: 'auto',
      },
    },
    splitLine: {
      length: 18,
      lineStyle: {
        color: 'auto',
      },
    },
    pointer: {
      length: '90%',
      color: 'auto',
    },
    title: {
      textStyle: {
        color: '#333',
      },
    },
    detail: {
      textStyle: {
        color: 'auto',
      },
    },
  },
  textStyle: {
    fontFamily: 'Arial, Verdana, sans-serif',
  },
};

type RawData = {
  time: string[]; // UTC
  risks: number[]; // 'YYYY-MM-DD'
  industry: number[];
  targets: string[];
};

type ChartData = {
  time: string[];
  risks: string[];
  industry: string[];
  targets: string[];
};

const TARGET = '4.0';

const formatTimestamp = (timestamp: string) =>
  moment.utc(timestamp).format('YYYY-MM-DD');
const formatScore = (score: number) => score.toFixed(1);

export const getChartData = (
  summaries: iRiskSummary[],
  industryData: iIndustryData[]
) => {
  try {
    const companyScoreMap = new Map(
      summaries
        // the last item is possibly a mid-day score, which should be ignored in this chart
        .filter((summary) => summary.date.includes('T00:00:00'))
        .map((summary) => [summary.date, summary.summaryData.level])
    );

    const industryScoreMap = new Map(
      industryData.map((industry) => [industry.scoreDay, industry.score])
    );

    const rawDailyData: RawData = {
      time: [],
      risks: [],
      industry: [],
      targets: [],
    };

    for (const [timestamp, companyScore] of companyScoreMap) {
      rawDailyData.time.push(formatTimestamp(timestamp));
      rawDailyData.risks.push(companyScore);
      rawDailyData.industry.push(industryScoreMap.get(timestamp) ?? 0);
      rawDailyData.targets.push(TARGET);
    }

    const dailyData = {
      time: rawDailyData.time,
      risks: rawDailyData.risks.map(formatScore),
      industry: rawDailyData.industry.map(formatScore),
      targets: rawDailyData.targets,
    };

    const monthlyData: ChartData = {
      time: [],
      risks: [],
      industry: [],
      targets: [],
    };

    let day = dailyData.time[0];
    let monthIdx = day.lastIndexOf('-');
    let curMonth = day.substring(0, monthIdx);
    let maxRisk = 0.0;
    let maxIndustry = 0.0;

    for (let i = 0; i < dailyData.time.length; i++) {
      day = dailyData.time[i];
      monthIdx = day.lastIndexOf('-');
      const month = day.substring(0, monthIdx);

      const risk = rawDailyData.risks[i];
      if (risk > maxRisk) {
        maxRisk = risk;
      }

      const industry = rawDailyData.industry[i];
      if (industry > maxIndustry) {
        maxIndustry = industry;
      }

      if (month !== curMonth || i === dailyData.time.length - 1) {
        monthlyData.time.push(curMonth);
        monthlyData.risks.push(maxRisk.toFixed(1));
        monthlyData.industry.push(maxIndustry.toFixed(1));
        monthlyData.targets.push(TARGET);
        curMonth = month;
        maxRisk = 0.0;
        maxIndustry = 0.0;
      }
    }

    return { dailyData, monthlyData };
  } catch (e) {
    const emptyData: ChartData = {
      time: [],
      risks: [],
      industry: [],
      targets: [],
    };

    return {
      dailyData: emptyData,
      monthlyData: emptyData,
    };
  }
};

const RiskScoreTrends = ({ summaries, industryData }: PropsFromRedux) => {
  const [isMonthlyView, setIsMonthlyView] = React.useState(true);

  const { dailyData, monthlyData } = React.useMemo(() => {
    return getChartData(summaries, industryData);
  }, [industryData, summaries]);

  const data = isMonthlyView ? monthlyData : dailyData;

  const riskOptions = {
    title: {
      text: '',
      subtext: '',
    },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: 'white',
      },
    },
    legend: {
      position: 'bottom',
      data: isFeatureEnabled(ffBenchmarkRiskScore)
        ? ['Your Risk Score', 'Benchmark Risk Score', 'Target']
        : ['Your Risk Score', 'Industry Risk Score', 'Target'],
      tooltip: {
        textStyle: {
          color: 'white',
        },
      },
    },
    toolbox: {
      show: true,
      feature: {
        magicType: {
          show: true,
          title: {
            bar: 'Bar',
            line: 'Line',
          },
          type: ['bar', 'line'],
        },
        restore: {
          show: true,
          title: 'Restore',
        },
        saveAsImage: {
          show: false,
          title: 'Save Image',
        },
        dataView: {
          show: false,
          readOnly: true,
        },
      },
    },
    calculable: true,
    xAxis: [
      {
        name: 'Time',
        nameTextStyle: { color: 'black' },
        axisLabel: { color: 'black' },
        nameLocation: 'middle',
        nameGap: 25,
        type: 'category',
        boundaryGap: true,
        data: data.time,
      },
    ],
    yAxis: [
      {
        name: 'Risk Score',
        nameTextStyle: { color: 'black' },
        axisLabel: { color: 'black' },
        nameLocation: 'middle',
        nameGap: 25,
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Your Risk Score',
        type: 'bar',
        fill: false,
        smooth: true,
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            {
              type: 'min',
              name: 'Min',
            },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Mean',
            },
          ],
        },
        data: data.risks,
        animationDelay: function (idx: any) {
          return idx;
        },
      },
      {
        name: isFeatureEnabled(ffBenchmarkRiskScore)
          ? 'Benchmark Risk Score'
          : 'Industry Risk Score',
        type: 'bar',
        fill: false,
        smooth: true,
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            {
              type: 'min',
              name: 'Min',
            },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Mean',
            },
          ],
        },
        data: data.industry,
        animationDelay: function (idx: any) {
          return idx;
        },
      },
      {
        name: 'Target',
        type: 'bar',
        fill: false,
        smooth: true,
        data: data.targets,
        animationDelay: function (idx: any) {
          return idx;
        },
      },
    ],
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx: any) {
      return idx;
    },
  };

  echarts.registerTheme('AWN', rs_echarts_theme);

  return (
    <RowTile
      id='risk-score-trends'
      title={'Risk Score Trends'}
      description={
        isFeatureEnabled(ffBenchmarkRiskScore)
          ? 'How your individual and benchmark risk scores have changed over time, up to one year.'
          : 'How your individual and industry risk scores have changed over time, up to one year.'
      }
      buttons={
        <div>
          <Button
            variant='secondary'
            id='risk-trends-month-btn'
            onClick={() => setIsMonthlyView(true)}
            iconLeft={<i className='fa fa-refresh' />}
          >
            Monthly
          </Button>
          <Button
            variant='secondary'
            id='risk-trends-day-btn'
            onClick={() => setIsMonthlyView(false)}
            iconLeft={<i className='fa fa-refresh' />}
          >
            Daily
          </Button>
          <Button
            variant='secondary'
            id='export-risk-trends-to-csv'
            onClick={() =>
              initRiskTrendsCSV(
                data.risks.map((risk, index) => ({
                  date: data.time[index],
                  yourRiskScore: risk,
                  indRiskScore: data.industry[index],
                  target: data.targets[index],
                }))
              )
            }
            iconLeft={<i className='fa fa-download' />}
          >
            CSV
          </Button>
        </div>
      }
    >
      <ReactEcharts option={riskOptions} theme={'AWN'} />
    </RowTile>
  );
};
const connector = connect(({ stats }) => ({
  summaries: stats.allSummaries,
  industryData: stats.allIndustryData,
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RiskScoreTrends);
