/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ManageIvaScheduleState,
  ManageIvaScheduleStateFromJSON,
  ManageIvaScheduleStateFromJSONTyped,
  ManageIvaScheduleStateToJSON,
} from './';

/**
 * Input to request for disabling iva scan schedules
 * @export
 * @interface ManageIvaScheduleStateInputs
 */
export interface ManageIvaScheduleStateInputs {
  /**
   *
   * @type {Array<ManageIvaScheduleState>}
   * @memberof ManageIvaScheduleStateInputs
   */
  disable?: Array<ManageIvaScheduleState>;
}

export function ManageIvaScheduleStateInputsFromJSON(
  json: any
): ManageIvaScheduleStateInputs {
  return ManageIvaScheduleStateInputsFromJSONTyped(json, false);
}

export function ManageIvaScheduleStateInputsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManageIvaScheduleStateInputs {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    disable: !exists(json, 'disable')
      ? undefined
      : (json['disable'] as Array<any>).map(ManageIvaScheduleStateFromJSON),
  };
}

export function ManageIvaScheduleStateInputsToJSON(
  value?: ManageIvaScheduleStateInputs | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    disable:
      value.disable === undefined
        ? undefined
        : (value.disable as Array<any>).map(ManageIvaScheduleStateToJSON),
  };
}
