/* tslint:disable */
/* eslint-disable */
/**
 * Rendall API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ScheduledReport,
  ScheduledReportFromJSON,
  ScheduledReportFromJSONTyped,
  ScheduledReportToJSON,
} from './';

/**
 *
 * @export
 * @interface ScheduledReports
 */
export interface ScheduledReports {
  /**
   * the total number of scheduled reports across all pages
   * @type {number}
   * @memberof ScheduledReports
   */
  total: number;
  /**
   *
   * @type {Array<ScheduledReport>}
   * @memberof ScheduledReports
   */
  items: Array<ScheduledReport>;
}

export function ScheduledReportsFromJSON(json: any): ScheduledReports {
  return ScheduledReportsFromJSONTyped(json, false);
}

export function ScheduledReportsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScheduledReports {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json['total'],
    items: (json['items'] as Array<any>).map(ScheduledReportFromJSON),
  };
}

export function ScheduledReportsToJSON(value?: ScheduledReports | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(ScheduledReportToJSON),
  };
}
